import React from 'react'
import { ICharge } from './Billing'
import { Row, Col } from 'react-flexbox-grid'

export default class Charge extends React.Component<ICharge> {
    render() {
        const { amount, receiptUrl, paid, createdDate } = this.props
        return (
            <Col
                xs={12}
                lg={3}
                style={{
                    backgroundColor: '#eff7fb',
                    padding: 20,
                    borderRadius: 4,
                    marginBottom: 15,
                    marginRight: 20,
                }}
            >
                <h3 style={{ margin: 0, fontSize: 16 }}>
                    {new Date(createdDate * 1000).toLocaleDateString()}
                </h3>
                <p
                    style={{
                        fontSize: 12,
                        marginTop: 5,
                        marginBottom: 5,
                    }}
                >
                    USD ${amount}
                </p>
                <p
                    style={{
                        fontSize: 12,
                        margin: 0,
                    }}
                >
                    <a
                        style={{
                            color: '#000000',
                        }}
                        target="_blank"
                        href={receiptUrl}
                    >
                        View Receipt
                    </a>
                </p>
                {/* <p>{paid ? 'PAID!' : 'Not paid'}</p> */}
            </Col>
        )
    }
}
