import React from 'react'
import { fetchAllProfiles } from '../services/screeningProfileService'
import Modal from './Modal'
import { Row, Col } from 'react-flexbox-grid'
import Button from '../insightui/Button'
import { LoadingOutlined } from '@ant-design/icons'
import { ICustomProfile } from '../models/api'
import TagSelect from '../insightui/TagSelect'
import { store } from '../store'
import { setProfile } from '../actions'
import { config } from '../models/config'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
    FAITH_BASED_EVANGELICAL_DEFAULT_PROFILE,
    FAITH_BASED_CATHOLIC,
    SOCIAL_JUSTICE,
    ENVIRONMENTAL,
} from '../models/profiles'
interface ScreeningProfileModalProps extends RouteComponentProps {
    isActive: boolean
    onClose?: () => void
    onSelection?: (selection: string) => void
}

interface ScreeningProfileModalState {
    profiles: Array<ICustomProfile>
    ready: boolean
    selection: string
}

/*
 * This component manages the active screening profile
 * and interracts with the state such to bridge the user
 * and the Redux Store.
 */
class ScreeingProfileModal extends React.Component<ScreeningProfileModalProps> {
    standardProfileData: Array<ICustomProfile> = [
        FAITH_BASED_EVANGELICAL_DEFAULT_PROFILE,
        FAITH_BASED_CATHOLIC,
        SOCIAL_JUSTICE,
        ENVIRONMENTAL,
    ]
    state = {
        profiles: [],
        ready: false,
        selection: store.getState().activeProfile.id.toString(),
    }

    processProfileSelection = (profiles) => {
        return profiles.map((item: ICustomProfile) => ({
            title: item.name,
            value: item.id.toString(),
        }))
    }

    async componentDidMount() {
        await fetchAllProfiles((data: Array<ICustomProfile>) =>
            this.setState({ profiles: data, ready: true })
        )
    }

    onSelectionChange = () => {
        let allProfiles = this.state.profiles.concat(this.standardProfileData)
        allProfiles.forEach((profile: ICustomProfile) => {
            if (profile.id.toString() === this.state.selection) {
                store.dispatch(setProfile(profile))
            }
        })
        if (this.props.onClose) this.props.onClose()
    }

    render() {
        const { isActive, onClose } = this.props
        return (
            <Modal isActive={isActive}>
                <div style={{ padding: 13 }}>
                    <Row>
                        <p
                            style={{
                                fontWeight: 600,
                                fontSize: 26,
                                lineHeight: 0.5,
                            }}
                        >
                            Screening Profiles
                        </p>
                    </Row>
                    <Row style={{ paddingBottom: 20 }}>
                        <Col xs={12}>
                            <h4 style={{ marginBottom: 0 }}>
                                Standard Profiles
                            </h4>
                            {this.state.ready ? (
                                <TagSelect
                                    value={this.state.selection}
                                    key={Math.random() * 1000}
                                    onChange={(selection) => {
                                        this.setState({ selection: selection })
                                    }}
                                    items={this.processProfileSelection(
                                        this.standardProfileData
                                    )}
                                />
                            ) : (
                                <>
                                    <LoadingOutlined
                                        style={{
                                            marginRight: 7,
                                            position: 'relative',
                                            bottom: 2,
                                        }}
                                    />
                                    Loading...
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: 20 }}>
                        <Col xs={12}>
                            <h4 style={{ marginBottom: 0 }}>Custom Profiles</h4>
                            {this.state.ready ? (
                                <TagSelect
                                    value={this.state.selection}
                                    key={Math.random() * 1000}
                                    onChange={(selection) =>
                                        this.setState({ selection: selection })
                                    }
                                    items={this.processProfileSelection(
                                        this.state.profiles
                                    )}
                                />
                            ) : (
                                <>
                                    <LoadingOutlined
                                        style={{
                                            marginRight: 7,
                                            position: 'relative',
                                            bottom: 2,
                                        }}
                                    />
                                    Loading...
                                </>
                            )}
                        </Col>
                    </Row>

                    <Row style={{ paddingBottom: 20 }}>
                        <Col lg>
                            <Button
                                type="outlined"
                                style={{ width: '100%' }}
                                onClick={() => {
                                    this.props.history.push(
                                        '/screening-profiles'
                                    )
                                }}
                            >
                                Edit Custom Profiles
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg>
                            <Button
                                type="outlined"
                                style={{ width: '100%' }}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col lg>
                            <Button
                                type="primary"
                                style={{ width: '100%' }}
                                onClick={this.onSelectionChange}
                            >
                                Select
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        )
    }
}

export default withRouter(ScreeingProfileModal)
