import React from 'react'
import Layout from '../components/Layout'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Button from '../insightui/Button'
import { config } from '../models/config'
import { RouteComponentProps, withRouter } from 'react-router-dom'
class NotFound extends React.Component<RouteComponentProps> {
    render() {
        return (
            <>
                <Layout sider={false}>
                    <Grid>
                        <Row
                            style={{
                                paddingLeft: '10%',
                                paddingRight: '10%',
                                paddingTop: 80,
                            }}
                        >
                            <Col xs>
                                <img src="https://assets.website-files.com/5e41c4526d9f269d42ec87f4/5faed7077103df41be9b3f44_tumblr_lz2ufhclZj1r4mh0bo1_r1_400.gif" />
                            </Col>
                            <Col
                                xs
                                style={{
                                    display: 'table-cell',
                                    verticalAlign: 'middle',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'inline-block',
                                        paddingTop: '45%',
                                    }}
                                >
                                    <h1>Page Not Found</h1>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            this.props.history.push('/')
                                        }}
                                    >
                                        Go Home
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        {/* <Footer topMargin={130} /> */}
                    </Grid>
                </Layout>
            </>
        )
    }
}

export default withRouter(NotFound)
