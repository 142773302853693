import { Component } from 'react'
import NumberRoll from '../insightui/NumberRoll'
import Bar from '../insightui/Bar'
import { globals } from '../utils/globals'
import { isMobile } from 'react-device-detect'
import { Row, Col } from 'react-flexbox-grid'

const DEBUG: boolean = false

enum Size {
    FONT_SIZE = 16,
}

interface ESGFundCriterionProps {
    speedMS?: number
    steps?: number
    style?: object
    withAnimation?: boolean
    group?: boolean
    percent?: number
    negative?: boolean
    securities: number
    printing?: boolean
    securitiesTitle?: string
    title?: string
    category?: string
    handleContributors?: (category: string) => void
}

export default class ESGFundCriterion extends Component<ESGFundCriterionProps> {
    state = {
        percent: 0,
    }

    getContainerStyles(extendStyles?: object, printing?: boolean) {
        const getIndent: any = function (isGroup: boolean) {
            return isGroup ? 0 : 30
        }
        const isGroup: boolean = this.props.group as boolean
        return {
            width: this.getWidth(isGroup),
            marginBottom: isGroup ? 25 : 0,
            height: printing ? 15 : 50,
            padding: 15,
            marginLeft: getIndent(isGroup),
            backgroundColor: DEBUG ? 'rgba(0,0,255,0.05)' : 'transparent',
            ...extendStyles,
        }
    }

    getWidth(isGroup: boolean) {
        const space: number = 60
        return isGroup ? '100%' : 'calc(100% - ' + space + 'px)'
    }

    addTitleComponent(title: string) {
        const titleStyles: Record<string, any> = {
            fontFamily: '"URW Geometric Cond", sans-serif',
            color: globals.colors.text.default,
            fontSize: Size.FONT_SIZE,
            fontWeight: 900,
            backgroundColor: DEBUG ? 'lime' : 'transparent',
            height: 16,
        }
        return (
            <div
                style={{
                    ...titleStyles,
                    overflow: 'hidden',
                    width: '100%',
                    height: 20,
                    marginTop: 10,
                    backgroundColor: DEBUG ? 'pink' : 'transparent',
                }}
            >
                {title}
            </div>
        )
    }

    addSecuritiesComponent(securities: number, isNeg: boolean, title?: string) {
        const securitiesStyle: Record<string, any> = {
            fontFamily: '"URW Geometric Cond", sans-serif',
            color: globals.colors.text.default,
            fontSize: 12,
            fontWeight: 600,
            width: 'auto',
            height: 'auto',
            display: 'inline-block',
            textTransform: 'uppercase' as 'uppercase',
            verticalAlign: 'top',
            letterSpacing: 2,
            float: 'right',
            backgroundColor: DEBUG ? 'purple' : 'transparent',
            marginTop: -8,
        }
        const securitiesValueStyle: Record<string, any> = {
            display: 'inline-block',
            lineHeight: '1em',
            color: isNeg
                ? globals.colors.negative.red
                : globals.colors.positive.blue,
        }

        const securitiesTitle: string = this.props.securitiesTitle
            ? this.props.securitiesTitle
            : 'securities'

        return (
            <div style={{ ...securitiesStyle }}>
                <p
                    style={{
                        ...securitiesValueStyle,
                        textDecoration:
                            securitiesTitle === 'securities'
                                ? 'underline'
                                : 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() =>
                        this.props.handleContributors(this.props.category)
                    }
                >
                    {securities}
                    {` ${securitiesTitle}`}
                </p>
            </div>
        )
    }

    addNumberRollComponent(percent: number, animate: boolean, isNeg: boolean) {
        const numberrollStyle: Record<string, any> = {
            lineHeight: '10px',
            fontSize: 18,
            fontWeight: 500,
            verticalAlign: 'top',
            textAlign: 'top',
            //backgroundColor: 'rgba(255,0,0,0.1)',
            marginTop: -2,
            marginLeft: 0,
            display: 'inline-block',
            letterSpacing: -2,
        }

        const symbolStyles: Record<string, any> = {
            lineHeight: '10px',
            fontSize: 18,
            fontWeight: 800,
            verticalAlign: 'top',
            textAlign: 'top',
            marginTop: -1,
            marginLeft: 2,
            display: 'inline-block',
            fontFamily: '"URW Geometric Cond", sans-serif',
            //backgroundColor: 'rgba(255,0,0,0.1)',
            color: isNeg
                ? globals.colors.negative.red
                : globals.colors.positive.blue,
        }

        return (
            <>
                <NumberRoll
                    match
                    negative={this.props.negative}
                    final={percent}
                    withAnimation={animate}
                    style={{ ...numberrollStyle }}
                ></NumberRoll>
                <div style={{ ...symbolStyles }}>{'%'}</div>
            </>
        )
    }

    addBarComponent(percent: number, animate: boolean, isNeg: boolean) {
        //console.log('percentVersion: ' + percent)
        const barContainerStyle: Record<string, any> = {
            marginTop: -8,
            width: 'calc(100% - 48px)',
            display: 'inline-block',
            float: 'right',
        }
        return (
            <div style={{ ...barContainerStyle }}>
                <Bar
                    negative={isNeg}
                    percent={percent}
                    withAnimation={animate}
                ></Bar>
            </div>
        )
    }

    render() {
        const hasNegativeProp: boolean = this.props.negative
            ? this.props.negative
            : false

        const hasNegativeValue: boolean =
            this.props.percent && this.props.percent < 0 ? true : false

        const isNegative: boolean = hasNegativeProp || hasNegativeValue

        const percent: number = this.props.percent
            ? (this.props.percent as number)
            : 0

        const animate: boolean =
            this.props.withAnimation === false
                ? (this.props.withAnimation as boolean)
                : true

        const title: string = this.props.title ? this.props.title : 'Untitled'

        const securities: number = this.props.securities
            ? this.props.securities
            : 0

        const passedStyle: Record<string, any> = this.props.style as Record<
            string,
            any
        >

        const printing = this.props.printing && !this.props.group

        return (
            <>
                <div
                    style={{
                        ...this.getContainerStyles(passedStyle, printing),
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: 20,
                            backgroundColor: DEBUG ? 'teal' : 'transparent',
                            marginTop: -15,
                        }}
                    >
                        {printing ? (
                            <Row style={{ marginTop: 10 }}>
                                <Col style={{ paddingRight: 15 }}>
                                    <span
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 500,
                                            verticalAlign: 'top',
                                            //backgroundColor: 'rgba(255,0,0,0.1)',
                                            marginLeft: 0,
                                            display: 'inline-block',
                                            color: isNegative
                                                ? globals.colors.negative.red
                                                : globals.colors.positive.blue,
                                        }}
                                    >
                                        {percent < 1
                                            ? '<1'
                                            : percent.toFixed(0)}
                                        %
                                    </span>
                                </Col>
                                <Col style={{ paddingTop: 2 }}>
                                    <span
                                        style={{
                                            fontFamily:
                                                '"URW Geometric Cond", sans-serif',
                                            color: globals.colors.text.default,
                                            fontSize: Size.FONT_SIZE,
                                            fontWeight: 900,
                                            backgroundColor: DEBUG
                                                ? 'lime'
                                                : 'transparent',
                                            height: 16,
                                        }}
                                    >
                                        {title
                                            .replace('(Best In Class)', '')
                                            .slice(0, 27)}
                                        {/* {title.replace('(Best In Class)', '')
                                            .length > 40 && '...'} */}
                                    </span>
                                </Col>
                                {this.props.category &&
                                    ![
                                        'environmental',
                                        'social',
                                        'governance',
                                    ].includes(
                                        this.props.category.toLowerCase()
                                    ) && (
                                        <Col
                                            style={{
                                                textDecoration: 'underline',
                                                textTransform: 'uppercase',
                                                fontSize: 12,
                                                paddingTop: 3,
                                                paddingLeft: 10,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                this.props.handleContributors(
                                                    this.props.category
                                                )
                                            }}
                                        >
                                            <span
                                                onClick={() => {
                                                    this.props.handleContributors(
                                                        this.props.category
                                                    )
                                                }}
                                            >
                                                {!printing && `View Securities`}
                                            </span>
                                        </Col>
                                    )}
                            </Row>
                        ) : (
                            <>
                                {this.addTitleComponent(title)}
                                {this.addSecuritiesComponent(
                                    securities,
                                    isNegative,
                                    title
                                )}
                            </>
                        )}
                    </div>

                    <div
                        style={{
                            top: 0,
                            padding: 0,
                            width: '100%',
                            height: 40,
                            backgroundColor: DEBUG ? 'lime' : 'transparent',
                        }}
                    >
                        <div style={{ marginTop: 10 }}>
                            <div
                                style={{
                                    marginTop: 26,
                                }}
                            >
                                {!printing &&
                                    this.addNumberRollComponent(
                                        percent,
                                        animate,
                                        isNegative
                                    )}
                            </div>
                            <div
                                style={{
                                    marginTop: -40,
                                }}
                            >
                                {!printing &&
                                    this.addBarComponent(
                                        percent,
                                        animate,
                                        isNegative
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
