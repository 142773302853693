import React from 'react'
import Modal from '../Modal'
import Button from '../../insightui/Button'
import { Row, Col } from 'react-flexbox-grid'
import { Input } from '../../insightui/Input'
import { IPortfolio } from '../../models/api'
import TagSelect from '../../insightui/TagSelect'
import axios from 'axios'
import { config } from '../../models/config'
import { store } from '../../store'
import { toast } from 'react-toastify'

interface SettingsModalProps {
    portfolio: IPortfolio
    isActive: boolean
    onClose: () => void
    onUpdate: () => void
}

export default class SettingsModal extends React.Component<SettingsModalProps> {
    state: IPortfolio = this.props.portfolio

    changeValue = (key: string, value: string) => {
        this.setState({ [key]: value })
    }

    savePortfolioData = () => {
        axios
            .put(
                `${config.api}/api/portfolios`,
                {
                    name: this.state.name,
                    type: this.state.type,
                    id: this.state.id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response) => {
                toast.success('Updated successfully')
                this.props.onUpdate()
                this.props.onClose()
            })
            .catch((err) =>
                toast.error('Failed to update portfolio. Please Try again')
            )
    }

    render() {
        return (
            <Modal isActive={this.props.isActive}>
                <span style={{ fontWeight: 600 }}>Portfolio Name</span>
                <Row style={{ paddingBottom: 30, paddingTop: 7 }}>
                    <Input
                        value={this.state.name}
                        width={'100%'}
                        onChange={(e) => this.changeValue('name', e)}
                    />
                </Row>
                <span style={{ fontWeight: 600 }}>Account Type</span>
                <Row style={{ paddingBottom: 30, marginTop: 0, paddingTop: 0 }}>
                    <TagSelect
                        items={[
                            { title: 'Individual', value: 'Individual' },
                            { title: 'Joint', value: 'Joint' },
                            { title: 'IRA', value: 'IRA' },
                            { title: 'Roth IRA', value: 'Roth IRA' },
                            { title: 'Trust', value: 'Trust' },
                            { title: '401(k)', value: '401(k)' },
                            { title: '403(b)', value: '403(b)' },
                            { title: '529', value: '529' },
                            { title: 'HSA', value: 'HSA' },
                            { title: 'Other', value: 'Other' },
                        ]}
                        value={this.state.type}
                        onChange={(output) => this.changeValue('type', output)}
                    />
                </Row>
                <Row>
                    <Col xs={6}>
                        <Button
                            type="outlined"
                            style={{ width: '100%' }}
                            onClick={this.props.onClose}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={this.savePortfolioData}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Modal>
        )
    }
}
