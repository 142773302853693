import React from 'react'
import { config } from '../../models/config'
import ImpactScore from '../../insightui/ImpactScore'
import { Row, Col } from 'react-flexbox-grid'
import { isMobile, isTablet } from 'react-device-detect'
import { withRouter } from 'react-router-dom'

class PortfolioCard extends React.Component {
    state = {
        createPortfolioModalActive: false,
    }
    render() {
        const { portfolio, selected, setSelected } = this.props
        return (
            <div
                style={{
                    width: isMobile && !isTablet ? 330 : 270,
                    padding: '20px 30px 25px',
                    paddingRight: 0,
                    marginBottom: 30,
                    backgroundColor: 'white',
                    marginRight: 30,
                    cursor: 'pointer',
                }}
                onClick={() => {
                    this.props.history.push(`/portfolios/${portfolio.id}`)
                    window.location.reload()
                }}
            >
                <Row style={{ alignItems: 'stretch' }}>
                    <Col xs={6}>
                        <div style={{ display: 'inline-block' }}>
                            {selected !== undefined && (
                                <input
                                    type="checkbox"
                                    checked={selected}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={() => {
                                        setSelected(!selected)
                                    }}
                                />
                            )}
                        </div>
                    </Col>
                    <Col
                        xs={6}
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <div
                            style={{
                                display: 'inline-block',
                                paddingRight: 20,
                            }}
                        >
                            <ImpactScore
                                type="small"
                                score={portfolio.impactScore}
                                withAnimation={false}
                                match={portfolio.impactScore < 0}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h3 style={{ fontSize: 22 }}>
                        {portfolio.name.length > 25
                            ? portfolio.name.slice(0, 25) + '...'
                            : portfolio.name}
                    </h3>
                </Row>
                <Row>
                    <span
                        style={{
                            // ...globals.typeface.defaultText,
                            ...{ fontSize: 13 },
                        }}
                    >
                        {portfolio.type} | $
                        {portfolio.monetaryValue
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                        | {portfolio.totalHoldings}{' '}
                        {portfolio.totalHoldings === 1 ? 'Holding' : 'Holdings'}
                    </span>
                </Row>
                <Row>
                    <span
                        style={{
                            // ...globals.typeface.defaultText,
                            ...{ fontSize: 13, marginTop: 15 },
                        }}
                    >
                        Last Modified:{' '}
                        <strong>
                            {new Date(
                                portfolio.lastModified
                            ).toLocaleDateString('en-US')}
                        </strong>
                    </span>
                </Row>
            </div>
        )
    }
}

export default withRouter(PortfolioCard)
