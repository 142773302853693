import React from 'react'
import Modal from '../../components/Modal'
import { Row, Col } from 'react-flexbox-grid'
import Button from '../../insightui/Button'

interface ConfirmProps {
    isActive: boolean
    message: string | React.ReactNode
    onClose: () => void
    onConfirm: () => void
    confirmMessage?: string
    cancelMessage?: string
    subTitle?: string | React.ReactNode
}

export default class Confirm extends React.Component<ConfirmProps> {
    render() {
        const {
            isActive,
            message,
            onClose,
            onConfirm,
            confirmMessage,
            cancelMessage,
            subTitle,
        } = this.props
        return (
            <Modal isActive={isActive}>
                <Row style={{ padding: 20 }}>
                    <span
                        style={{
                            color: '#2c88aa',
                            fontSize: 23,
                            fontWeight: 700,
                        }}
                    >
                        {message}
                    </span>
                </Row>
                {subTitle && (
                    <Row style={{ padding: 20, paddingTop: 0 }}>
                        <span
                            style={{
                                color: '#2c88aa',
                                fontSize: 14,
                                fontWeight: 300,
                            }}
                        >
                            {subTitle}
                        </span>
                    </Row>
                )}
                <Row style={{ paddingTop: 30 }}>
                    <Col xs={6}>
                        <Button
                            type="outlined"
                            style={{ width: '100%' }}
                            onClick={onClose}
                        >
                            {cancelMessage ? cancelMessage : 'Cancel'}
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button
                            type="primary"
                            onClick={onConfirm}
                            style={{ width: '100%' }}
                        >
                            {confirmMessage ? confirmMessage : 'Confirm'}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        )
    }
}
