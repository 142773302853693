import { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { globals } from '../utils/globals'

type InputFieldTypes = 'default'

export default class InputField extends Component<{
    width?: number | string
    title: string
    value: string
    id: string
    type?: InputFieldTypes
    isPassword?: boolean
    renderIf?: boolean
    placeholder?: string
    disableIf?: boolean
    style?: Record<string, any>
    onChange?: (e: any, id: string) => void
    formatInput?: (value: string) => string
}> {
    state = {
        type: this.props.type ? this.props.type : 'default',
        value: this.props.value,
    }

    renderField(params: {
        title: string
        value: string
        onChange?: (e: any) => void
        isPassword?: boolean
        width?: number | string
        isRendered?: boolean
    }) {
        const isRendered: boolean = params.isRendered !== false ? true : false
        const isPass: boolean = !params.isPassword ? false : params.isPassword
        const { placeholder } = this.props
        const inputStyle: Record<string, any> = {
            width: params.width ? params.width : '95%',
            display: 'inline-block',
            height: 50,
            border: isMobile ? '2px solid #f2f2f2' : 0,
            boxShadow: '0 2px 8px #dbdbdc',
            paddingLeft: 20,
            fontWeight: 500,
            paddingTop: 20,
            fontSize: 14,
            color: 'black',
            borderRadius: 4,
        }

        const inputHeader: Record<string, any> = {
            ...globals.typeface.spacedUppercase,
            position: 'absolute',
            marginLeft: 20,
            marginTop: 10,
            fontWeight: 700,
        }
        if (isRendered)
            return (
                <div
                    style={{
                        ...this.props.style,
                        marginTop: 20,
                        opacity: this.props.disableIf ? 0.5 : 1.0,
                    }}
                >
                    <p style={inputHeader}>{params.title}</p>

                    <input
                        style={inputStyle}
                        type={isPass ? 'password' : ''}
                        value={
                            this.props.formatInput
                                ? this.props.formatInput(this.props.value)
                                : this.props.value
                        }
                        onChange={params.onChange}
                        placeholder={placeholder ?? '—'}
                        readOnly={this.props.disableIf}
                    />
                </div>
            )
        return <></>
    }

    render() {
        return this.renderField({
            width: this.props.width,
            title: this.props.title,
            value: this.props.value,
            isPassword: this.props.isPassword,
            onChange: (e: any) => {
                if (this.props.onChange)
                    this.props.onChange(
                        e,
                        this.props.id ? this.props.id : 'null'
                    )
            },
            isRendered: this.props.renderIf,
        })
    }
}
