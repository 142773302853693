import { auth } from '../reducers/auth'
import { persistStore, persistReducer } from 'redux-persist';
import { createStore } from 'redux';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, auth); 

const store = createStore(persistedReducer);
const persistor = persistStore(store) 

export { store, persistor }

