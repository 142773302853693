import React from 'react'
import LoginRegistration from '../components/Registration/LoginRegistration'
import Checkout, { Billing } from '../components/Registration/Checkout'
import { RegistrationRequest, CheckoutRequest } from '../models/state'
import PlansAndPricing from '../pages/PlansAndPricing'
import { register } from '../reducers/auth'
import axios from 'axios'
import { config } from '../models/config'
import RegistrationComplete from '../pages/RegistrationComplete'
import { toast } from 'react-toastify'
import { store } from '../store'
import { history } from '../App'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Cookies } from 'react-cookie'

const TEST_CREATE_ACCOUNT_PSEUDO_DATA = false
const TEST_PREVENT_CHARGING_CARD = false

export const enum Pages {
    REGISTER,
    CHECKOUT,
    REGISTRATION_SUCCESS,
}

export enum Plans {
    BASIC,
    PRO,
    ADVISOR,
}

export enum CharacterLimits {
    LOGIN_MIN = 5,
    LOGIN_MAX = 255,
    EMAIL_MIN = 5,
    EMAIL_MAX = 255,
    ZIPCODE_MIN = 5,
    ZIPCODE_MAX = 5,
    PHONE_NUMBER_MIN = 10,
    PHONE_NUMBER_MAX = 10,
    FIRST_NAME_MIN = 2,
    FIRST_NAME_MAX = 255,
    LAST_NAME_MIN = 2,
    LAST_NAME_MAX = 255,
    PASSWORD_MIN = 8,
    PASSWORD_MAX = 255,
}

export interface CardData {
    cardNumber: String
    expMonth: number
    expYear: number
    cvc: string
    zipCode: string
}

export interface PaymentMethod {
    cardNumber: String
    expMonth: number
    expYear: number
    cvc: string
    zipCode: string
    userId: string
}

export function forgotPassword(email: string) {
    axios
        .post(`${config.api}/api/account/reset-password/init`, email, {
            headers: {
                'Content-Type': 'text/xml',
            },
        })
        .then((response: any) => {
            toast.success('Check your email for a link to reset your password.')
        })
        .catch((error) => {
            toast.error('There was an error, please contact support.')
        })
}

export function changePassword(key: string, password: string) {
    axios
        .put(`${config.api}/api/account/reset-password/finish`, {
            key: key,
            newPassword: password,
        })
        .then((response: any) => {
            toast.success('Password has been updated. Redirecting to login', {
                onClose: () => {
                    history.push('/login')
                },
            })
        })
        .catch((error) => {
            try {
                let message = JSON.parse(error.response.data.title).message
                toast.error(message)
            } catch (e) {
                toast.error('There was an error. Please contact support')
            }
        })
}

function createPaymentMethod(
    params: {
        paymentMethod: PaymentMethod
        dummyResponse?: any
        onFail?: (response: string) => void
    },
    onSuccess?: (response: string) => void
) {
    if (params.dummyResponse) {
        if (onSuccess) onSuccess(params.dummyResponse.data)
        return
    }

    axios
        .post(
            `${config.api}/api/payment/payment-method`,
            {
                cardNumber: params.paymentMethod.cardNumber,
                expMonth: params.paymentMethod.expMonth,
                expYear: params.paymentMethod.expYear,
                cvc: params.paymentMethod.cvc,
                zipCode: params.paymentMethod.zipCode,
            },
            {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            }
        )
        .then((response: any) => {
            if (onSuccess) onSuccess(response.data)
        })
        .catch((error) => {
            // if (onSuccess) onSuccess(null) //uncomment this for null server testing
            if (params.onFail) params.onFail(error)
        })
}

function createSubscription(
    userId: string,
    billing: number,
    plan: Plans,
    couponId: string,
    params: {
        dummyResponse?: any
    },
    onSuccess?: (response: any) => void,
    onFailure?: () => void
) {
    let priceId = ''
    if (plan === Plans.BASIC) {
        if (billing === Billing.YEARLY) {
            priceId = 'price_1KgtRXCzf3NLB1twKicWrXyA'
        } else {
            priceId = 'price_1KgtRXCzf3NLB1twYuoHOYXA'
        }
    } else {
        if (billing === Billing.YEARLY) {
            priceId = 'price_1KgtS6Czf3NLB1twQfGTdDcs'
        } else {
            priceId = 'price_1KgtS6Czf3NLB1twMiFsvxvc'
        }
    }

    if (params && params.dummyResponse) {
        if (onSuccess) onSuccess('')
        return
    }

    axios
        .post(
            `${config.api}/api/payment/subscription`,
            {
                priceId: priceId,
                couponId: couponId ?? '',
            },
            {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            }
        )
        .then((response: any) => {
            if (onSuccess) onSuccess('')
        })
        .catch((error) => {
            if (onFailure) onFailure()
            toast.error('Your payment failed, please try again')
        })
}

function registerNewUser(
    request: RegistrationRequest,
    onSuccess?: (response: any) => void
) {
    register({
        request: request,
        onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response)
        },
        onFail: (response: any) => {},
    })
}

interface PathParams {
    code?: string
}

interface RegsiterProps extends RouteComponentProps<PathParams> {
    defaultPage?: number
}

class Register extends React.Component<RegsiterProps> {
    state = {
        plan: Plans.BASIC,
        request: {} as any,
        page: this.props.defaultPage ?? Pages.REGISTER,
        params: {},
    }

    componentDidMount() {
        const code = this.props.match.params.code
        if (code) {
            new Cookies().set('signup_code', code)
        }
    }

    passCustomerData = (request: CheckoutRequest) => {
        this.setState({
            request: request,
            page: (
                <CheckoutPage
                    request={request}
                    plan={this.state.plan}
                    nav={this.nav}
                />
            ),
        })
    }

    nav = (destination: number, params?: any) => {
        this.setState({ page: destination, params: params })
    }

    render() {
        switch (this.state.page) {
            case Pages.REGISTER:
                return (
                    <RegistrationPage
                        plan={this.state.plan}
                        nav={this.nav}
                        params={this.state.params}
                        passCustomerData={this.passCustomerData}
                    />
                )
            case Pages.CHECKOUT:
                return (
                    <CheckoutPage
                        request={this.state.request}
                        plan={this.state.plan}
                        nav={this.nav}
                        params={this.state.params}
                    />
                )
            case Pages.REGISTRATION_SUCCESS:
                return <RegistrationComplete />
        }
    }
}

export default withRouter(Register)

export class PlansAndPricingPage extends React.Component<{
    onSubmission?: (value: Plans) => void
    nav?: (destination: number, params?: any) => void
    params?: any
}> {
    render() {
        return <PlansAndPricing onSubmission={this.props.onSubmission} />
    }
}

interface RegistrationPageProps extends RouteComponentProps {
    plan: Plans
    nav?: (destination: number, params?: any) => void
    passCustomerData?: (request: CheckoutRequest) => void
    params?: any
}

class RegistrationPageComponent extends React.Component<RegistrationPageProps> {
    render() {
        return (
            <LoginRegistration
                type="registration"
                plan={this.props.plan}
                onSubmission={(request: RegistrationRequest) => {
                    const registrationRequest: any = {
                        firstName: request.firstName,
                        lastName: request.lastName,
                        email: request.email,
                        zipCode: request.zipCode,
                        password: request.password,
                    }

                    if (this.props.plan === Plans.BASIC) {
                        registerNewUser(registrationRequest, () => {
                            // this.props.history.push('/checkout')
                            this.props.nav(Pages.REGISTRATION_SUCCESS)
                        })
                    } else if (
                        this.props.plan === Plans.PRO ||
                        this.props.plan === Plans.ADVISOR
                    ) {
                        const onSuccess = (response: any) => {
                            const checkoutRequest: CheckoutRequest = {
                                ...request,
                                userId: response.data.id,
                                stripeCustomerId:
                                    response.data.stripeCustomerId,
                            }

                            if (this.props.passCustomerData)
                                this.props.passCustomerData(checkoutRequest)

                            //if (this.props.nav) this.props.nav(Pages.CHECKOUT)
                            //if (this.props.passCustomerData)
                        }

                        registerNewUser(registrationRequest, onSuccess)
                    }
                }}
                params={this.props.params}
            />
        )
    }
}

interface CheckoutPageProps extends RouteComponentProps {
    request: CheckoutRequest
    plan: Plans
    nav?: (destination: number, params?: any) => void
    params?: any
}

export class CheckoutPageComponent extends React.Component<CheckoutPageProps> {
    state = {
        pending: false,
    }
    onSubmissionExtended(
        request: CheckoutRequest,
        card: CardData,
        billing: number,
        plan: Plans,
        couponId: string,
        onSuccess?: () => void,
        onFailure?: () => void
    ) {
        this.setState({ pending: true })
        const userId: string = request.userId

        const paymentMethod: PaymentMethod = {
            ...card,
            userId: userId,
        }

        const createPaymentParams: any = {
            paymentMethod: paymentMethod,
            dummyResponse: TEST_CREATE_ACCOUNT_PSEUDO_DATA,
        }

        // if creation of user stripe-account was successful, now
        // try to create a payment method for users stripe-account
        createPaymentMethod(createPaymentParams, (accountId: string) => {
            createSubscription(
                userId,
                billing,
                plan,
                couponId,
                {
                    dummyResponse: TEST_PREVENT_CHARGING_CARD
                        ? true
                        : undefined,
                },
                () => {
                    this.props.history.push('/')
                },
                () => {
                    if (onFailure) {
                        onFailure()
                    }
                }
            )
            this.setState({ pending: false })
        })
    }
    render() {
        return (
            <Checkout
                plan={this.props.plan}
                request={this.props.request}
                pending={this.state.pending}
                params={this.props.params}
                onSubmission={(
                    request: CheckoutRequest,
                    card: CardData,
                    billing: number,
                    plan: Plans,
                    couponId: string,
                    onSuccess?: () => void,
                    onFailure?: () => void
                ) => {
                    this.onSubmissionExtended(
                        request,
                        card,
                        billing,
                        plan,
                        couponId,
                        onSuccess,
                        onFailure
                    )
                }}
            />
        )
    }
}

const CheckoutPage = withRouter(CheckoutPageComponent)
export const RegistrationPage = withRouter(RegistrationPageComponent)
export { CheckoutPage, RegistrationPageComponent }
