import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import DropdownTable from '../../insightui/DropdownTable'
import ESGFundCriterion from '../../insightui/ESGFundCriterion'
import { config } from '../../models/config'
import axios from 'axios'
import { isNegative, isPositive } from '../../services/screeningProfileService'
import { toast } from 'react-toastify'
import ContributingSecurities from './ContributingSecurities'
import { store } from '../../store'

export class Representation extends React.Component<{
    instrument: any
    printing?: boolean
    negative: boolean
    handleContributors: (category: string) => void
}> {
    getMaxExposure = (negative: boolean) => {
        let items: any = []
        this.props.instrument.esgIssueCriteriaRollup
            .filter(
                (item: any) =>
                    (negative && isNegative(item.criterion.name)) || (!negative && isPositive(item.criterion.name))
            )
            .forEach((item: any) => items.push(item.weightedPercentage))

        return Math.max.apply(Math, items)
    }

    getCategoryCount = (negative: boolean) => {
        return this.props.instrument.esgIssueCriteriaRollup.filter(
            (item: any) =>
                (negative && isNegative(item.criterion.name)) || (!negative && isPositive(item.criterion.name))
        ).length
    }

    renderFunds = (item: any) => {
        return (
            <>
                {((this.props.negative && isNegative(item.criterion.name)) || (!this.props.negative && isPositive(item.criterion.name))) ? (
                    <>
                        <ESGFundCriterion
                            title={item.criterion.title}
                            securities={item.numberOfHoldings}
                            printing={this.props.printing}
                            category={item.criterion.name}
                            percent={item.weightedPercentage}
                            handleContributors={
                                this.props.handleContributors
                            }
                            negative={this.props.negative}
                        />
                    </>
                ) : (
                    <></>
                )}
            </>
        )
    }

    render() {
        const { instrument, negative } = this.props
        return (
            <DropdownTable
                securities={this.getCategoryCount(negative)}
                title={negative ? 'Negative' : 'Positive'}
                total={this.getMaxExposure(negative)}
                header={true}
                negative={negative}
                printing={this.props.printing}
                nocategories={this.getCategoryCount(negative) === 0}
            >
                {instrument.esgIssueCriteriaRollup?.map((item: any) => (
                    <>{this.renderFunds(item)}</>
                ))}
            </DropdownTable>
        )
    }
}
export interface IContributor {
    financialInstrumentId: number
    ticker: string
    name: string
}
export default class ESGCriteriaFund extends React.Component<{
    instrument: any
    compare?: boolean
    portfolio?: boolean
    printing?: boolean
}> {
    state = {
        viewContributors: false,
        contributors: [],
    }

    handleContributorsRequest = async (category: string) => {
        this.setState({ viewContributors: true })
        await axios
            .get(
                this.props.portfolio
                    ? `${config.api}/api/portfolios/${this.props.instrument.id}/esg-issues/${category}/contributors`
                    : `${config.api}/api/tickers/${this.props.instrument.financialInstrumentId}/esg-issues/${category}/contributors`,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response) => {
                const data: Array<IContributor> = response.data
                this.setState({ contributors: data })
            })
            .catch((err) => {
                toast.error('Failed to fetch contributors.')
            })
    }

    render() {
        const { instrument, compare } = this.props
        return (
            <div style={{ backgroundColor: '#F7F7F7' }}>
                <ContributingSecurities
                    isActive={this.state.viewContributors}
                    contributors={this.state.contributors}
                    onClose={() => this.setState({ viewContributors: false })}
                />
                <Row>
                    <Col
                        xs={this.props.printing ? 6 : 12}
                        lg={compare ? 12 : 6}
                        md={6}
                        sm={6}
                    >
                        <Representation
                            instrument={instrument}
                            printing={this.props.printing}
                            negative={false}
                            handleContributors={this.handleContributorsRequest}
                        />
                    </Col>
                    <Col
                        xs={this.props.printing ? 6 : 12}
                        lg={compare ? 12 : 6}
                        md={6}
                        sm={6}
                    >
                        <Representation
                            instrument={instrument}
                            negative
                            printing={this.props.printing}
                            handleContributors={this.handleContributorsRequest}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}
