import {
    IAccount,
    ICustomProfile,
    IRankedGroup,
    ITrendingGroup,
    UniversalSearchResultItem,
} from './api'
import User from './user'
import { Cookies } from 'react-cookie'
import { FAITH_BASED_EVANGELICAL_DEFAULT_PROFILE } from './profiles'

export interface HomeState {
    loading: boolean
    tokens: Record<string, any>
    query: string
    lastChange: any
    scrollActive: boolean
    prev: number
    loggedIn: boolean
    token: string | null
    account: IAccount | null
    count: number
    ready: boolean
    trending: any
    open: boolean
    ranked: any
}

export interface IUser {
    id: string
    email: string
    name: string
}

export interface ISessionState {
    authUser: User | null
}

export interface IUserHash {
    [key: string]: IUser
}

export interface IUsersState {
    users: IUserHash
}

export interface AdvancedSearchState {
    searchParams: {
        ticker: string
    }
    ready: boolean
    token: string
}

export interface ESGCriterionFundState {
    positiveEnvironmental: number
    positiveSocial: number
    positiveGovernance: number
    negativeEnviornmental: number
    negativeGovernance: number
    negativeSocial: number
}

export interface FooterLink {
    name: string
    href: string
    style?: Record<string, any>
}

export interface ScreeningProfileItem {
    title: string
    value: number
}

export interface TagData {
    title: string
    toggle: boolean
    style?: Record<string, any>
}

export interface TagCategory {
    title: string
    type: number
    style?: Record<string, any>
}

export interface RadioProps {
    value?: number
    id?: string
    title: string
    onSetScreen?: (category: string, value: number) => void
    getRadioState?: (key: string) => number
    setScreeningProfile?: (name: string) => void
    setScreeningProfileButtonState?: () => void
}

export interface RadioParams {
    id: number
    groupId: string
    functionality: any
    isChecked: boolean
}

export interface ScreeningCategory {
    title: string
    type: number
}

export type AuthStatus = 'LOADING' | 'ERROR' | 'COMPLETE' | 'UNBEGUN'

export type Authority =
    | 'ROLE_USER'
    | 'ROLE_PREMIUM'
    | 'ROLE_ADMIN'
    | 'ROLE_ADVISOR'
export interface IAppState {
    isLogged: boolean
    token: string | null
    activeProfile: ICustomProfile
    authStatus: AuthStatus
    authorities: Array<Authority>
}

export const defaultIAppState: IAppState = {
    isLogged: Boolean(new Cookies().get('token')),
    token: Boolean(new Cookies().get('token'))
        ? new Cookies().get('token')
        : null,
    activeProfile: new Cookies().get('profile')
        ? new Cookies().get('profile')
        : FAITH_BASED_EVANGELICAL_DEFAULT_PROFILE,
    authStatus: 'UNBEGUN',
    authorities: ['ROLE_USER'], //each user is a basic user by default
}

export interface AuthResponse {
    id_token: string
}

export interface RankedGroupState {
    ranked: Array<ITrendingGroup> | Array<IRankedGroup> | null
}

export interface RegistrationRequest {
    firstName: string
    lastName: string
    email: string
    login: string
    zipCode: string
    password: string
}

export interface CheckoutRequest {
    firstName: string
    lastName: string
    email: string
    login: string
    zipCode: string
    password: string
    userId: string
    stripeCustomerId: string
}
