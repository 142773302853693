import { Component } from 'react'
import chevronUp from '../images/chevron-up.svg'
import chevronDown from '../images/chevron-down.svg'

// extend type options
type ChevronType = 'vertical' | 'horizontal'

interface ChevronProps {
    height?: number
    width?: number
    type?: ChevronType
    stateindex?: number
    style?: object
}

const chevronStyle: Record<string, any> = {
    position: 'relative',
    border: 'none',
    width: '25px',
    height: '100%',
    marginTop: '0',
    padding: '0',
    display: 'inline-block',
    backgroundSize: '100%',
}

export default class Chevron extends Component<ChevronProps> {
    getIcon(index: number) {
        if (this.props.stateindex === 0) return chevronDown
        return chevronUp
    }

    stateindex: number = this.props.stateindex as number

    render() {
        return (
            <>
                <img
                    alt=""
                    className="chevron"
                    src={this.getIcon(this.stateindex)}
                    style={{
                        ...chevronStyle,
                        ...(this.props.style ? this.props.style : {}),
                    }}
                />
            </>
        )
    }
}
