import React from 'react'
import { IPortfolio } from '../../models/api'
import Modal from '../Modal'
import { Row, Col } from 'react-flexbox-grid'
import TagSelect from '../../insightui/TagSelect'
import Button from '../../insightui/Button'
import { LoadingOutlined } from '@ant-design/icons'
import { Input } from '../../insightui/Input'
import axios from 'axios'
import { config } from '../../models/config'
import { toast } from 'react-toastify'
import { store } from '../../store'

interface PortfolioCompareProps {
    portfolios: Array<IPortfolio>
    isActive: boolean
    portfolio: IPortfolio
    activePortfolio: IPortfolio
    onClose: () => void
    onCompare: (
        id: number,
        portfolio: IPortfolio,
        portfolioUniverse: Array<IPortfolio>
    ) => void
}

export default class PortfolioComapre extends React.Component<PortfolioCompareProps> {
    // needed two items arrays since we modify the portfolio items when livesearching on this modal
    state = {
        ready: false,
        items: [],
        initialItems: [],
        searchString: '',
        compareId: 0,
        portfolioList: [],
    }

    componentDidMount() {
        this.fetchAllPortfolios()
    }

    createTagSelectFromPortfolioList = (
        data: Array<IPortfolio>
    ): Array<{
        title: string
        value: string
    }> => {
        let tags: Array<{ title: string; value: string }> = []
        data.forEach((portfolio: IPortfolio) => {
            tags.push({
                title: portfolio.name,
                value: portfolio.id.toString(),
            })
        })

        return tags
    }

    fetchAllPortfolios = () => {
        axios
            .get(`${config.api}/api/portfolios`, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response) => {
                this.setState({
                    ready: true,
                    initialItems: this.createTagSelectFromPortfolioList(
                        (response.data as any).portfolios
                    ),
                    items: this.createTagSelectFromPortfolioList(
                        (response.data as any).portfolios
                    ),
                    portfolioList: (response.data as any).portfolios,
                })
            })
    }

    handleSearch = (e: string) => {
        this.setState({
            searchString: e,
            items: this.state.initialItems.filter(
                (portfolio: { title: string; value: string }) =>
                    portfolio.title.toLowerCase().includes(e)
            ),
        })
    }

    onCompare = () => {
        this.props.onClose()
        if (this.state.compareId != 0) {
            this.props.onCompare(
                this.state.compareId,
                this.props.portfolio,
                this.state.portfolioList
            )
        } else {
            toast.error('Please Select a portfolio')
        }
    }

    render() {
        const { portfolios, isActive, activePortfolio } = this.props
        return (
            <Modal isActive={isActive}>
                <Row style={{ paddingBottom: 30 }}>
                    <span
                        style={{
                            color: '#2c88aa',
                            fontSize: 29,
                            fontWeight: 700,
                        }}
                    >
                        Compare Portfolios
                    </span>
                </Row>
                <Row style={{ paddingBottom: 30 }}>
                    <span
                        style={{
                            color: 'black',
                            fontSize: 23,
                            fontWeight: 700,
                        }}
                    >
                        <span
                            style={{
                                color: 'black',
                                marginRight: 15,
                            }}
                        >
                            Comparing:
                        </span>
                        <span
                            style={{
                                backgroundColor: '#2c88aa',
                                color: 'white',
                                padding: 8,
                                borderRadius: 7,
                            }}
                        >
                            {activePortfolio.name}
                        </span>
                    </span>
                </Row>
                <Row style={{ paddingBottom: 20 }}>
                    <Input
                        placeholder="Search"
                        value={this.state.searchString}
                        width={'100%'}
                        onChange={this.handleSearch}
                    />
                </Row>
                <Row>
                    {this.state.items ? (
                        <div
                            style={{
                                maxHeight: 139,
                                overflow: 'auto',
                            }}
                        >
                            <TagSelect
                                items={this.state.items}
                                onChange={(item) => {
                                    this.setState({ compareId: item })
                                }}
                            />
                        </div>
                    ) : (
                        <>
                            <LoadingOutlined
                                style={{
                                    marginRight: 7,
                                    position: 'relative',
                                    bottom: 2,
                                }}
                            />
                            Loading...
                        </>
                    )}
                </Row>
                <Row style={{ paddingTop: 30 }}>
                    <Col xs={6}>
                        <Button
                            type="outlined"
                            style={{ width: '100%' }}
                            onClick={this.props.onClose}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={this.onCompare}
                        >
                            Compare
                        </Button>
                    </Col>
                </Row>
            </Modal>
        )
    }
}
