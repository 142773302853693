import { Component, Children } from 'react'
import { globals } from '../utils/globals'
import { css } from 'glamor'
import Chevron from './Chevron'

const DEBUG: boolean = false

enum Sizes {
    BORDER_RADIUS = 5,
}

interface DropdownMenuProps {
    height?: number
    width?: number
    fontMarginTop?: number
    stateindex?: number
    style?: Record<string, any>
    value?: string
    appendComponent?: Child
    appendComponents?: Child[]
    appendMenuItemData?: MenuItemData[]
    appendStringData?: string[]
    isInitial?: boolean
    disabled?: boolean
    params?: any
    setSelection?: (selection: string) => void
}

function manageState(stateindex: number, max: number) {
    let newIndex: number
    const zeroIndex = max - 1
    if (stateindex >= zeroIndex) newIndex = 0
    else newIndex = stateindex + 1
    if (DEBUG) console.log('setting state: ' + newIndex)
    return newIndex
}

function getSharedStyleText(override?: number) {
    const defaultMargin: number = 17
    const implicitMargin: number = override ? override : defaultMargin // vertical align workaround
    return {
        textAlign: 'left',
        width: '100%',
        margin: 0,
        padding: 0,
        paddingTop: implicitMargin,
        ...globals.styles.presets.noselect,
        ...globals.typeface.footerLinks,
    }
}

function getDefaultBoxHeight(This?: any) {
    if (This && This.props.height) return This.props.height
    return 45
}

interface Child {
    component: {}
}

export interface MenuItemData {
    value: string
    onClick?: () => void
    isSelected?: boolean
}

export default class DropdownMenu extends Component<DropdownMenuProps> {
    keys: number = 0
    stateLimiter: number = 0
    data: any[] = this.getComponentData()

    state = {
        stateindex: 0,
        selected:
            this.props.isInitial && this.data.length > 0
                ? this.data[0].value
                : this.props.value
                ? this.props.value
                : 'Menu',
        length: this.data.length,
        /* 
        the animate prop is used internally to stop the animation 
        after a user clicks (selects) an option from the drop down.
        Its function here is to allow animation when the menu 
        slides-down and when it slides-up, but not when a 
        selection (onClick) has occurred on a menu-item, which then 
        just snaps close.
        */
        animate: true,
    }

    getRadius(override?: Record<string, any>) {
        return override && override.borderRadius >= 0
            ? override.borderRadius
            : Sizes.BORDER_RADIUS
    }

    getBorderColor(override?: Record<string, any>) {
        return override && override.borderColor
            ? override.borderColor
            : globals.colors.inspire.darknavy
    }

    getDropdownStyle(params: any) {
        const stateindex: number = params.stateindex
        const quantity: number = params.quantity
        const override: Record<string, any> = params.styles

        const index: number = this.getStateWithPatch(stateindex, quantity)
        const isStateOpen: boolean = index < 1
        const animate: string = this.state.animate ? '250ms' : 'none'
        const radius: number = this.getRadius(override)
        const h: any = isStateOpen
            ? radius
            : getDefaultBoxHeight(this) * quantity
        const w: any = this.props.width
            ? this.props.width
            : globals.sizes.button.default.width

        const style: Record<string, any> = {
            width: w,
            height: h,
            backgroundColor: 'white',
            display: 'block',
            cursor: 'pointer',
            overflow: 'hidden',
            transition: animate,
            position: 'absolute',
            borderRadius: `0 0 ${radius}px ${radius}px`,
            verticalAlign: 'middle',
        }
        return style
    }

    getSelectionBoxStyle(override: Record<string, any>) {
        const p: string = this.props.height ? '' : '0.9em 0'
        const radius: number = this.getRadius(override)
        const w: any = this.props.width
            ? this.props.width
            : globals.sizes.button.default.width
        const style: Record<string, any> = {
            width: w,
            padding: p,
            height: 18,
            backgroundColor: 'white',
            display: 'block',
            cursor: 'pointer',
            borderRadius: `${radius}px ${radius}px 0 0`,
        }
        return style
    }

    forceState(index?: number) {
        index = index ? index : 0
        this.setState({
            stateindex: index,
        })
    }

    getComponentProps() {}

    getInlineComponentProps() {
        const components: object[] = []
        const inlineChildren: any = this.props.children
        const arrayChildren: any[] = Children.toArray(inlineChildren)

        arrayChildren.forEach((item) => {
            components.push({
                value: item.props.value,
                onClick: item.props.onClick,
            })
        })

        return components
    }

    getComponentFromStringArray() {
        const components: {}[] = []
        this.props.appendStringData?.forEach((title: string) => {
            components.push({ value: title })
        })
        return components
    }

    getComponentFromMenuItemData() {
        if (this.props.appendMenuItemData) return this.props.appendMenuItemData
        return []
    }

    getAppendedComponentProps() {
        if (!this.props.appendComponent) return []
        const components: {}[] = []
        let component: any = this.props.appendComponent.component
        if (component.props.value.length > 2)
            components.push({
                value: component.props.value,
            })
        return components
    }

    getAppendedMultiComponentProps() {
        if (!this.props.appendComponents) return []
        const components: any[] = []
        let arrayChildren: any[] = [],
            component: any
        this.props.appendComponents.map((item) => {
            component = item.component
            return arrayChildren.push(component)
        })
        let buffer: string

        arrayChildren.forEach((item) => {
            buffer = item.props.value as string
            if (component.props.value)
                components.push({
                    value: buffer,
                })
        })

        return components
    }

    getComponentData() {
        const propsInline = this.getInlineComponentProps()
        const propsMulti = this.getAppendedMultiComponentProps()
        const propsSingle = this.getAppendedComponentProps()
        const propsData = this.getComponentFromMenuItemData()
        const propsStrings = this.getComponentFromStringArray()
        const props: any[] = [
            ...propsInline,
            ...propsMulti,
            ...propsSingle,
            ...propsData,
            ...propsStrings,
        ]
        const definedProps: any[] = []

        props.forEach((item) => {
            if (item.value !== undefined) definedProps.push(item)
        })

        return definedProps
    }

    compileComponents(props: any[]) {
        /* 
        Solves the problem of having a dynamic number of menu items. 
        When putting functional-components in an array, you can append them 
        directly to the page, however in 'variable form' you can no longer
        add props like onClick etc..

        This approach works by extracting the properties of the menu-item 
        components (i.e. value) and recreating the component with any 
        additional props necessary. */

        const current: string = this.state.selected
        let components: any[] = []
        let id: string = Math.random().toString(36).substr(2, 9)

        props.forEach((item) => {
            if (current !== item.value)
                components.push(
                    <MenuItem
                        width={this.props.width}
                        value={item.value}
                        stateindex={this.state.stateindex}
                        onClick={() => {
                            this.setState({
                                selected: item.value,
                                animate: false,
                                stateindex: 0,
                            })
                            if (item.onClick) item.onClick()
                            if (this.props.setSelection)
                                this.props.setSelection(item.value)
                        }}
                        forceState={(i) => {
                            this.setState({ stateindex: i })
                        }}
                        height={this.props.height}
                        fontMarginTop={this.props.fontMarginTop}
                        parentId={id}
                        key={this.keys++}
                    ></MenuItem>
                )
        })

        return components
    }

    appendComponentHeader(override: Record<string, any>) {
        const h: number = this.props.height ? this.props.height : 30
        const fontMarginTop: number = this.props.fontMarginTop
            ? this.props.fontMarginTop
            : 15
        const w: any = this.props.width
            ? this.props.width
            : globals.sizes.button.default.width
        return (
            <div
                onClick={() => {
                    let length: number = 0
                    const unique: { [type: string]: any } = {}
                    const current: string = this.state.selected
                    unique[current] = true
                    let exists: boolean, value: string

                    this.data.forEach((item) => {
                        value = item.value
                        exists = unique[value] === true
                        if (!exists) length++
                    })

                    this.setState({
                        animate: true,
                        stateindex: manageState(this.state.stateindex, 2),
                        length: length,
                    })
                }}
                style={{
                    ...this.getSelectionBoxStyle(override),
                    paddingTop: 0,
                    height: h - 5,
                    overflow: 'hidden',
                    width: w,
                }}
            >
                <div
                    id="DropdownMenu"
                    {...css({
                        ...getSharedStyleText(fontMarginTop),
                        //backgroundColor: 'black',
                        width: w,
                    })}
                >
                    <div
                        style={{
                            verticalAlign: 'top',
                            marginTop: -2,
                            display: 'inline-block',
                        }}
                    >
                        <div
                            style={{
                                verticalAlign: 'top',
                                display: 'inline-block',
                                paddingLeft: 20,
                            }}
                        >
                            {this.state.selected.substr(0, 18).concat('...')}
                        </div>
                    </div>
                    <div
                        style={{
                            verticalAlign: 'top',
                            float: 'right',
                            display: 'inline-block',
                            marginRight: 10,
                            marginTop: -5,
                        }}
                    >
                        <Chevron stateindex={this.state.stateindex}></Chevron>
                    </div>
                </div>
            </div>
        )
    }

    appendComponentDropdown(paramsPassed: any) {
        const paramsUpdated: any = {
            ...paramsPassed,
            stateindex: this.state.stateindex,
            styles: paramsPassed.styles,
        }

        return (
            <div
                style={{
                    ...this.getDropdownStyle(paramsUpdated),
                }}
            >
                {paramsUpdated.components}
            </div>
        )
    }

    getStateWithPatch(stateindex: number, n: number) {
        /*
        if only a <= 1 items is inserted into the menu
        the animation will not need to resize. If allowed
        to it will appear buggy, as no accompanying height
        adjustment occurs. 

        If one item is inserted the same problem occurs, after 
        the number of slots -=1. However, this blocking must be 
        'blocked' or the initial state will appear open. 
        */

        const defaultTitle: string =
            this.props.isInitial && this.data.length > 0
                ? this.data[0].value
                : this.props.value
                ? this.props.value
                : 'Menu'
        const changed: boolean = this.state.selected !== defaultTitle
        if (changed && n < 1) stateindex = 0
        return stateindex
    }

    componentDidMount() {
        if (this.props.setSelection)
            this.props.setSelection(this.state.selected)
    }
    render() {
        const subcomponents: any[] = this.compileComponents(this.data)
        const override: Record<string, any> = this.props.style
            ? this.props.style
            : {}
        const w: any = this.props.width
            ? this.props.width
            : globals.sizes.button.default.width
        const style: Record<string, any> = {
            width: w,
            position: 'relative',
            zIndex: 2,
        }
        const params: any = {
            ...this.props.params,
            components: subcomponents,
            styles: override,
            initial: this.props.isInitial,
            quantity: this.state.length,
        }

        return (
            <div {...css({ ...style, ...override })}>
                {/* COMPONENT HEADER */}
                {this.appendComponentHeader(override)}

                {/* COMPONENT DROPDOWN */}
                {this.appendComponentDropdown(params)}
            </div>
        )
    }
}

interface ItemProps {
    stateindex?: number
    value?: string
    height?: number
    width?: number
    onClick?: () => void
    forceState?: (i?: number) => void
    parentId?: string
    fontMarginTop?: number
}

export class MenuItem extends Component<ItemProps> {
    toBaseState() {
        if (this.props.forceState) this.props.forceState(0)
    }

    getItemContainerStyle() {
        const onHover: string = ':hover'
        const isOn: boolean = this.props.stateindex === 1
        const hover: Record<string, any> = {
            [onHover]: {
                backgroundColor: isOn ? 'whitesmoke' : 'white',
            },
        }
        const h: number = this.props.height
            ? this.props.height
            : getDefaultBoxHeight()
        const style: Record<string, any> = {
            height: h,
            width: '100%',
            textAlign: 'center',
        }
        return css({ ...style, ...hover })
    }

    toggleIf(isOpen: boolean) {
        if (isOpen && this.props.onClick) this.props.onClick()
    }

    render() {
        const h: number = this.props.height ? this.props.height : 30
        const w: any = this.props.width
            ? this.props.width
            : globals.sizes.button.default.width
        return (
            <div
                {...this.getItemContainerStyle()}
                onClick={() => {
                    if (this.props.forceState) this.props.forceState(1)
                    this.toggleIf(this.props.stateindex === 1)
                    if (this.props.onClick) this.props.onClick()
                }}
            >
                <div id="MenuItem" {...getSharedStyleText(h / 4)}>
                    <div
                        style={{
                            display: 'table-cell',
                            height: 45,
                            verticalAlign: 'middle',
                            textAlign: 'left',
                            paddingLeft: '25px',
                            fontSize: '14px',
                            width: w,
                        }}
                    >
                        {this.props.value}
                    </div>
                </div>
            </div>
        )
    }
}
