import axios from 'axios'
import { config } from '../models/config'
import { Cookies } from 'react-cookie'
import {
    IAppState,
    defaultIAppState,
    RegistrationRequest,
} from '../models/state'
import { toast } from 'react-toastify'
import { store } from '../store'
import { login, setAuthority } from '../actions'
import { ICustomProfile } from '../models/api'
import { history } from '../App'

export const register = (params: {
    request: RegistrationRequest
    onSuccess?: (response: string) => void
    onFail?: (response: string) => void
}) => {
    const postData = {
        firstName: params.request.firstName,
        lastName: params.request.lastName,
        email: params.request.email.toLowerCase(),
        zipCode: params.request.zipCode,
        login: params.request.email.toLowerCase(),
        password: params.request.password,
        isGoodStanding: true,
        langKey: 'en',
    }

    if (new Cookies().get('signup_code')) {
        postData['affiliate'] = new Cookies().get('signup_code')
    }

    axios
        .post(`${config.api}/api/register`, postData)
        .then((response: any) => {
            if (params.onSuccess) params.onSuccess(response)
        })
        .catch((error) => {
            if (params.onFail) params.onFail(error)

            toast.error('Registration Incorrect. Please try again.')
            store.dispatch(login(null, false, 'ERROR'))
        })
}

export const registerAcvivate = (params: {
    request: RegistrationRequest
    onSuccess?: (response: string) => void
    onFail?: (response: string) => void
}) => {
    axios
        .post(`${config.api}/api/register-activate`, {
            firstName: params.request.firstName,
            lastName: params.request.lastName,
            email: params.request.email,
            zipCode: params.request.zipCode,
            login: params.request.email,
            password: params.request.password,
            langKey: 'en',
        })

        .then((response: any) => {
            if (params.onSuccess) params.onSuccess(response)
        })
        .catch((error) => {
            if (params.onFail) params.onFail(error)

            toast.error('Registration Incorrect. Please try again.')
            store.dispatch(login(null, false, 'ERROR'))
        })
}

export const signIn = (
    username: string,
    password: string,
    overrideRedirect?: (response: any) => void
) => {
    axios
        .post(`${config.api}/api/authenticate`, {
            username: username,
            password: password,
            rememberMe: true,
        })
        .then((response: any) => {
            const token = response.data.id_token
            if (token) {
                axios
                    .get(`${config.api}/api/account`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response: any) => {
                        store.dispatch(setAuthority(response.data.authorities))
                        store.dispatch(login(token, true, 'COMPLETE'))
                        history.push('/dashboard', response.data.authorities)
                    })
            } else {
                toast.error('There was an error fetching your account')
            }
        })
        .catch((error) => {
            toast.error('Login Incorrect. Please try again.')
            store.dispatch(login(null, false, 'ERROR'))
        })
}

export const auth = (
    state: IAppState = defaultIAppState,
    action: any
): IAppState => {
    switch (action.type) {
        case 'SIGNIN':
            const actionProps = action as {
                type: string
                token: string | null
                isLogged: boolean
                authStatus: string
            }

            return {
                ...state,
                isLogged: actionProps.isLogged,
                token: actionProps.token,
                authStatus: actionProps.authStatus,
            } as IAppState
        case 'SIGNOUT':
            new Cookies().remove('token')
            localStorage.clear()
            return { isLogged: false, token: null } as IAppState

        case 'SET':
            new Cookies().set('profile', JSON.stringify(action.profile))
            return { ...state, activeProfile: action.profile as ICustomProfile }

        case 'LOAD':
            return action.state

        case 'SETAUTH':
            new Cookies().set('auth', action.authority)
            return { ...state, authorities: action.authority }
        default:
            return state
    }
}

export const authState = (
    state: IAppState = defaultIAppState,
    action: { type: string }
): IAppState => {
    switch (action.type) {
        case 'LOADING':
            return { ...state, authStatus: 'LOADING' }
        case 'ERROR':
            return { ...state, authStatus: 'ERROR' }
        case 'COMPLETE':
            return { ...state, authStatus: 'COMPLETE' }
        default:
            return { ...state, authStatus: 'UNBEGUN' }
    }
}
