import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import { Row, Col } from 'react-flexbox-grid'
import { store } from '../store'
import axios from 'axios'
import { config } from '../models/config'
import ScaleLoader from 'react-spinners/ScaleLoader'
import Button from '../insightui/Button'
import { isMobile, isTablet } from 'react-device-detect'
import PortfolioCard from '../components/Portfolios/PortfolioCard'

import { ChevronDoubleUp } from '@styled-icons/bootstrap/ChevronDoubleUp'
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort'
import { ChevronDoubleDown } from '@styled-icons/bootstrap/ChevronDoubleDown'
import { ArrowTrending } from '@styled-icons/fluentui-system-regular/ArrowTrending'
import { RankedComponent } from '../components/RankedComponent'
import product from '../images/insight-product.jpeg'
import { setAuthority } from '../actions'
import { determinePlanTitle } from '../utils/globals'
import Footer from '../insightui/Footer'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

function CTA() {
    const history = useHistory()
    return (
        <Row
            style={{
                padding: isMobile ? '20px' : '0',
                marginBottom: 30,
                backgroundColor: 'white',
                boxShadow: '1px 1px 6px -5px rgba(0, 0, 0, 0.41)',
            }}
        >
            <Col
                lg={2}
                style={{
                    margin: 0,
                    padding: 0,
                    display: isMobile ? 'none' : 'block',
                }}
            >
                <img
                    alt="insight"
                    src={product}
                    style={{
                        height: 180,
                        width: 180,
                        margin: isMobile ? '0px auto' : 0,
                        display: 'block',
                    }}
                />
            </Col>
            <Col xl={7}>
                <Row style={{ padding: '20px 0 0 20px' }}>
                    <h1
                        style={{
                            fontSize: 25,
                            marginBottom: 0,
                        }}
                    >
                        Get Advanced Professional Features with
                        <span style={{ color: '#DB7B27' }}>
                            {' '}
                            Insight Premium
                        </span>
                    </h1>
                    <p
                        style={{
                            lineHeight: '25px',
                            marginBottom: 0,
                        }}
                    >
                        Inspire Insight Premium makes biblically responsible
                        investing easy. <br />
                        Instantly screen stocks, mutual funds, and ETFs for
                        biblical values, with{' '}
                        <strong>powerful advanced Premium features.</strong>
                    </p>
                </Row>
            </Col>
            <Col
                xl={3}
                style={{
                    padding: isMobile ? 25 : '60px 30px 20px',
                    textAlign: isMobile ? 'center' : 'right',
                }}
            >
                <Button
                    type="primary"
                    style={{
                        backgroundColor: '#DB7B27',
                        borderColor: '#DB7B27',
                        width: 190,
                        height: 60,
                        fontSize: 20,
                        fontWeight: 700,
                        display: 'inline-block',
                    }}
                    onClick={() => {
                        history.push('/checkout')
                    }}
                >
                    Upgrade <ArrowRightShort width={25} />
                </Button>
            </Col>
        </Row>
    )
}

export default function Dashboard({ view }) {
    const [userProfile, setUserProfile] = useState(null)
    const [ready, setReady] = useState(false)
    const [ranked, setRanked] = useState(null)
    const [portfolios, setPortfolios] = useState([])
    const [trending, setTrending] = useState(null)
    const [isPro, setIsPro] = useState(false)
    const history = useHistory()
    const [cookies, setCookie] = useCookies()

    useEffect(() => {
        if (!cookies.firstime) {
            setCookie('firstime', true)
            window.location.reload()
        }
        if (!ready) {
            getUserDetails()
            fetchAllPortfolios()
            getRankedTrending()
            getTrending()
        }
    })

    function fetchAllPortfolios() {
        axios
            .get(`${config.api}/api/portfolios`, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response) => {
                setPortfolios(response.data.portfolios)
            })
    }

    function getUserDetails() {
        if (store.getState().token) {
            axios
                .get(`${config.api}/api/account`, {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                })
                .then((response) => {
                    store.dispatch(setAuthority(response.data.authorities))
                    localStorage.setItem(
                        'prem',
                        Buffer.from(determinePlanTitle()).toString('base64')
                    )
                    if (
                        store.getState().authorities.includes('ROLE_PREMIUM') ||
                        store.getState().authorities.includes('ROLE_ADMIN')
                    ) {
                        setIsPro(true)
                    }
                    setUserProfile(response.data)
                })
        }
    }

    function getRankedTrending() {
        axios.get(`${config.api}/api/ranked`).then((response) => {
            setRanked(response.data)
            setReady(true)
        })
    }

    function getTrending() {
        axios.get(`${config.api}/api/trending`).then((response) => {
            setTrending(response.data)
        })
    }

    const eligibleForUpgrade = !store
        .getState()
        .authorities.includes('ROLE_ADVISOR')
    return (
        <>
            <Layout sider={false} />
            {ready && userProfile ? (
                <div
                    style={{
                        backgroundColor: '#f7f7f7',
                        padding: 30,
                    }}
                >
                    <Row
                        style={{
                            paddingTop: isMobile && !isTablet ? 60 : 40,
                            marginBottom: 20,
                        }}
                    >
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={1}
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            {userProfile.imageUrl ? (
                                <img
                                    src={userProfile.imageUrl}
                                    width={80}
                                    height={80}
                                    style={{ borderRadius: '50%' }}
                                />
                            ) : (
                                <div
                                    style={{
                                        width: 80,
                                        height: 47,
                                        borderRadius: '50%',
                                        textAlign: 'center',
                                        backgroundColor: '#2c8aae',
                                        paddingTop: 33,
                                        color: 'white',
                                        fontSize: 40,
                                        lineHeight: 0.5,
                                        fontWeight: 600,
                                        display: 'inline-block',
                                    }}
                                >
                                    {userProfile.firstName[0].toUpperCase()}
                                </div>
                            )}
                        </Col>
                        <Col
                            lg={3}
                            xs={12}
                            style={{
                                textAlign: isMobile
                                    ? 'center'
                                    : isMobile && isTablet
                                      ? 'center'
                                      : 'left',
                            }}
                        >
                            <h1 style={{ fontSize: 45, lineHeight: 0 }}>
                                Hi, {userProfile.firstName.slice(0, 100)}!
                            </h1>
                            <p>Welcome to your dashboard.</p>
                        </Col>
                        <Col
                            lg={8}
                            sm={12}
                            xs={12}
                            style={{
                                paddingLeft: 10,
                                paddingTop: 30,
                                marginBottom: isMobile ? 30 : 0,
                                textAlign: isMobile ? 'center' : 'right',
                                height: 90,
                            }}
                        >
                            <span
                                style={{
                                    padding: 14,
                                    borderTop: '1px solid black',
                                    borderBottom: '1px solid black',
                                    borderLeft: '1px solid black',
                                    borderBottomLeftRadius: 5,
                                    borderTopLeftRadius: 5,
                                    ...{
                                        //the user is already at the maximum plan
                                        borderRight: eligibleForUpgrade
                                            ? 'none'
                                            : '1px solid black',
                                        borderBottomRightRadius:
                                            eligibleForUpgrade ? 0 : 5,
                                        borderTopRightRadius: eligibleForUpgrade
                                            ? 0
                                            : 5,
                                    },
                                }}
                            >
                                Current Plan:{' '}
                                <strong>{determinePlanTitle()}</strong>
                            </span>
                            {eligibleForUpgrade && (
                                <span
                                    style={{
                                        padding: 14,
                                        borderTop: '1px solid black',
                                        borderBottom: '1px solid black',
                                        borderRight: '1px solid black',
                                        fontWeight: 700,
                                        backgroundColor: '#0B192C',
                                        color: 'white',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5,
                                        cursor: 'pointer',
                                        userSelect: 'none',
                                    }}
                                    onClick={() => {
                                        history.push('/checkout')
                                    }}
                                >
                                    Upgrade
                                </span>
                            )}
                        </Col>
                    </Row>
                    {!isPro && <CTA />}
                    <div style={{}}>
                        <Row>
                            <Col xs={12} md={6} lg>
                                <RankedComponent
                                    title={<span>Inspire Funds</span>}
                                    icon={<ChevronDoubleUp width={30} />}
                                    items={ranked['inspireFunds']}
                                    disableAll
                                />
                            </Col>
                            <Col xs={12} md={6} lg>
                                <RankedComponent
                                    title={
                                        <span>
                                            Trending Issue: Abortion Travel
                                        </span>
                                    }
                                    icon={<ChevronDoubleUp width={30} />}
                                    items={ranked['trendingIssue']}
                                    disableAll
                                />
                            </Col>
                            {/* <Col xs={12} md={6} lg>
                                {trending ? (
                                    <RankedComponent
                                        title="Trending Tickers"
                                        icon={<ArrowTrending width={30} />}
                                        items={trending}
                                        disableAll
                                    />
                                ) : (
                                    <></>
                                )}
                            </Col> */}
                        </Row>
                        <Row style={{ paddingTop: 50 }}>
                            <Col xs={12} md={6} lg>
                                <RankedComponent
                                    title={<span>Top Scoring Stocks</span>}
                                    icon={<ChevronDoubleDown width={30} />}
                                    items={ranked['topStocks']}
                                    disableAll
                                />
                            </Col>
                            <Col xs={12} md={6} lg>
                                <RankedComponent
                                    title={<span>Worst Scoring Stocks</span>}
                                    icon={<ChevronDoubleDown width={30} />}
                                    items={ranked['worstStocks']}
                                    disableAll
                                />
                            </Col>
                            <Col xs={12} md={6} lg></Col>
                        </Row>
                    </div>
                    <Row style={{ marginTop: 50 }}>
                        <Col>
                            <h1>Portfolios</h1>
                        </Col>
                        <Col xs />
                        <Col style={{ paddingRight: 40, paddingTop: 20 }}>
                            <Button
                                type="outlined"
                                style={{
                                    width: 'auto',
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}
                                onClick={() => history.push('/portfolios')}
                            >
                                View Portfolios <ArrowRightShort width={20} />
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 15 }}>
                        {portfolios.length ? (
                            <>
                                {portfolios.slice(0, 4).map((portfolio) => (
                                    <Col>
                                        <PortfolioCard portfolio={portfolio} />
                                    </Col>
                                ))}
                            </>
                        ) : (
                            <p></p>
                        )}
                    </Row>
                    <Footer marginTop={80} />
                </div>
            ) : (
                <div style={{ paddingLeft: '47%', paddingTop: '20%' }}>
                    {' '}
                    <ScaleLoader color={'black'} loading={true} />
                </div>
            )}
        </>
    )
}
