import React from 'react'
import { globals } from '../utils/globals'
import ImpactArc from '../insightui/ImpactArc'
import NumberRoll from '../insightui/NumberRoll'
import ToolTip from './Tooltip'
import { CompareArrows } from '@styled-icons/material-outlined/CompareArrows'
import Tooltip from '../images/info-circle-light.svg'
import { isMobile, isTablet } from 'react-device-detect'

type ScoreType = 'default' | 'large' | 'small'

interface ImpactScoreProps {
    score: any
    speed?: number
    withAnimation?: boolean
    boxSize?: number
    fontSize?: string
    key?: number
    arcSize?: number
    type?: ScoreType
    match?: boolean
    style?: Record<string, any>
    footer?: boolean // hide text underneath
    bgColor?: string
}

enum ArcSize {
    Small = 25,
    Large = 35,
}

enum CounterSize {
    Small = 22,
    Large = 46,
}

enum TextSize {
    Small = 0,
    Large = 10,
}

const styles: Record<string, any> = {
    text: {
        fontSize: 12,
        color: globals.colors.inspire.orange,
        fontWeight: 700,
        letterSpacing: '0.05',
        marginTop: -5,
        marginLeft: -2,
    },
}

export default class ImpactScore extends React.Component<ImpactScoreProps> {
    getImpactScoreStyle() {
        let sizeBig = 128
        let leftMargBig = 10
        let type = this.props.type
        if (!type) type = 'default'
        if (type === 'large') return {}
        if (type === 'small')
            return {
                width: 65,
                height: 35,
            }
        return {
            backgroundColor: 'rgba(0,255,0,0.1)',
        }
    }

    getArcSize() {
        let type = this.props.type
        if (!type) type = 'default'
        if (type === 'large') return ArcSize.Large
        if (type === 'small') return ArcSize.Small
        return this.props.arcSize
    }

    getNumberRollFontSize(unit?: string) {
        if (!unit) unit = 'px'
        let type = this.props.type
        if (type === 'large') return CounterSize.Large + unit
        if (type === 'small') return CounterSize.Small + unit
        return this.props.fontSize
    }

    getNumberRollStyles(unit?: string) {
        if (!unit) unit = 'px'
        let type = this.props.type

        if (type === 'large')
            return {
                fontSize: CounterSize.Large + unit,
                color: globals.colors.inspire.orange,
                fontWeight: 800,
                marginTop: -5,
                marginLeft: 7,
                marginBottom: -5,
                display: 'inline-block',
                letterSpacing: '-3px',
            }
        if (type === 'small')
            return {
                fontSize: CounterSize.Small + unit,
                color: globals.colors.inspire.orange,
                marginLeft: 3,
                letterSpacing: -1,
                fontWeight: 700,
                top: 2,
            }
        return {
            fontSize: this.props.fontSize,
        }
    }

    getTextStyles(unit?: string) {
        if (!unit) unit = 'px'
        let type = this.props.type
        if (type === 'small' || !this.props.footer)
            return {
                display: 'none',
                fontSize: TextSize.Small + unit,
            }
        if (type === 'large')
            return {
                fontSize: '15px',
                margin: '0',
                ...globals.styles.presets.noselect,
            }
        return {}
    }

    render() {
        const animate = this.props.withAnimation !== false ? true : false
        const match = this.props.match
        const bg: string = this.props.bgColor
            ? this.props.bgColor
            : 'transparent'
        //console.log('this.props.score: ' + this.props.score)
        return (
            <div
                style={
                    this.props.type === 'large'
                        ? {
                              background: bg,
                              paddingTop: '13px',
                              paddingRight: '0',
                              textAlign: 'center',
                              float: 'left',
                              marginRight: '20px',
                              marginBottom: '20px',
                              width: '145px',
                          }
                        : {}
                }
            >
                <div
                    style={{
                        ...this.getImpactScoreStyle(),
                        ...this.props.style,
                    }}
                >
                    <ImpactArc
                        withAnimation={animate}
                        score={this.props.score}
                        key={this.props.key + 1}
                        size={this.getArcSize()}
                    />
                    {this.props.score === null ? (
                        <span
                            style={
                                this.props.type === 'large'
                                    ? {
                                          color: globals.colors.inspire.orange,
                                          marginLeft: 5,
                                          fontSize: 28,
                                          fontWeight: 700,
                                      }
                                    : {
                                          color: 'lightgrey',
                                          marginLeft: 3,
                                          fontWeight: 700,
                                          fontSize: 16,
                                          letterSpacing: -1,
                                      }
                            }
                        >
                            NA
                        </span>
                    ) : (
                        <span
                            className="extended-font-style"
                            style={this.getNumberRollStyles()}
                        >
                            {this.props.score}
                        </span>
                        // <NumberRoll
                        //     match={match}
                        //     withAnimation={animate}
                        //     fontSize={this.getNumberRollFontSize()}
                        //     type={this.props.type}
                        //     final={this.props.score}
                        //     style={this.getNumberRollStyles()}
                        // />
                    )}
                    <h5
                        style={{
                            ...styles.text,
                            ...this.getTextStyles(),
                            ...globals.styles.presets.noselect,
                            verticalAlign: 'top',
                            display: 'block',
                            marginTop: '2',
                        }}
                    >
                        inspire impact
                        {this.props.type === 'large' && this.props.footer ? (
                            <div
                                style={{
                                    display: 'inline-block',
                                    marginLeft: 2,
                                    verticalAlign: 'top',
                                }}
                            >
                                <img
                                    src={Tooltip}
                                    width={14}
                                    height={14}
                                    alt=""
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </h5>
                </div>
            </div>
        )
    }
}
