import React, { ReactNode } from 'react'
import Header from '../insightui/Header'
import { Row, Col } from 'react-flexbox-grid'
import { css, nthChild } from 'glamor'
import { globals } from '../utils/globals'
import Footer from '../insightui/Footer'
import { isMobile, isTablet } from 'react-device-detect'

enum Views {
    DESKTOP,
    MOBILE,
}
const DEBUG: boolean = false

const SHARED: any = {
    title: {
        ...globals.typeface.spacedUppercaseThin,
        fontSize: 19,
    },
    subtitle: {
        ...globals.typeface.wideButtonText,
        fontSize: 20,
    },
    text: {
        ...globals.typeface.defaultText,
    },
}

function renderIf(render: boolean, value: ReactNode) {
    if (render) return value
    return <></>
}

function renderRow(title: string, value: string, isMobile?: boolean) {
    const definitionContainer = css(
        nthChild('odd', {
            backgroundColor: '#dfe8ec',
        })
    )

    return (
        <div {...definitionContainer}>
            <div
                style={{
                    height: 'auto',
                    top: 0,
                    padding: 20,
                    paddingBottom: 5,
                    paddingTop: 5,
                }}
            >
                <Row>
                    <Col xs={12} sm={4}>
                        <p style={SHARED.subtitle}>{title}</p>
                    </Col>
                    <Col xs={12} sm={8}>
                        <p style={SHARED.text}>{value}</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export class StickySection extends React.Component<{
    title: string
}> {
    state = {
        view: isMobile ? Views.MOBILE : Views.DESKTOP,
    }

    render() {
        return (
            <>
                <div
                    {...css({
                        width: '100%',
                    })}
                >
                    <Header logo subscriberExempt />
                </div>
                <div
                    style={{
                        backgroundColor: DEBUG ? 'lime' : 'transparent',
                        height: 'auto',
                        marginLeft: isMobile ? 0 : 45,
                        paddingBottom: 100,
                        paddingTop: 20,
                        top: 0,
                    }}
                >
                    <Row>
                        <Col xs={11}>
                            <div
                                style={{
                                    height: 'auto',
                                }}
                            >
                                {this.props.children}
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default class ScreeningCategories extends React.Component {
    render() {
        const isMobile: boolean = true
        return (
            <>
                <div
                    style={{
                        height: 80,
                        width: '100%',
                        position: 'sticky',
                        overflowX: isMobile ? 'hidden' : 'initial',
                        backgroundColor: DEBUG ? 'blue' : 'transparent',
                    }}
                ></div>

                <Row
                    style={{
                        margin: isMobile && !isTablet ? '60px 40px 0' : 40,
                    }}
                >
                    <Col>
                        <h1>Inspire Insight Screening Categories</h1>
                    </Col>
                </Row>
                <StickySection title="">
                    {renderRow(
                        'Abortifacients',
                        'Companies which produce or distribute abortifacient drugs (pharmaceuticals used to terminate a pregnancy anytime from the moment of conception onward, including those labeled as "contraceptives" but which may cause a fertilized egg to be destroyed).',
                        isMobile
                    )}
                    {renderRow(
                        'Abortion Activism',
                        'Companies that promote abortion access through legislative support, corporate philanthropic activity, and/or employee travel benefits.',
                        isMobile
                    )}
                    {renderRow(
                        'Abortion Services',
                        'Companies that own and operate one or more medical facilities that provides abortion procedures at any stage of pregnancy.',
                        isMobile
                    )}
                    {renderRow(
                        'Alcohol',
                        'Companies that produce at least one alcoholic beverage or exclusively distribute alcoholic beverages.',
                        isMobile
                    )}
                    {renderRow(
                        'Air Quality',
                        'Companies who responsibly address and manage the impact of air quality resulting from stationary (e.g., factories, power plants) and mobile sources (e.g., trucks, delivery vehicles, planes) as well as industrial emissions (does not include GHG emissions). Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Business Ethics',
                        'Companies who intentionally manage risks and opportunities surrounding ethical conduct of business, including fraud, corruption, bias, negligence, bribery, facilitation payments, fiduciary responsibilities, and other behavior that may have an ethical component. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Business Resilience',
                        'Companies who display a capacity to manage risks and opportunities associated with incorporating social, environmental, and political transitions into long-term business model planning despite operating in industries where evolving environmental and social realities challenge their current business approach. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Cannabis (Cultivation/Processing)',
                        'Companies that cultivate or process cannabis for retail or wholesale distribution.',
                        isMobile
                    )}
                    {renderRow(
                        'Cannabis (Retail THC)',
                        'Companies that produce or distribute retail cannabis products containing THC (which is the psychoactive component of cannabis).',
                        isMobile
                    )}
                    {renderRow(
                        'Critical Risk Management',
                        'Companies who display responsible use of management systems and scenario planning to identify, understand, and prevent or minimize the occurrence of low-probability, high-impact accidents, and emergencies with significant probable consequences, taking into consideration the potential human, environmental, and social implications, as well as the long-term ramifications for the company. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Customer Privacy',
                        'Companies who responsibly address risks related to the use of personally identifiable information (PII) and other user/customer data for secondary purposes including but not limited to marketing through affiliates and non-affiliates, data collecting procedures, managing user/customer expectations, consent processes, and compliance with evolving regulation (does not include cybersecurity risks). Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Customer Welfare',
                        'Companies who responsibly address customer welfare concerns over issues including, but not limited to, health and nutrition of foods and beverages, antibiotic use in animal production, and management of controlled substances. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Data Security',
                        'Companies who responsibly address management of risks related to collection, retention, and use of sensitive, confidential, and/or proprietary customer or user data, as well as strategic policies for incidents such as data breaches. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Embryonic Stem Cells',
                        'Companies that perform research on or produce products using embryonic stem cells, companies that provide embryonic stem cells to other entities, and companies that utilize propagated stem cell lines which originally derived from embryonic stem cells.',
                        isMobile
                    )}
                    {renderRow(
                        'Employee Wellbeing',
                        'Companies who responsibly address their ability to create and maintain a safe and healthy workplace environment that is free of injuries, fatalities, and illness (both chronic and acute) through the implementation of safety management plans, training requirements, regular audits of internal practices, and systematized monitoring and testing. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Energy Management',
                        'Companies that conscientiously manage the environmental impacts linked to their energy consumption used in their business operations. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Environmental Risk Mitigation',
                        'Companies who display the ability to manage risks and opportunities associated with direct exposure of their owned or controlled assets and operations as they pertain to the potential or actual physical impacts of environmental factors, including factors such as the increased frequency and severity of extreme weather, shifting climate, sea level change, and other expected physical impacts. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Ethical Labor Practices',
                        'Companies that responsibly ensure adherence to widely accepted labor standards within the workplace. This encompasses compliance with labor laws and internationally recognized norms and standards, including fundamental human rights and the prohibition of child, forced, or bonded labor, as well as exploitative labor practices. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Ethical Sales Practices',
                        'Companies that responsibly handle social issues arising from inadequately managing the transparency, accuracy, and comprehensibility of marketing statements, advertising, and product/service labeling. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Ethical Supply Chain Management',
                        'Companies that responsibly address the management of risks within their supply chain and handle issues associated with environmental and social externalities created by suppliers through their operational activities. Such issues include, but are not limited to, environmental responsibility, human rights, labor practices, ethics, and corruption. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Exploitation',
                        'Companies that contribute towards the unlawful and immoral practices of exploiting individuals for labor or sexual purposes, according to the National Center on Sexual Exploitation (NCOSE).',
                        isMobile
                    )}

                    {renderRow(
                        'Fair Competition',
                        'Companies who conscientiously manage issues associated with the existence of monopolies, which may include, but are not limited to, excessive prices, poor quality of service, and inefficiencies. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Gambling',
                        'Companies that generate revenue from gambling facilities, products, and/or services (not including third-party stores which offer Lottery services).',
                        isMobile
                    )}
                    {renderRow(
                        'GHG Emissions',
                        'Companies who responsibly address direct (Scope 1) greenhouse gas (GHG) emissions they may generate through their operations, which includes GHG emissions from stationary (e.g., factories, power plants) or mobile sources (e.g., trucks, delivery vehicles, planes). The seven GHGs covered under the Kyoto Protocol are included within the category: carbon dioxide (CO2), methane (CH4), nitrous oxide (N2O), hydrofluorocarbons (HFCs), perfluorocarbons (PFCs), sulfur hexafluoride (SF6), and nitrogen trifluoride (NF3). Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Hiring Ethics',
                        'Companies who responsibly address their ability to ensure culture, hiring, and promotion practices do not discriminate based on race, gender, ethnicity, religion, and other factors. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Human Rights',
                        'Companies who responsibly manage the relationship between their business and the communities in which they operate, including, but not limited to, management of direct and indirect impacts on core human rights, the treatment of indigenous peoples, and the impact of local businesses. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'In Vitro Fertilization',
                        'Companies that offer in vitro fertilization services or manufacture equipment specifically for the purpose of in vitro fertilization procedures.',
                        isMobile
                    )}
                    {renderRow(
                        'LGBT Activism',
                        'Companies earning an above-average rating according to an annual self-reported survey conducted by a national LGBT advocacy organization, which rates companies based on their corporate LGBT activism across several areas, including philanthropy, corporate policy, marketing efforts, and legislative support. The average score is calculated from the scores of the Fortune 500 companies that participated in the annual survey.',
                        isMobile
                    )}
                    {renderRow(
                        'Low Ecological Impact',
                        'Companies demonstrating conscientious knowledge and management of their impact on ecosystems and biodiversity through activities including, but not limited to, land use for exploration, natural resource extraction, and cultivation, as well as project development, construction, and siting. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Materials Efficiency',
                        'Companies who responsibly address issues related to the resilience of materials supply chains to impacts of climate change and other external environmental and social factors, including, but not limited to, product design, manufacturing, end-of-life management, reduction of key material usage, maximizing planning efficiency, and R&D material diversity. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Pornography',
                        'Companies that produce or distribute pornography. This category includes all media types, such as film, print, and online. Also included are companies that produce AO (Adult Only) rated video games that contain pornographic content.',
                        isMobile
                    )}
                    {renderRow(
                        'Product Access & Affordability',
                        'Companies who conscientiously address their ability to ensure broad access to their products and services, specifically in the context of underserved markets and/or population groups, including the accessibility and affordability of health care, financial services, utilities, education, and telecommunications. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Product Safety',
                        'Companies who responsibly address issues involving unintended characteristics of products sold or services provided that may create health or safety risks to end-users, meet customer expectations, manage liability concerns, product testing, and intentionally acknowledge recalls or market withdraws. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Product Sustainability',
                        'Companies who conscientiously acknowledge the characteristics of products and services provided or sold and address customer and societal demand for more sustainable products and services as well as meet evolving environmental and social regulations. Category data provided by FactSet.',
                        isMobile
                    )}

                    {renderRow(
                        'Regulatory Adherence',
                        'Companies who responsibly engage with regulators in cases where conflicting corporate and public interests may have the potential for long-term adverse direct or indirect environmental and social impacts and display their level of reliance on regulatory policy or monetary incentives while acknowledging the necessity of regulatory compliance within a competitive business environment. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'State Owned Enterprise',
                        'Companies who are owned and/or controlled by a Nation State/government of a country excluded from investment due to significant human rights violations of the following nature (as provided by U.S. Department of State): freedom of religion, sexual exploitation of children, trafficking in persons (Tier 3 only), and/or predominantly governed by Sharia Law. This category includes situations where the State has veto power, or a "golden share" is owned by the State or State-controlled agency. The current countries excluded from investment due to significant human rights violations are China, Saudi Arabia, United Arab Emirates, Qatar, Kuwait, Russia, Iran, Pakistan, Malaysia, and Vietnam.',
                        isMobile
                    )}
                    {renderRow(
                        'Systemic Risk Mitigation',
                        `The category addresses management of risks within a company's supply chain. It addresses issues associated with environmental and social externalities created by suppliers through their operational activities. Such issues include, but are not limited to, environmental responsibility, human rights, labor practices, and ethics and corruption. Management may involve screening, selection, monitoring, and engagement with suppliers on their environmental and social impacts. The category does not address the impacts of external factors, such as climate change and other environmental and social factors, on suppliers, operations and/or on the availability and pricing of key resources, which is covered in a separate category.`,
                        isMobile
                    )}
                    {renderRow(
                        'Tobacco',
                        'Companies that derive revenue from producing or exclusively distributing tobacco products.',
                        isMobile
                    )}
                    {renderRow(
                        'Waste & Hazmat Management',
                        'Companies who responsibly address environmental issues associated with the hazardous and non-hazardous waste they generate and the treatment, handling, storage, disposal, and regulatory compliance. Category data provided by FactSet.',
                        isMobile
                    )}
                    {renderRow(
                        'Water Conservation',
                        'Companies who conscientiously manage their water use, water consumption, wastewater generation, water recycling, water treatment, and any other operations pertaining to water resources, which may be influenced by regional differences in the availability and quality of and competition for water resources. Category data provided by FactSet.',
                        isMobile
                    )}
                </StickySection>

                <div
                    {...css({
                        width: '100%',
                        left: 0,
                        float: 'left',
                        overflow: 'hidden',
                        height: 470,
                    })}
                >
                    <Footer />
                </div>
            </>
        )
    }
}
