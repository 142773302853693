import React, { useState } from 'react'
import Modal from '../Modal'
import { Row, Col } from 'react-flexbox-grid'
import Button from '../../insightui/Button'
import DropdownMenu from '../../insightui/DropdownMenu'
import axios from 'axios'
import { config } from '../../models/config'
import { store } from '../../store'
import TagSelect from '../../insightui/TagSelect'

interface CreatePortfolioProps {
    activeModal: boolean
    onChange: (newState: boolean) => void
    onComplete: () => void
}

export default function CreatePortfolio(props: CreatePortfolioProps) {
    const [newPortfolioTitle, setPortfolioTitle] = useState('')
    const [portfolioType, setPortfolioType] = useState('Individual')

    const { activeModal, onChange, onComplete } = props

    function createPortfolio() {
        axios
            .post(
                `${config.api}/api/portfolios`,
                {
                    name: newPortfolioTitle.slice(0, 100),
                    categoryTemplateDTO: 'FAITH_BASED_EVANGELICAL',
                    type: portfolioType,
                },
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response) => onComplete())
    }

    return (
        <Modal isActive={activeModal} top={'10%'}>
            <Row>
                <p
                    style={{
                        fontWeight: 500,
                        fontSize: 16,
                        letterSpacing: 2,
                        paddingLeft: 8,
                    }}
                >
                    CREATE PORTFOLIO
                </p>
            </Row>
            <Row>
                <p
                    style={{
                        fontWeight: 300,
                        fontSize: 13,
                        letterSpacing: 2,
                        paddingLeft: 8,
                    }}
                >
                    Portfolio Name
                </p>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <input
                    style={{
                        width: 300,
                        height: 48,
                        border: 0,
                        boxShadow: '0 2px 8px #dbdbdc',
                        paddingLeft: 20,
                        fontWeight: 500,
                        fontSize: 14,
                        color: 'black',
                    }}
                    value={newPortfolioTitle}
                    onChange={(e) => setPortfolioTitle(e.target.value)}
                />
            </Row>
            <Row style={{ paddingTop: 10 }}>
                <p
                    style={{
                        fontWeight: 300,
                        fontSize: 13,
                        letterSpacing: 2,
                        paddingLeft: 8,
                    }}
                >
                    Portfolio Type
                </p>
            </Row>
            <Row>
                <TagSelect
                    items={[
                        { title: 'Individual', value: 'Individual' },
                        { title: 'Joint', value: 'Joint' },
                        { title: 'IRA', value: 'IRA' },
                        { title: 'Roth IRA', value: 'Roth IRA' },
                        { title: 'Trust', value: 'Trust' },
                        { title: '401(k)', value: '401(k)' },
                        { title: '403(b)', value: '403(b)' },
                        { title: '529', value: '529' },
                        { title: 'HSA', value: 'HSA' },
                        { title: 'Other', value: 'Other' },
                    ]}
                    value={portfolioType}
                    onChange={(output) => setPortfolioType(output)}
                />
            </Row>
            <Row style={{ paddingTop: 20 }}>
                <Button
                    type="outlined"
                    style={{ width: 110 }}
                    onClick={() => {
                        onChange(false)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    style={{ width: 120, marginLeft: 30 }}
                    onClick={() => {
                        createPortfolio()
                        onChange(false)
                    }}
                >
                    Create
                </Button>
            </Row>
        </Modal>
    )
}
