import React from 'react'
import Header from '../insightui/Header'
import Footer from '../insightui/Footer'
import { isMobile, isTablet } from 'react-device-detect'

function terms() {
    return (
        <>
            <Header logo subscriberExempt />
            <div
                style={{
                    padding: 30,
                    paddingTop: isMobile && !isTablet ? 175 : 80,
                    lineHeight: 1.5,
                }}
            >
                <h2>INSPIRE INSIGHT END USER LICENSE AGREEMENT</h2>
                <div class="typography__body-1">
                    This End-User License Agreement (this “EULA”) is a legal
                    agreement between you (“Licensee”) and Inspire Insight, LLC
                    (“Licensor”), the author of Inspire Insight, including all
                    HTML files, XML files, Java files, graphics files, animation
                    files, data files, technology, development tools, scripts
                    and programs, both in object code and source code (the
                    “Software”), the deliverables provided pursuant to this
                    EULA, which may include associated media, printed materials,
                    and “online” or electronic documentation.
                </div>
                <div class="typography__body-1">
                    By installing, copying, or otherwise using the Software,
                    Licensee agrees to be bound by the terms and conditions set
                    forth in this EULA. If Licensee does not agree to the terms
                    and conditions set forth in this EULA, then Licensee may not
                    download, install, or use the Software.
                </div>
                <ol
                    class="terms-of-service__list-top typography__body-1"
                    type="1"
                >
                    <li>
                        <span class="terms-of-service__text--bold">
                            Grant of License
                        </span>
                        <ol type="A">
                            <li>
                                <span class="terms-of-service__text--bold">
                                    Scope of License.
                                </span>
                                 Subject to the terms of this EULA, Licensor
                                hereby grants to Licensee a royalty-free,
                                non-exclusive license to use a copy of the
                                Software.
                            </li>
                            <li>
                                <span class="terms-of-service__text--bold">
                                    Use and Access.
                                </span>
                                 Licensee may use and access the Software solely
                                for Licensee’s business and personal use. Use
                                and access shall be limited to the Licensee and
                                not shared with other parties. Sharing access
                                shall be deemed a violation of this agreement
                                and appropriate action will be taken by the
                                Licensor.
                            </li>
                            <li>
                                <span class="terms-of-service__text--bold">
                                    Type of License.
                                </span>{' '}
                                Varying license permissions, features and
                                related pricing are offered to accommodate
                                different use-cases and entity types of
                                individual Licensees. Licensor reserves the
                                right to charge different fees for use of the
                                Software based on the use-cases and entity types
                                of individual Licensees, and to revoke, cancel
                                or restrict access to some or all of the
                                features of the Software at Licensor’s sole
                                discretion based on the use-cases and entity
                                types of individual Licensees. Licensor
                                considers three different entity types and
                                related use-cases when determining which
                                features to enable and what fees to charge for
                                the Software:
                                <ol type="i">
                                    <li>
                                        <span class="terms-of-service__text--bold">
                                            Basic Use – Individual Investor.
                                        </span>{' '}
                                        Individual, non-professional investor
                                        who will be using only the “Basic”
                                        features available to the general public
                                        without cost at inspireinsight.com. No
                                        commercial use is allowed under this
                                        license.
                                    </li>
                                    <li>
                                        <span class="terms-of-service__text--bold">
                                            Professional Use – Individual
                                            Financial Professionals.
                                        </span>
                                        Individual financial professionals, such
                                        as financial advisors, or individual
                                        investors who desire access to
                                        Professional features. Commercial use is
                                        allowed under this license, but is
                                        limited to one individual per license
                                        and may not be shared with any other
                                        individual. Pricing and terms of
                                        Professional Use licenses shall be
                                        published at pro.inspireinsight.com.
                                    </li>
                                    <li>
                                        <span class="terms-of-service__text--bold">
                                            Institutional Use – Financial
                                            Institutions.
                                        </span>
                                        Institutions and their employees/agents,
                                        including but not limited to entities
                                        such as asset managers, mutual fund/ETF
                                        managers, index providers, registered
                                        investment advisors, broker-dealers,
                                        foundations, retirement plans, pensions,
                                        endowments and others. Commercial use is
                                        allowed under this license, as well as
                                        multiple users. Number of allowed users
                                        and fee for Institutional Use is
                                        determined on a case-by-case basis,
                                        taking into account the size and scope
                                        of the institutional Licensee’s business
                                        use-case. Pricing and terms for specific
                                        Institutional Use Licensees shall be
                                        included as an Exhibit to the EULA
                                        signed by institutional Licensees.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Description of Rights and Limitations
                        </span>
                        <ol type="A">
                            <li>
                                <span class="terms-of-service__text--bold">
                                    Limitations.
                                </span>
                                 Licensee and third parties may not reverse
                                engineer, decompile, or disassemble the
                                Software, except and only to the extent that
                                such activity is expressly permitted by
                                applicable law notwithstanding the limitation.
                            </li>
                            <li>
                                <span class="terms-of-service__text--bold">
                                    Update and Maintenance.
                                </span>
                                 Licensor shall provide updates and maintenance
                                on the Software on an as needed basis.
                            </li>
                            <li>
                                <span class="terms-of-service__text--bold">
                                    Separation of Components.
                                </span>
                                 The Software is licensed as a single product.
                                Its components may not be separated for use.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Title to Software.
                        </span>
                         Licensor represents and warrants that it has the legal
                        right to enter into and perform its obligations under
                        this EULA, and that use by the Licensee of the Software,
                        in accordance with the terms of this EULA, will not
                        infringe upon the intellectual property rights of any
                        third parties.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Intellectual Property.
                        </span>
                         All now known or hereafter known tangible and
                        intangible rights, title, interest, copyrights and moral
                        rights in and to the Software, including but not limited
                        to all images, photographs, animations, video, audio,
                        music, text, data, computer code, algorithms, and
                        information, are owned by Licensor. The Software is
                        protected by all applicable copyright laws and
                        international treaties.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            No Support.
                        </span>
                         Licensor has no obligation to provide support services
                        for the Software.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Duration.
                        </span>
                         This EULA is effective for the length of the Licensee’s
                        membership or until:
                        <ol type="A">
                            <li>
                                Automatically terminated or suspended if
                                Licensee fails to comply with any of the terms
                                and conditions set forth in this EULA; or
                            </li>
                            <li>
                                Terminated or suspended by Licensor, with or
                                without cause.
                            </li>
                        </ol>
                        In the event this EULA is terminated, you must cease use
                        of the Software.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Payment in Advance.
                        </span>
                         The Licensor may choose to offer a discounted
                        subscription price for annual memberships when paid in
                        advance. In this case, the Licensee has 30 days from
                        initial subscription to request a full refund and
                        cancelation of membership. If the membership is canceled
                        after the first 30 days, no refund will be granted.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Jurisdiction.
                        </span>
                         This EULA shall be deemed to have been made in, and
                        shall be construed pursuant to the laws of the State of
                        Idaho, without regard to conflicts of law provisions
                        thereof. Any legal action or proceeding relating to this
                        EULA shall be brought exclusively in courts located in
                        Meridian, ID, and each party consents to the
                        jurisdiction thereof. The prevailing party in any action
                        to enforce this EULA shall be entitled to recover costs
                        and expenses including, without limitation, attorneys’
                        fees. This EULA is made within the exclusive
                        jurisdiction of the United States, and its jurisdiction
                        shall supersede any other jurisdiction of either party’s
                        election.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Non-Transferable.
                        </span>
                         This EULA is not assignable or transferable by
                        Licensee, and any attempt to do so would be void.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Severability.
                        </span>
                         No failure to exercise, and no delay in exercising, on
                        the part of either party, any privilege, any power or
                        any rights hereunder will operate as a waiver thereof,
                        nor will any single or partial exercise of any right or
                        power hereunder preclude further exercise of any other
                        right hereunder. If any provision of this EULA shall be
                        adjudged by any court of competent jurisdiction to be
                        unenforceable or invalid, that provision shall be
                        limited or eliminated to the minimum extent necessary so
                        that this EULA shall otherwise remain in full force and
                        effect and enforceable.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Warranty Disclaimer.
                        </span>
                         Licensor, AND AUTHOR OF THE SOFTWARE, HEREBY EXPRESSLY
                        DISCLAIM ANY WARRANTY FOR THE SOFTWARE. THE SOFTWARE AND
                        ANY RELATED DOCUMENTATION IS PROVIDED “AS IS” WITHOUT
                        WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                        INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                        NON-INFRINGEMENT. LICENSEE ACCEPTS ANY AND ALL RISK
                        ARISING OUT OF USE OR PERFORMANCE OF THE SOFTWARE.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            LIMITATION OF LIABILITY.
                        </span>
                         LICENSOR SHALL NOT BE LIABLE TO LICENSEE, OR ANY OTHER
                        PERSON OR ENTITY CLAIMING THROUGH LICENSEE ANY LOSS OF
                        PROFITS, INCOME, SAVINGS, OR ANY OTHER CONSEQUENTIAL,
                        INCIDENTAL, SPECIAL, PUNITIVE, DIRECT OR INDIRECT
                        DAMAGE, WHETHER ARISING IN CONTRACT, TORT, WARRANTY, OR
                        OTHERWISE. THESE LIMITATIONS SHALL APPLY REGARDLESS OF
                        THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. UNDER NO
                        CIRCUMSTANCES SHALL LICENSOR’S AGGREGATE LIABILITY TO
                        LICENSEE, OR ANY OTHER PERSON OR ENTITY CLAIMING THROUGH
                        LICENSEE, EXCEED THE FINANCIAL AMOUNT ACTUALLY PAID BY
                        LICENSEE TO LICENSOR FOR THE SOFTWARE.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Entire Agreement.
                        </span>
                         This EULA constitutes the entire agreement between
                        Licensor and Licensee and supersedes all prior
                        understandings of Licensor and Licensee, including any
                        prior representation, statement, condition, or warranty
                        with respect to the subject matter of this EULA.
                    </li>
                    <li>
                        <span class="terms-of-service__text--bold">
                            Acknowledgement.
                        </span>{' '}
                        I hereby agree to comply with and be bound by the terms
                        of use stated herein, including any Exhibits and
                        Addendums. I acknowledge that I have received a copy of
                        Licensor’s Privacy Policy, which is publicly available
                        on Licensor’s website.
                    </li>
                    <li>
                        Certain datapoints including fundamentals and pricing
                        information is
                        <a href="https://iexcloud.io"> provided by IEX Cloud</a>
                    </li>
                </ol>
            </div>
            <Footer />
        </>
    )
}

export default terms
