import { ICustomProfile } from '../models/api'
import axios from 'axios'
import { config } from '../models/config'
import { store } from '../store'


export const fetchAllProfiles = async (onResponse?: any) => {
    await axios
        .get(`${config.api}/api/screening-profiles/get`, {
            headers: { Authorization: `Bearer ${store.getState().token}` },
        })
        .then((response) => {
            if (onResponse) {
                let customProfileData: Array<ICustomProfile> = response.data
                onResponse(customProfileData)
            }
            return response.data as Array<ICustomProfile>
        })
        .catch((err) => {
        })
}

export const updateProfile = (profile: ICustomProfile) => {
    axios
        .put(`${config.api}/api/screening-profiles/update`, profile, {
            headers: { Authorization: `Bearer ${store.getState().token}` },
        })
        .then((response) => {
            return 'success'
        })
        .catch((err) => {
            return err
        })
}

export const deleteProfile = (profile: ICustomProfile, cb: any) => {
    axios
        .delete(
            `${config.api}/api/screening-profiles/delete?profile_id=${profile.id}`,
            {
                headers: { Authorization: `Bearer ${store.getState().token}` },
            }
        )
        .then((response) => {
            cb();
        })
        .catch((err) => {
            return err
        })
}

export const createScreeningProfile = (profile: ICustomProfile) => {
    axios
        .post(`${config.api}/api/screening-profiles/create`, profile, {
            headers: { Authorization: `Bearer ${store.getState().token}` },
        })
        .then((response) => {
            return 'success'
        })
        .catch((err) => {
            return err
        })
}

/**
 *  ========== Developer Note =========
 * By: @carterjfulcher
 *
 * This isn't just lazy code, both isPositive and isNegative are required since there are three states
 * in any screening profile, [POSITIVE, NEGATIVE, OFF].
 *
 * This way we can check:
 *
 * isPositive()
 * isNegative()
 *
 * and !isPositive() && !isNegative()
 */
export const isPositive = (title: string): boolean => {
    const screeningProfile: ICustomProfile = store.getState().activeProfile
    return screeningProfile.positive.includes(title)
}

export const isNegative = (title: string): boolean => {
    const screeningProfile: ICustomProfile = store.getState().activeProfile
    return screeningProfile.negative.includes(title)
}
