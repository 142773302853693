import React from 'react'
import { Grid, Row } from 'react-flexbox-grid'

import logo from '../images/logo.svg'
import Button from './Button'

import { GridOn } from '@styled-icons/material/GridOn'
import { BarChartAlt2 } from '@styled-icons/boxicons-solid/BarChartAlt2'
import { Article } from '@styled-icons/material/Article'
import { PriceTag } from '@styled-icons/icomoon/PriceTag'
import { Filter } from '@styled-icons/boxicons-regular/Filter'
import { Question } from '@styled-icons/bootstrap/Question'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort'

const siderStyle: Record<string, unknown> = {
    height: '100%',
    position: 'fixed',
    backgroundColor: 'white',
    width: 250,
    borderRight: '0.5px solid #DFE7EC',
    paddingTop: 14,
    paddingLeft: 5,
    display: 'block',
}

export default class Sider extends React.Component {
    render() {
        return (
            <div style={siderStyle}>
                <Grid fluid>
                    <Row>
                        <img
                            src={logo}
                            style={{ height: 75, paddingLeft: 20 }}
                            alt="Inspire Insight"
                        />
                    </Row>
                    <Row style={{ paddingTop: 20 }}>
                        <Button
                            icon={
                                <GridOn
                                    width={22}
                                    style={{ marginRight: 15 }}
                                />
                            }
                            type="ghost"
                        >
                            Dashboard
                        </Button>
                    </Row>
                    <Row>
                        <Button
                            type="ghost"
                            icon={
                                <BarChartAlt2
                                    width={22}
                                    style={{ marginRight: 15 }}
                                />
                            }
                        >
                            Portfolios
                        </Button>
                    </Row>
                    <Row>
                        <Button
                            type="ghost"
                            icon={
                                <Article
                                    width={22}
                                    style={{
                                        marginRight: 15,
                                    }}
                                />
                            }
                        >
                            Articles
                        </Button>
                    </Row>
                    <Row>
                        <Button
                            type="ghost"
                            icon={
                                <PriceTag
                                    width={22}
                                    style={{ marginRight: 15 }}
                                />
                            }
                        >
                            Plans
                        </Button>
                    </Row>
                    <Row>
                        <Button
                            type="ghost"
                            icon={
                                <Filter
                                    width={22}
                                    style={{ marginRight: 15 }}
                                />
                            }
                        >
                            Screening
                        </Button>
                    </Row>
                    <Row>
                        <Button
                            type="ghost"
                            icon={
                                <Question
                                    width={22}
                                    style={{ marginRight: 15 }}
                                />
                            }
                        >
                            FAQ
                        </Button>
                    </Row>
                    <Row>
                        <div
                            style={{
                                backgroundColor: '#EEF7FA',
                                padding: 15,
                                margin: 17,
                            }}
                        >
                            <h4>
                                <strong>
                                    Get Advanced Professional Features
                                </strong>
                            </h4>
                            <p style={{ fontWeight: 400, fontSize: 12 }}>
                                Inspire Insight Premium makes biblically
                                responsible investing easy. Instantly screen
                                stocks, mutual funds, and ETFs for biblical
                                values, with powerful advanced Premium features.
                            </p>
                            <Button
                                style={{ marginTop: 5 }}
                                type="primary"
                                icon={<ArrowRightShort width={20} />}
                                iconPosition="right"
                            >
                                Insight Premium
                            </Button>
                        </div>
                    </Row>
                </Grid>
            </div>
        )
    }
}
