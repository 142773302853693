import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import axios from 'axios'
import { config } from '../models/config'
import { toast } from 'react-toastify'

interface PathParams {
    key: string
}

type PropsType = RouteComponentProps<PathParams> & {}

class Activation extends React.Component<PropsType> {
    componentDidMount() {
        const key = this.props.match.params.key
        axios
            .get(`${config.api}/api/activate?key=${key}`)
            .then(() => {
                this.props.history.push('/login')
            })
            .catch((err) => {
                toast.error('Your account could not be activated')
                this.props.history.push('/')
            })
    }
    render() {
        return 'activated'
    }
}

export default withRouter(Activation)
