import { ICustomProfile } from './api'

export const FAITH_BASED_EVANGELICAL_DEFAULT_PROFILE: ICustomProfile = {
    positive: [
        'AIR_QUALITY_BIC',
        'BUSINESS_ETHICS_BIC',
        'BUSINESS_MODEL_RESILIENCE_BIC',
        'COMPETITIVE_BEHAVIOR_BIC',
        'CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC',
        'CUSTOMER_PRIVACY_BIC',
        'CUSTOMER_WELFARE_BIC',
        'ACCESS_AND_AFFORDABILITY_BIC',
        'DATA_SECURITY_BIC',
        'ECOLOGICAL_IMPACTS_BIC',
        'EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC',
        'EMPLOYEE_HEALTH_AND_SAFETY_BIC',
        'ENERGY_MANAGEMENT_BIC',
        'GHG_EMISSIONS_BIC',
        'HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC',
        'LABOR_PRACTICES_BIC',
        'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
        'MATERIALS_SOURCING_AND_EFFICIENCY_BIC',
        'PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC',
        'PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC',
        'PRODUCT_QUALITY_AND_SAFETY_BIC',
        'SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC',
        'SUPPLY_CHAIN_MANAGEMENT_BIC',
        'SYSTEMIC_RISK_MANAGEMENT_BIC',
        'WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC',
        'WATER_AND_WASTEWATER_MANAGEMENT_BIC',
    ],
    negative: [
        'ABORTIFACIENTS',
        'ABORTION_LEGISLATION',
        'ABORTION_PHILANTHROPY',
        'ABORTION_PROMOTION',
        'ALCOHOL',
        'ALCOHOL_5PERCENT_REVENUE',
        'CANNABIS_CBD_ONLY',
        'CANNABIS_CULTIVATION_PROCESSING',
        'CANNABIS_PHARMACEUTICAL',
        'CANNABIS_RETAIL_THC',
        'EMBRYONIC_STEM_CELL',
        'INVITRO_FERTILIZATION',
        'EXPLOITATION',
        'GAMBLING',
        'GAMBLING_5PERCENT_REVENUE',
        'EXPLOITATION',
        'LGBTA_LEGISLATION',
        'LGBTA_PHILANTHROPY',
        'LGBTA_PROMOTION',
        'PORNOGRAPHY',
        'STATE_OWNED_ENTERPRISE',
        'TOBACCO',
        'TOBACCO_5PERCENT_REVENUE',
    ],
    name: 'Faith Based Evangelical',
    id: 0,
}

export const FAITH_BASED_CATHOLIC: ICustomProfile = {
    positive: [
        'AIR_QUALITY_BIC',
        'BUSINESS_ETHICS_BIC',
        'BUSINESS_MODEL_RESILIENCE_BIC',
        'COMPETITIVE_BEHAVIOR_BIC',
        'CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC',
        'CUSTOMER_PRIVACY_BIC',
        'CUSTOMER_WELFARE_BIC',
        'ACCESS_AND_AFFORDABILITY_BIC',
        'DATA_SECURITY_BIC',
        'ECOLOGICAL_IMPACTS_BIC',
        'EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC',
        'EMPLOYEE_HEALTH_AND_SAFETY_BIC',
        'ENERGY_MANAGEMENT_BIC',
        'GHG_EMISSIONS_BIC',
        'HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC',
        'LABOR_PRACTICES_BIC',
        'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
        'MATERIALS_SOURCING_AND_EFFICIENCY_BIC',
        'PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC',
        'PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC',
        'PRODUCT_QUALITY_AND_SAFETY_BIC',
        'SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC',
        'SUPPLY_CHAIN_MANAGEMENT_BIC',
        'SYSTEMIC_RISK_MANAGEMENT_BIC',
        'WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC',
        'WATER_AND_WASTEWATER_MANAGEMENT_BIC',
    ],
    negative: [
        'ABORTIFACIENTS',
        'ABORTION_LEGISLATION',
        'ABORTION_PHILANTHROPY',
        'ABORTION_PROMOTION',
        'ALCOHOL',
        'ALCOHOL_5PERCENT_REVENUE',
        'CANNABIS_CBD_ONLY',
        'CANNABIS_CULTIVATION_PROCESSING',
        'CANNABIS_PHARMACEUTICAL',
        'CANNABIS_RETAIL_THC',
        'EMBRYONIC_STEM_CELL',
        'INVITRO_FERTILIZATION',
        'EXPLOITATION',
        'GAMBLING',
        'GAMBLING_5PERCENT_REVENUE',
        'LGBTA_LEGISLATION',
        'LGBTA_PHILANTHROPY',
        'CONTRACEPTIVES_BARRIER',
        'LGBTA_PROMOTION',
        'PORNOGRAPHY',
        'STATE_OWNED_ENTERPRISE',
        'TOBACCO',
        'TOBACCO_5PERCENT_REVENUE',
        'WEAPONS_MILITARY',
        'WEAPONS_COMPONENTS',
        'WEAPONS_COMPONENTS_5PERCENT_REVENUE',
        'IN_VITRO_FERTILIZATION',
        'EXPLOITATION',
    ],
    name: 'Faith Based Catholic',
    id: 1,
}

export const ENVIRONMENTAL: ICustomProfile = {
    positive: [
        'ACCESS_AND_AFFORDABILITY_BIC',
        'AIR_QUALITY_BIC',
        'BUSINESS_ETHICS_BIC',
        'BUSINESS_MODEL_RESILIENCE_BIC',
        'COMPETITIVE_BEHAVIOR_BIC',
        'CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC',
        'CUSTOMER_PRIVACY_BIC',
        'CUSTOMER_WELFARE_BIC',
        'DATA_SECURITY_BIC',
        'ECOLOGICAL_IMPACTS_BIC',
        'EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC',
        'EMPLOYEE_HEALTH_AND_SAFETY_BIC',
        'ENERGY_MANAGEMENT_BIC',
        'GHG_EMISSIONS_BIC',
        'HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC',
        'LABOR_PRACTICES_BIC',
        'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
        'MATERIALS_SOURCING_AND_EFFICIENCY_BIC',
        'PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC',
        'PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC',
        'PRODUCT_QUALITY_AND_SAFETY_BIC',
        'SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC',
        'SUPPLY_CHAIN_MANAGEMENT_BIC',
        'SYSTEMIC_RISK_MANAGEMENT_BIC',
        'WASTE_AND_HAZARDOUS_MATERIALS_MANAGEMENT_BIC',
        'WATER_AND_WASTEWATER_MANAGEMENT_BIC',
    ],
    negative: [
        'NUCLEAR',
        'ANIMAL_TESTING',
        'NUCLEAR_5PERCENT_REVENUE',
        'GMO_PRODUCTS',
    ],
    name: 'Environmental',
    id: 2,
}

export const SOCIAL_JUSTICE = {
    negative: [
        'WEAPONS_COMPONENTS',
        'WEAPONS_COMPONENTS_5PERCENT_REVENUE',
        'WEAPONS_MILITARY',
        'PORNOGRAPHY',
        'STATE_OWNED_ENTERPRISE',
    ],
    positive: [
        'ACCESS_AND_AFFORDABILITY_BIC',
        'AIR_QUALITY_BIC',
        'BUSINESS_ETHICS_BIC',
        'BUSINESS_MODEL_RESILIENCE_BIC',
        'COMPETITIVE_BEHAVIOR_BIC',
        'CRITICAL_INCIDENT_RISK_MANAGEMENT_BIC',
        'CUSTOMER_PRIVACY_BIC',
        'CUSTOMER_WELFARE_BIC',
        'DATA_SECURITY_BIC',
        'ECOLOGICAL_IMPACTS_BIC',
        'EMPLOYEE_ENGAGEMENT_DIVERSITY_AND_INCLUSION_BIC',
        'EMPLOYEE_HEALTH_AND_SAFETY_BIC',
        'ENERGY_MANAGEMENT_BIC',
        'GHG_EMISSIONS_BIC',
        'HUMAN_RIGHTS_AND_COMMUNITY_RELATIONS_BIC',
        'LABOR_PRACTICES_BIC',
        'MANAGEMENT_OF_THE_LEGAL_AND_REGULATORY_ENVIRONMENT_BIC',
        'MATERIALS_SOURCING_AND_EFFICIENCY_BIC',
        'PHYSICAL_IMPACTS_OF_CLIMATE_CHANGE_BIC',
        'PRODUCT_DESIGN_AND_LIFECYCLE_MANAGEMENT_BIC',
        'PRODUCT_QUALITY_AND_SAFETY_BIC',
        'SELLING_PRACTICES_AND_PRODUCT_LABELING_BIC',
        'SUPPLY_CHAIN_MANAGEMENT_BIC',
        'SYSTEMIC_RISK_MANAGEMENT_BIC',
        'TOBACCO',
        'TOBACCO_5PERCENT_REVENUE',
    ],
    name: 'Social Justice',
    id: 3,
}
