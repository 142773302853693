import React, { FC, useEffect, useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import cleanwaterImage from '../../images/impactCalculator/cleanWater.png'
import biblesImage from '../../images/impactCalculator/bibles.png'
import shoeboxImage from '../../images/impactCalculator/shoebox.png'
import gospelImage from '../../images/impactCalculator/gospel.png'
import legalImage from '../../images/impactCalculator/legal.png'
import babiesImage from '../../images/impactCalculator/babies.png'
import backdrop from '../../images/impactCalculator/give50backdrop.jpeg'
import give50 from '../../images/impactCalculator/give50logo.svg'
import Button from '../../insightui/Button'
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight'
import { isMobile, isTablet } from 'react-device-detect'
import give50logos from '../../images/give50logos.svg'
import give50logosDark from '../../images/give50logos-dark.svg'
import { url } from 'inspector'

interface ImpactCalculatorProps {
    defaultPortfolioValue?: number
    printing?: boolean
}

const ImpactCalculator: FC<ImpactCalculatorProps> = (props) => {
    const [cleanwater, setCleanwater] = useState(1)
    const [bibles, setBibles] = useState(1)
    const [shoebox, setShoebox] = useState(1)
    const [gospel, setGospel] = useState(1)
    const [legal, setLegal] = useState(1)
    const [babies, setBabies] = useState(1)
    const [portfolioValue, setPortfolioValue] = useState(
        String(props.defaultPortfolioValue ?? '250,000')
    )
    const [rateOfReturn, setRateOfReturn] = useState('7')

    const meta: Array<{ img: any; text: string; key: string; value: any }> = [
        {
            key: 'cleanwater',
            img: cleanwaterImage,
            text: 'People provided with a clean water source',
            value: cleanwater,
        },
        {
            key: 'bibles',
            img: biblesImage,
            text: 'Bibles given to the persecuted Church',
            value: bibles,
        },
        {
            key: 'shoebox',
            img: shoeboxImage,
            text: 'Operation shoebox gifts delivered',
            value: shoebox,
        },
        {
            key: 'gospel',
            img: gospelImage,
            text: 'People reached for the gospel',
            value: gospel,
        },
        {
            key: 'legal',
            img: legalImage,
            text: 'Cases of legal representation for the oppressed',
            value: legal,
        },
        {
            key: 'babies',
            img: babiesImage,
            text: 'Potential babies spared through pro-life counsel',
            value: babies,
        },
    ]

    useEffect(() => {
        const inspire = 0.000268100423828553
        let growth = Number(portfolioValue.replace(/,/g, ''))
        const rate = Number(rateOfReturn.replace(/,/g, '')) / 100

        let total = 0
        function product(input: any) {
            let total = 1
            for (var i = 0; i < input.length; i++) {
                total *= Number(input[i])
            }
            return total
        }

        for (let i = 1; i <= 20; i++) {
            growth = product([growth, 1 + rate])
            let giving = growth * inspire
            total += giving
        }

        setCleanwater(total / 15)
        setBabies(total / 4)
        setShoebox(total / 25)
        setBibles(total / 5)
        setLegal(total / 105)
        setGospel(total / 4.36)
    }, [portfolioValue, rateOfReturn])

    const handlePortfolioValueChange = (value: any) => {
        setPortfolioValue(value.target.value)
    }

    const handleRateOfReturnChange = (rate: any) => {
        setRateOfReturn(rate.target.value)
    }

    const { printing } = props
    return (
        <div>
            <div
                style={{
                    width: '100%',
                    // height: isMobile ? 1200 : printing ? '96%' : 800,
                    zIndex: 1,
                    overflow: 'hidden',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        backgroundImage: `url(${backdrop})`,
                        backgroundSize: printing ? '200%' : '140%',
                        height: printing ? '970px' : 'auto',
                        backgroundPosition: '41% 0%',
                        backgroundRepeat: printing ? 'repeat' : 'no-repeat',
                        padding: printing ? '30px 12px 0' : '100px 20px 300px',
                    }}
                >
                    <Row center="xs" style={{ marginBottom: 20 }}>
                        <img src={give50} width={270} alt="give50" />
                    </Row>
                    {isMobile ? (
                        <></>
                    ) : (
                        <Row center="xs">
                            <h1
                                style={{
                                    color: 'white',
                                    fontSize: 60,
                                    lineHeight: '55px',
                                    margin: '0',
                                }}
                            >
                                Inspire’s Giving Pledge
                            </h1>
                        </Row>
                    )}

                    <Row center="xs">
                        <Col lg={4} md={12}>
                            <span
                                style={{
                                    color: 'white',
                                    fontWeight: 500,
                                    lineHeight: 1.6,
                                    padding: 12,
                                }}
                            >
                                Inspire believes that good returns and good
                                values are not mutually exclusive. Not only does
                                Inspire invests in some of the most impactful
                                companies in the world, but they also seek to be
                                one of the most impactful companies in the
                                world. One way Inspire works to make a
                                meaningful impact in the world is through
                                generosity.&nbsp;
                                <span style={{ fontWeight: 800 }}>
                                    Inspire donates at least 50% of the net
                                    profits from their management fees to
                                    impactful charities with our Give50
                                    campaign.
                                </span>
                            </span>
                        </Col>
                    </Row>
                    {!printing && (
                        <Row center="xs" style={{ marginTop: 30 }}>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.inspireinvesting.com/"
                            >
                                <Button type="primary">
                                    Learn More About Inspire{' '}
                                    <ArrowRight width={25} />
                                </Button>
                            </a>
                        </Row>
                    )}
                </div>
            </div>
            <div
                style={{
                    textAlign: 'center',
                    backgroundColor: 'white',
                    zIndex: 1,
                    // height: isMobile ? 1500 : printing ? 320 : 500,
                    // top: printing ? 420 : 600,
                    marginRight: '5%',
                    marginLeft: '5%',
                    position: 'relative',
                    marginTop: printing ? -630 : -250,
                    padding: '30px 12px 0',
                }}
            >
                <p style={{ fontSize: 24, fontWeight: 700 }}>
                    <span style={{ color: '#ce7a36' }}>
                        Discover the impact
                    </span>{' '}
                    your investment portfolio can make over 20 years by
                    investing with Inspire
                </p>
                <p style={{ fontWeight: 600 }}>
                    Potential impact of a
                    <span
                        style={{
                            color: '#2d7eb1',
                            paddingBottom: 3,
                            fontSize: 20,
                            marginLeft: 5,
                            borderBottom: isMobile
                                ? 'none'
                                : '4px solid #2d7eb1',
                        }}
                    >
                        $
                        <input
                            value={portfolioValue}
                            onChange={handlePortfolioValueChange}
                            style={{
                                width: 90,
                                border: 'none',
                                color: '#2d7eb1',
                                fontWeight: 700,
                                marginRight: 5,
                                fontSize: 20,
                            }}
                        />
                    </span>
                    portfolio with
                    <span
                        style={{
                            color: '#2d7eb1',
                            paddingBottom: 3,
                            fontSize: 20,
                            borderBottom: isMobile
                                ? 'none'
                                : '4px solid #2d7eb1',
                            marginRight: 5,
                        }}
                    >
                        <input
                            value={rateOfReturn}
                            onChange={handleRateOfReturnChange}
                            style={{
                                width: 35,
                                border: 'none',
                                color: '#2d7eb1',
                                textAlign: 'right',
                                fontWeight: 700,
                                fontSize: 20,
                            }}
                        />
                        %
                    </span>
                    expected annual rate of return
                </p>
                <Row
                    style={{
                        paddingTop: 30,
                        padding: printing
                            ? 10
                            : isMobile && !isTablet
                              ? 20
                              : 50,
                    }}
                >
                    {meta.map((item: any) => (
                        <Col
                            lg
                            md={6}
                            xs
                            style={{
                                justifyContent: 'center',
                                textAlign: 'center',
                                padding: 15,
                            }}
                            {...(!printing && { xs: 12 })}
                        >
                            <Row center="xs">
                                <img
                                    alt="give50"
                                    src={item.img}
                                    width={printing ? 40 : 50}
                                    height={printing ? 40 : 50}
                                />
                            </Row>
                            <Row center="xs">
                                <span
                                    style={{
                                        fontWeight: 700,
                                        paddingTop: 10,
                                        paddingBottom: 12,
                                        fontSize: printing ? 14 : 30,
                                    }}
                                >
                                    {Number(item.value)
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </span>
                            </Row>
                            <Row center="xs" style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: 12 }}>
                                    {item.text}
                                </span>
                            </Row>
                        </Col>
                    ))}
                </Row>
            </div>
            <div
                style={{
                    position: printing ? 'relative' : 'static',
                    // top: printing ? 400 : 600,
                    zIndex: 10,
                    padding: printing ? '30px 10px 0' : '30px 20px 20px 20px',
                }}
            >
                <Row
                    style={{
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    <img
                        src={printing ? give50logos : give50logosDark}
                        width="80%"
                    />
                </Row>
                <Row>
                    <Col xs={6} style={{ padding: 10 }}>
                        <p
                            style={{
                                color: printing ? 'white' : 'black',
                                fontSize: 12,
                            }}
                        >
                            Impact estimates based on Inspire donating an
                            average of 0.10% of initial investment for 20 years.
                            Does not include calculation for potential growth
                            rate of assets over time. Actual donations may be
                            higher or lower depending on growth rate, length of
                            time assets are held at Inspire, and other factors.
                            Donation amounts, performance, profit and other
                            factors are not quaranteed. Donations are paid by
                            Inspire from the revenues generated by all of our
                            products and services. Calculation of minimum annual
                            donation amount is (Revenue - Expenses)*50%
                        </p>
                    </Col>
                    <Col xs={6} style={{ padding: 10 }}>
                        <p
                            style={{
                                color: printing ? 'white' : 'black',
                                fontSize: 12,
                            }}
                        >
                            IMPACT DATA SOURCES: 1 WorldHelp.net: $15 provides
                            clean water for 1 person 2 JesusCares.com: Cost per
                            visitor to JesusCares.com ($4.36 each) 3
                            WorldHelp.net: $5 provides one Bible to Christians
                            in persecution 4 International Justice Mission
                            (www.ijm.org): $105 to cover trial fees for one case
                            defending a victim of human trafficking 5
                            Samaritanspurse.org: $25 provides a shoebox to one
                            needy child. • Heartbeatinternational.org: $4
                            provides one consultant call to a frightened
                            pregnant woman
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ImpactCalculator
