import React, { Component } from 'react'
import { globals } from '../utils/globals'
import ESGFundCriterion from '../insightui/ESGFundCriterion'
import Chevron from '../insightui/Chevron'
import { Row, Col } from 'react-flexbox-grid'
import { isMobile } from 'react-device-detect'
import nocategories from '../images/noCategories.png'
import { relative } from 'path'

const DEBUG: boolean = false

interface DropdownTableProps {
    withAnimation?: boolean
    style?: object
    title: string
    negative?: boolean
    securities?: number
    header?: boolean
    total?: number
    printing?: boolean
    category?: string
    nocategories?: boolean
}

export default class DropdownTable extends Component<DropdownTableProps> {
    state = { stateindex: 0 }
    getContainerStyles() {
        const headerHeight: number = 20
        const hasHeader: boolean = this.props.header ? this.props.header : false
        const minHeight: number = hasHeader ? 130 : 110 - headerHeight

        return {
            width: '100%',
            minHeight: this.props.nocategories ? 150 : minHeight,
            height: minHeight,
            overflow: 'hidden',
            backgroundColor: 'white',
            display: 'block',
            ...this.props.style,
        }
    }

    getTitleContainerStyles() {
        const space: number = 5
        const total: number = this.props.total ? this.props.total : 0
        const isNegative: boolean =
            this.props.negative || total < 0 ? true : false
        return {
            width: '100%',
            padding: '15px 25px',
            backgroundColor: isNegative
                ? globals.colors.negative.red
                : globals.colors.positive.blue,
        }
    }

    getTitleStyles() {
        return {
            ...globals.typeface.spacedUppercase,
            color: 'white',
            padding: 0,
            margin: 0,
            marginTop: 0,
            marginLeft: 4,
        } as Record<string, any>
    }

    getTitleText() {
        const isNegative: boolean = this.props.negative ? true : false
        if (isNegative) return 'Negative'
        else return 'Positive'
    }

    getChevronStyles() {
        const size: number = 40
        return {
            width: size,
            height: size,
            cursor: 'pointer',
        }
    }

    getChevronContainerStyles() {
        return {
            width: 50,
            height: 50,
            display: 'inline-block',
        }
    }

    getContainerHeight(input: any) {
        const hasHeader: boolean = input as boolean
        if (hasHeader) return 50
        else return 60
    }

    countChildren(childProps: any) {
        let counter = 0
        React.Children.forEach(childProps, function (child) {
            counter++
        })
        return counter
    }

    onClick(event?: any) {
        const state0 = 0,
            state1 = 1
        const nextState = this.state.stateindex >= 1 ? state0 : state1

        this.setState({
            stateindex: nextState,
        })

        let div = event.target.parentNode.parentNode.parentNode
        const isOpening: boolean = this.state.stateindex === 0

        // div.style.transition = '450ms'
        div.style.height = isOpening ? 'auto' : '0'
    }

    addTitle() {
        return (
            <p
                style={{
                    textTransform: 'uppercase',
                    color: 'white',
                    fontWeight: '1000',
                    letterSpacing: 2,
                    fontSize: '15px',
                    margin: 0,
                }}
            >
                {' '}
                {this.getTitleText()}
            </p>
        )
    }

    addTitleContainer(hasTitle: boolean) {
        if (hasTitle)
            return (
                <div style={{ ...this.getTitleContainerStyles() }}>
                    {this.addTitle()}
                </div>
            )
        return <></>
    }

    addChevronButton() {
        const state = this.state.stateindex
        return (
            <div
                style={{ ...this.getChevronContainerStyles() }}
                onClick={(event) => {
                    this.onClick(event)
                }}
            >
                <Chevron
                    stateindex={state}
                    style={{ ...this.getChevronStyles() }}
                ></Chevron>
            </div>
        )
    }

    addFundScore(isNegative: boolean) {
        const chevronSpace: number = 80
        const title: string = this.props.title
            ? this.props.title
            : 'Undefined Title'
        const securities: number = this.props.securities
            ? this.props.securities
            : 0
        const percent: number = this.props.total ? this.props.total : 0

        const securitiesTitle: string = [
            'Negative',
            'Positive',
        ].includes(title)
            ? 'categories'
            : 'securities'

        return (
            <ESGFundCriterion
                group
                percent={percent}
                negative={isNegative}
                securities={securities}
                securitiesTitle={securitiesTitle}
                title={title}
                withAnimation={this.props.withAnimation}
                style={{
                    display: 'inline-block',
                    width: 'calc(100% - ' + chevronSpace + 'px)',
                }}
            ></ESGFundCriterion>
        )
    }

    addDropDownHeader(isNegative: boolean) {
        return (
            <div>
                {this.addFundScore(isNegative)}
                {this.addChevronButton()}
            </div>
        )
    }

    render() {
        const hasNegativeProp: boolean = this.props.negative
            ? this.props.negative
            : false

        const hasNegativeValue: boolean =
            this.props.total && this.props.total < 0 ? true : false

        const isNegative: boolean = hasNegativeProp || hasNegativeValue
        const isHeader: boolean = this.props.header ? this.props.header : false
        return (
            <div style={{ ...this.getContainerStyles() }}>
                {this.addTitleContainer(isHeader)}
                {this.props.nocategories ? (
                    <>
                        <Row
                            style={{
                                justifyContent: 'center',
                                padding: 30,
                                paddingBottom: 0,
                                margin: 0,
                                backgroundColor: 'white',
                            }}
                        >
                            <Col xs={8}>
                                <Row>
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: 24,
                                        }}
                                    >
                                        No Categories
                                    </span>
                                </Row>
                                <Row>
                                    <span
                                        style={{
                                            fontSize: 12,
                                            marginTop: 12,
                                        }}
                                    >
                                        There are no known categories to report.
                                    </span>
                                </Row>
                            </Col>
                            <Col xs={4}>
                                <img
                                    style={{
                                        position: 'relative',
                                        bottom: 10,
                                        float: 'right',
                                    }}
                                    src={nocategories}
                                />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        {this.addDropDownHeader(isNegative)}
                        {this.props.children}
                    </>
                )}
            </div>
        )
    }
}
