import React, { ReactInstance, ReactNode } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Header from '../../../insightui/Header'
import ImpactScore from '../../../insightui/ImpactScore'
import { IPortfolio } from '../../../models/api'
import ESGCriterion from '../../InstrumentDetails/ESGCriterionFund'
import PortfoliosDetails from './PortfoliosDetails'
import ReactToPrint from 'react-to-print'
import Button from '../../../insightui/Button'
interface ComparePortfoliosProps {
    base: IPortfolio
    compare: IPortfolio
    portfolios: Array<IPortfolio>
}

class PortfolioRepresentation extends React.Component<{
    portfolio: IPortfolio
}> {
    render() {
        const { portfolio } = this.props
        return (
            <>
                <Col lg={6} style={{ padding: 50 }}>
                    <Row>
                        <Col>
                            <ImpactScore
                                type="large"
                                withAnimation
                                bgColor={'rgb(251, 239, 229)'}
                                footer
                                score={portfolio.impactScore}
                            />
                        </Col>
                        <Col style={{ paddingLeft: 20 }}>
                            <Row>
                                <span style={{ fontWeight: 700, fontSize: 40 }}>
                                    {portfolio.name}
                                </span>
                            </Row>
                            <Row>
                                <span
                                    style={{
                                        fontWeight: 300,
                                        fontSize: 13,
                                        paddingTop: 10,
                                    }}
                                >
                                    {portfolio.type} | $
                                    {portfolio.monetaryValue
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    | <strong>{portfolio.totalHoldings}</strong>{' '}
                                    {portfolio.totalHoldings > 1
                                        ? 'Holdings'
                                        : 'Holding'}{' '}
                                    | Last Modified:{' '}
                                    <strong>
                                        {new Date(
                                            portfolio.lastModified
                                        ).toDateString()}
                                    </strong>
                                </span>
                            </Row>
                        </Col>
                    </Row>
                    <ESGCriterion instrument={portfolio} />
                </Col>
            </>
        )
    }
}

export default class ComparePortfolios extends React.Component<ComparePortfoliosProps> {
    private ref: any
    constructor(props: any) {
        super(props)
        this.ref = React.createRef()
    }

    fetchPrintContent = () => {
        return this.ref.current
    }

    render() {
        const { base, compare, portfolios } = this.props
        return (
            <div>
                <Header logo />
                <div ref={this.ref}>
                    <Row style={{ padding: 30, paddingTop: 150 }}>
                        <Col>
                            <span
                                style={{
                                    fontSize: 50,
                                    fontWeight: 700,
                                }}
                            >
                                Compare Portfolios
                            </span>
                        </Col>
                        <Col style={{ marginLeft: 20 }}>
                            <ReactToPrint
                                content={this.fetchPrintContent}
                                documentTitle="test"
                                trigger={() => (
                                    <Button
                                        type="outlined"
                                        style={{ width: 150 }}
                                    >
                                        Print
                                    </Button>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <PortfoliosDetails
                                portfolioId={base.id}
                                portfolios={portfolios}
                                onCompare={(id) => {}}
                                compare
                            />
                        </Col>
                        <Col xs={6}>
                            <PortfoliosDetails
                                portfolioId={compare.id}
                                portfolios={portfolios}
                                onCompare={(id) => {}}
                                compare
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
