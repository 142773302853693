import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import logo from '../images/logo.svg'
import { config } from '../models/config'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

const styles = {
    sectionHeaders: {
        fontWeight: 700,
        fontSize: 14,
    },
    href: {
        textDecoration: 'none',
        color: 'black',
        fontSize: 13,
        fontWeight: 300,
    },
}

const headerLinks = {
    account: [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Portfolios', url: '/portfolios' },
        { title: 'Account Settings', url: '/account-settings' },
    ],
    insight: [
        { title: 'About Inspire Insight', url: '/about' },
        { title: 'Screening Categories', url: '/screening-categories' },
        { title: 'Help & Support', url: '/help-and-support' },
        { title: 'Status Page', url: 'https://inspireinsight.statuspage.io/' },
    ],
}

function Linkable({ title, url }: { title: string; url: string }) {
    return (
        <Row style={{ paddingTop: 12 }}>
            {title === 'Status Page' ? (
                <a
                    href={url}
                    style={styles.href}
                    target="_blank"
                    rel="noreferrer"
                >
                    {title}
                </a>
            ) : (
                <Link style={styles.href} to={`${url}`}>
                    {title}
                </Link>
            )}
        </Row>
    )
}
export default class Footer extends React.Component<{ marginTop?: number }> {
    render() {
        const { marginTop } = this.props
        return (
            <div
                style={{
                    marginTop: marginTop,
                    padding: '0 10px 70px',
                }}
            >
                <Row>
                    <Col lg={8} xs={12}>
                        <img
                            src={logo}
                            alt="Inspire Logo"
                            style={{
                                width: isMobile ? 230 : 270,
                            }}
                        />
                    </Col>
                    <Col lg={2} xs={6} style={{ padding: 10 }}>
                        <Row>
                            <p style={styles.sectionHeaders}>ACCOUNT</p>
                        </Row>
                        {headerLinks.account.map((i) => (
                            <Linkable title={i.title} url={i.url} />
                        ))}
                    </Col>
                    <Col lg={2} xs={6} style={{ padding: 10 }}>
                        <Row>
                            <p style={styles.sectionHeaders}>INSIGHT</p>
                        </Row>
                        {headerLinks.insight.map((i) => (
                            <Linkable title={i.title} url={i.url} />
                        ))}
                    </Col>
                </Row>
                <Row style={{ marginTop: 50 }}>
                    <Col xs={6} lg={10}>
                        <Link style={styles.href} to={`/terms-of-service`}>
                            Terms
                        </Link>
                        <span style={{ marginLeft: 20 }} />
                        <Link style={styles.href} to={`/privacy-policy`}>
                            Privacy Policy
                        </Link>
                    </Col>
                    <Col xs={6} lg={2} style={styles.href}>
                        © Copyright - Inspire Insight
                    </Col>
                </Row>
            </div>
        )
    }
}
