import React from 'react'
import LoginRegistration from '../components/Registration/LoginRegistration'
import { useSelector } from 'react-redux'

const Login: React.FC = () => {
    const isAuthenticated = useSelector((state: any) => state.isLogged)
    if (isAuthenticated) {
        window.location.href = '/dashboard'
        return null
    }
    return <LoginRegistration type="login" />
}

export default Login
