import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Button from '../../insightui/Button'
import { config } from '../../models/config'
import ImpactScore from '../../insightui/ImpactScore'
import searchIcon from '../../images_export/search-light.svg'
import axios from 'axios'

import { css, nthChild } from 'glamor'
import { UniversalSearchResultItem } from '../../models/api'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import AddToPortfolio from '../../components/Portfolios/AddToPortfolio'
import { toast, ToastContainer } from 'react-toastify'
import { store } from '../../store'

import { isMobile, isTablet } from 'react-device-detect'
import { Plus } from '@styled-icons/feather/Plus'

interface SearchInputProps extends RouteComponentProps {
    isHome?: boolean
    onAdd?: () => void
    refreshPortfolios?: () => void
}
class SearchInput extends React.Component<SearchInputProps> {
    state = {
        searchResults: [] as UniversalSearchResultItem[],
        tokens: [],
        query: '',
        lastChange: null,
        addTo: false,
        portfolios: [],
        instrument: null,
    }
    handleAddToPortfolio = (portfolioList: Array<number>, cb?: () => void) => {
        const item: any = this.state.instrument
        const requestData = {
            financialInstrumentId: item.id,
            portfolioIds: portfolioList,
        }

        axios
            .post(`${config.api}/api/portfolios/holdings`, requestData, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response) => {
                toast.success(
                    `${item.name} has been added to your ${
                        portfolioList.length === 1
                            ? 'portfolio.'
                            : 'portfolios.'
                    }`
                )
                if (cb) cb()
                this.setState({ addTo: false })
            })
            .catch((err) => {
                toast.error(
                    `Error, ${item.name} cannot be added to your portfolio`
                )
                this.setState({ addTo: false })
            })
    }

    _handleKeyDown = (e) => {
        if (e.key == 'Enter') {
            if (this.state.searchResults.length) {
                this.navigateToInstrument(this.state.searchResults[0])
            }
        }
    }
    search = (value: any) => {
        this.state.tokens.forEach((source: any) => source.cancel())
        // let result = value.target.value
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        this.setState({
            tokens: this.state.tokens.concat(source),
        })

        axios
            .get(`${config.api}/api/search?query=${value}`)
            .then((response) => {
                let results = response.data as any[]
                let filteredResults = []
                results.forEach((data) => {
                    data.impactScore = (
                        <ImpactScore
                            score={data.impactScore}
                            type="small"
                            key={Math.random() * 10000}
                            withAnimation={false}
                        />
                    )
                    filteredResults.push(data)
                })
                this.setState({
                    searchResults: filteredResults,
                })

                this.forceUpdate()
            })
            .catch((thrown) => {
                //handle error
            })
    }

    navigateToInstrument = (item: UniversalSearchResultItem) => {
        if (item.isDomestic) {
            this.props.history.push(`/${item.ticker}`)
            window.location.reload()
        } else {
            this.props.history.push(
                `/${item.ticker}/${item.exchangeLocation
                    .replace('(', '')
                    .replace(')', '')
                    .replace(' ', '')}`
            )
        }
    }

    searchResultsContainer = css(
        nthChild('odd', {
            backgroundColor: '#e3eaee',
        }),
        nthChild('even', {
            backgroundColor: 'white',
        })
    )

    renderInputPosition() {
        if (isMobile && !this.props.isHome && !isTablet) {
            return 'absolute'
        } else {
            return 'static'
        }
    }

    renderInputCols() {
        if (this.props.isHome) {
            return {
                lg: 6,
                md: 8,
                sm: 11,
                xs: 11,
            }
        } else {
            return {
                xs: 12,
            }
        }
    }
    render() {
        return (
            <>
                <AddToPortfolio
                    isActive={this.state.addTo}
                    refreshPortfolios={this.props.refreshPortfolios}
                    addToPortfolio={this.handleAddToPortfolio}
                    onClose={() => {
                        this.setState({ addTo: false })
                    }}
                />
                <ToastContainer position="bottom-center" hideProgressBar />
                <Row
                    style={{
                        justifyContent: this.props.isHome ? 'center' : 'normal',
                        marginTop: this.props.isHome ? '30' : '0',
                        width: '100%',
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                >
                    <Col
                        style={{
                            position: this.renderInputPosition(),
                            top: isMobile && !isTablet ? '70px' : 'auto',
                            left: isMobile && !isTablet ? '8px' : 'auto',
                            width: isMobile && !isTablet ? '100%' : 'auto',
                            backgroundColor: '#f7f7f7',
                        }}
                        {...this.renderInputCols()}
                    >
                        <input
                            id="searchmaster"
                            placeholder={
                                isMobile
                                    ? 'Search by ticker or name'
                                    : 'Search by ticker, company, or fund name'
                            }
                            onKeyDown={this._handleKeyDown}
                            style={{
                                backgroundImage: `url(${searchIcon})`,
                                backgroundPosition: '96% 19px',
                                backgroundSize: 20,
                                backgroundRepeat: 'no-repeat',
                                boxSizing: 'border-box',
                                width: '100%',
                                height: 58,
                                border: 0,
                                boxShadow:
                                    '1px 1px 6px -5px rgb(11 25 44 / 40%)',
                                paddingLeft: 25,
                                paddingRight: 60,
                                fontWeight: 700,
                                fontSize: 15,
                                color: '#0b192c',
                            }}
                            autoFocus={!isMobile && !isTablet}
                            onBlur={() => {
                                setTimeout(() => {
                                    this.setState({
                                        query: '',
                                    })
                                }, 200)
                            }}
                            onFocus={(e) => {
                                this.setState({
                                    query: e.target.value,
                                    lastChange: Date.now(),
                                })
                            }}
                            onChange={(event) => {
                                this.setState({
                                    query: event.target.value,
                                    lastChange: Date.now(),
                                })
                                setTimeout(() => {
                                    if (this.state.query !== '') {
                                        let currentTime = Date.now()
                                        let timeDifference =
                                            currentTime -
                                            this.state.lastChange -
                                            800

                                        if (timeDifference) {
                                            this.search(this.state.query)
                                        }
                                    }
                                }, 200)
                            }}
                        />
                    </Col>
                </Row>
                <Row
                    style={{
                        justifyContent: this.props.isHome ? 'center' : 'normal',
                        marginTop: 0,
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                >
                    {this.state.searchResults.length === 0 ||
                    this.state.query === '' ? (
                        <></>
                    ) : (
                        <Col
                            style={{
                                position: this.renderInputPosition(),
                                top: isMobile && !isTablet ? '120px' : 'auto',
                                left: isMobile && !isTablet ? '5px' : 'auto',
                                width: isMobile && !isTablet ? '100%' : 'auto',
                                zIndex: 5000,
                            }}
                            {...this.renderInputCols()}
                        >
                            <div
                                style={{
                                    borderTop: 0,
                                    width: '100%',
                                    marginRight: isMobile ? 0 : 15,
                                    marginLeft: isMobile ? 3 : 0,
                                    position: 'relative',
                                    height: 0,
                                    zIndex: 1000000,
                                    boxShadow: '0 4px 8px #dbdbdc',
                                }}
                            >
                                {this.state.searchResults.map(
                                    (
                                        item: UniversalSearchResultItem,
                                        index: number
                                    ) => (
                                        <Row
                                            {...this.searchResultsContainer}
                                            style={{
                                                cursor: 'pointer',
                                                // margin: 8,
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        >
                                            <Col
                                                xs={2}
                                                style={{
                                                    paddingTop: 13,
                                                    paddingRight: 0,
                                                }}
                                                onClick={() =>
                                                    this.navigateToInstrument(
                                                        item
                                                    )
                                                }
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: 700,
                                                        fontSize: 13,
                                                        fontFamily:
                                                            'URW Geometric Cond',
                                                    }}
                                                >
                                                    {item.ticker}
                                                </span>
                                            </Col>
                                            <Col
                                                sm={6}
                                                xs={5}
                                                style={{
                                                    paddingTop: 15,
                                                    fontSize: 14,
                                                    fontFamily:
                                                        'URW Geometric Cond',
                                                }}
                                                onClick={() =>
                                                    this.navigateToInstrument(
                                                        item
                                                    )
                                                }
                                            >
                                                {item.name.slice(0, 30)}
                                                {''}
                                                {item.name.length > 20
                                                    ? '...'
                                                    : ''}
                                            </Col>
                                            <Col
                                                sm={2}
                                                xs={3}
                                                style={{
                                                    paddingTop: 10,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                }}
                                                onClick={() =>
                                                    this.navigateToInstrument(
                                                        item
                                                    )
                                                }
                                            >
                                                {item.impactScore}
                                                {/* <ImpactScore
                                                    score={item.impactScore}
                                                    type="small"
                                                    withAnimation={false}
                                                /> */}
                                            </Col>
                                            <Col
                                                xs={2}
                                                style={{
                                                    paddingTop: 12,
                                                }}
                                            >
                                                <Button
                                                    type="searchMini"
                                                    style={{ float: 'right' }}
                                                    onClick={() => {
                                                        if (
                                                            store.getState()
                                                                .token
                                                        ) {
                                                            this.setState({
                                                                addTo: true,
                                                                instrument:
                                                                    item,
                                                                query: '',
                                                            })
                                                        } else {
                                                            this.props.history.push(
                                                                '/login'
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <Plus
                                                        width={17}
                                                        style={{
                                                            position:
                                                                isMobile ||
                                                                isTablet
                                                                    ? 'static'
                                                                    : 'relative',

                                                            bottom: '1',
                                                            right: '2',
                                                        }}
                                                    />
                                                    {isMobile || isTablet ? (
                                                        <></>
                                                    ) : (
                                                        <>Add</>
                                                    )}
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            </>
        )
    }
}
export default withRouter(SearchInput)
