import { Component } from 'react'
import { globals } from '../utils/globals'

type NumberRollType = 'small' | 'large' | 'default'

interface NumberRollProps {
    height?: number
    width?: number
    type?: NumberRollType
    stateindex?: number
    initial?: number
    final: number
    speedMS?: number
    fontSize?: string
    style?: Record<string, any>
    withAnimation?: boolean
    match?: boolean
    negative?: boolean
}

const numberRollStyles: Record<string, any> = {
    position: 'relative',
    display: 'inline-block',
    border: 'none',
    width: 'auto',
    height: 'auto',
    backgroundSize: '100%',
    minHeight: '40px',
    fill: 'rgb(248, 152, 56)',
    fontWeight: '700',
    lineHeight: '1.5',
    verticalAlign: 'top',
    marginTop: '-5px',
    color: '#f89838',
}

export default class NumberRoll extends Component<NumberRollProps> {
    state = {
        stateindex: 0,
    }

    countDynamic(initial: number, final: number, speedMS: number) {
        initial = initial ? Math.abs(initial) : 0
        final = Math.abs(final)
        let first = Math.min(initial, final)
        let fix: number = final === 1 ? 1 : 0
        let last = Math.max(initial, final) - 1 + fix
        let isDone: boolean = first === last || final === 1
        let value: number = isDone ? last : last + 1

        const animate = this.props.withAnimation === false ? false : true
        if (!animate)
            return this.setState({
                stateindex: value,
            })

        this.setState({
            stateindex: Math.abs(first),
        })

        if (Math.abs(initial) - Math.abs(final) > 1) {
            const newIntervalId = setInterval(() => {
                if (this.state.stateindex >= last) {
                    clearInterval(newIntervalId)
                    this.setState({
                        stateindex: Math.abs(last),
                    })
                }
                const nextState: number = this.state.stateindex + 1
                this.setState({
                    stateindex: nextState,
                })
            }, speedMS)
        } else {
            this.setState({
                stateindex: final,
            })
        }
    }

    componentDidMount() {
        const defaultSpeed: number = 20
        const defaultInitial: number = 0
        const initial: number = this.props.initial
            ? this.props.initial
            : defaultInitial
        const speedMS: number = this.props.speedMS
            ? this.props.speedMS
            : defaultSpeed

        this.countDynamic(initial, this.props.final, speedMS)
    }

    getStyles(isNeg?: boolean) {
        let color: string = this.props.match
            ? globals.colors.inspire.orange
            : isNeg
            ? globals.colors.negative.red
            : globals.colors.positive.blue

        return {
            ...numberRollStyles,
            marginLeft: 6,
            fontSize: this.props.fontSize ? this.props.fontSize : '30px',
            color: color,
            fontWeight: 700,
            verticalAlign: 'top',
            ...globals.styles.presets.noselect,
            ...this.props.style,
        }
    }

    getSubtractionIf(isNeg: boolean) {
        const text: string = '-'
        const color: string = !isNeg
            ? 'transparent'
            : this.props.match
            ? globals.colors.negative.red
            : globals.colors.inspire.orange

        return (
            <p
                style={{
                    display: 'inline-block',
                    height: 10,
                    marginTop: 0,
                    color: color,
                    marginLeft: this.props.type === 'large' ? -6 : -2,
                    marginRight: this.props.type === 'large' ? -4 : 2,
                    transform:
                        this.props.type === 'large'
                            ? 'scale(1.0,1.0)'
                            : 'scale(1.5,1.0)',
                }}
            >
                {text}
            </p>
        )
    }

    getStateIndex(isNeg?: boolean) {
        const index: number = isNeg
            ? this.state.stateindex
            : this.state.stateindex

        const matchColor: string = isNeg
            ? globals.colors.negative.red
            : globals.colors.positive.blue

        const color: string = this.props.match
            ? matchColor
            : globals.colors.inspire.orange

        return (
            <p
                style={{
                    display: 'inline-block',
                    height: 10,
                    marginTop: 0,
                    color: color,
                }}
            >
                {isNaN(index)
                    ? 'N/A'
                    : index < 1
                    ? '<1'
                    : index.toFixed(0).toString()}
            </p>
        )
    }

    render() {
        const initial: number = this.props.initial ? this.props.initial : 0
        const isNeg: boolean = this.props.negative || this.props.final < initial
        const hasNegSign: boolean = !this.props.negative && isNeg

        return (
            <div style={{ ...this.getStyles(isNeg) }}>
                {this.getSubtractionIf(hasNegSign)}
                {this.getStateIndex(isNeg)}
            </div>
        )
    }
}
