import React from 'react'
import Header from '../insightui/Header'
import Footer from '../insightui/Footer'
import { Row, Col } from 'react-flexbox-grid'
import { store } from '../store'
import axios from 'axios'
import { config } from '../models/config'
import { IAccount } from '../models/api'

import { Person } from '@styled-icons/bootstrap/Person'
import { Tag } from '@styled-icons/bootstrap/Tag'
import { Payment } from '@styled-icons/fluentui-system-regular/Payment'
import ProfileSettings from '../components/Account/ProfileSettings'
import Card from '../components/Account/Card'
import PlansAndPricing from './PlansAndPricing'
import Billing from '../components/Account/Billing'
import { fetchPlanTitle } from '../utils/globals'

export default class AccountSettings extends React.Component {
    state: {
        activeMenu: 'profile' | 'plans' | 'billing'
        account: IAccount | null
    } = {
        activeMenu: 'profile',
        account: null,
    }

    getUserDetails = async () => {
        await axios
            .get(`${config.api}/api/account`, {
                headers: { Authorization: `Bearer ${store.getState().token}` },
            })
            .then((response: any) => {
                this.setState({ account: response.data })
            })
    }

    componentDidMount() {
        if (!this.state.account) {
            this.getUserDetails()
        }
    }

    render() {
        return (
            <div style={{ backgroundColor: '#f7f7f7' }}>
                {this.state.activeMenu ? <Header logo /> : <></>}
                <div
                    style={{
                        padding: 90,
                        paddingLeft: '5%',
                        paddingRight: '14%',
                        paddingTop: 150,
                    }}
                >
                    <h1>Account Settings</h1>
                    <Row>
                        <Col xs={12} sm={12} md={3} lg={2}>
                            <Row>
                                <Col
                                    xs={4}
                                    sm={4}
                                    md={12}
                                    lg={12}
                                    style={{
                                        backgroundColor:
                                            this.state.activeMenu === 'profile'
                                                ? 'white'
                                                : '',
                                        padding: '20px',
                                        fontWeight: 800,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        this.setState({ activeMenu: 'profile' })
                                    }
                                >
                                    <Person
                                        style={{
                                            float: 'left',
                                            paddingRight: '.5em',
                                        }}
                                        width={20}
                                    />
                                    <p style={{ float: 'left', margin: '0' }}>
                                        Profile
                                    </p>
                                </Col>

                                <Col
                                    xs={4}
                                    sm={4}
                                    md={12}
                                    lg={12}
                                    style={{
                                        backgroundColor:
                                            this.state.activeMenu === 'plans'
                                                ? 'white'
                                                : '',
                                        padding: '20px',
                                        fontWeight: 800,
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        this.setState({ activeMenu: 'plans' })
                                    }
                                >
                                    <Tag
                                        style={{
                                            float: 'left',
                                            paddingRight: '.5em',
                                        }}
                                        width={20}
                                    />
                                    <p style={{ float: 'left', margin: '0' }}>
                                        Plans
                                    </p>
                                </Col>

                                {fetchPlanTitle() === 'Pro' && (
                                    <Col
                                        xs={4}
                                        sm={4}
                                        md={12}
                                        lg={12}
                                        style={{
                                            backgroundColor:
                                                this.state.activeMenu ===
                                                'billing'
                                                    ? 'white'
                                                    : '',
                                            padding: '20px',
                                            fontWeight: 800,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                activeMenu: 'billing',
                                            })
                                        }
                                    >
                                        <Payment
                                            style={{
                                                float: 'left',
                                                paddingRight: '.5em',
                                            }}
                                            width={20}
                                        />
                                        <p
                                            style={{
                                                float: 'left',
                                                margin: '0',
                                            }}
                                        >
                                            Billing
                                        </p>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                        <Col
                            xs={12}
                            sm={12}
                            md={9}
                            lg={10}
                            style={{
                                backgroundColor: 'white',
                                padding: 40,
                            }}
                        >
                            {this.state.account ? (
                                <>
                                    {this.state.activeMenu === 'profile' ? (
                                        <ProfileSettings
                                            account={this.state.account}
                                        />
                                    ) : (
                                        <>
                                            {this.state.activeMenu ===
                                            'billing' ? (
                                                <Billing />
                                            ) : (
                                                <PlansAndPricing
                                                    disableFooter
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                </div>
                <Footer />
            </div>
        )
    }
}
