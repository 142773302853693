import React from 'react'

interface inputProps {
    value: string
    placeholder?: string
    onChange: (e: string) => void
    width?: number | string
    height?: number | string
}

export function Input(props: inputProps) {
    return (
        <input
            style={{
                backgroundColor: '#e3eaef',
                border: 'none',
                borderRadius: 8,
                padding: 10,
                height: props.height ? props.height : 30,
                width: props.width ? props.width : 'auto',
            }}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
        />
    )
}
