import React from 'react'
import PropagateLoader from 'react-spinners/PropagateLoader'
import complete from '../../images/complete.png'
import axios from 'axios'
import Button from '../../insightui/Button'
import { store } from '../../store'
import { config } from '../../models/config'
import Modal from '../Modal'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { Row, Col } from 'react-flexbox-grid'

export default class PortfolioUpload extends React.Component<{
    import: boolean
    portfolioId: number
    onFinish: () => void
    onClose: () => void
}> {
    state: {
        uploading: 'in-progress' | 'failed' | 'completed' | 'waiting'
        uploadingResponse: any
        over: boolean
    } = {
        uploading: 'waiting',
        uploadingResponse: null,
        over: false,
    }

    handlePortfolioUpload = (acceptedFiles: any) => {
        this.setState({
            uploading: 'inprogress',
        })
        let formdata = new FormData()
        formdata.append('file', acceptedFiles[0])

        axios
            .post(
                `${config.api}/api/portfolios/${this.props.portfolioId}/holdings/upload-excel-file`,
                formdata,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((response) => {
                if (Object.keys(response.data).includes('attemptedCount')) {
                    this.setState({
                        uploading: 'completed',
                        uploadingResponse: response.data,
                        over: false,
                    })
                    this.props.onFinish()
                } else {
                    this.setState({
                        uploading: 'failed',
                        over: false,
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    uploading: 'failed',
                    over: false,
                })
            })
    }
    render() {
        return (
            <Modal isActive={this.props.import} width={500}>
                <Row
                    style={{
                        flexDirection: 'row',
                        marginBottom: 25,
                    }}
                >
                    <Col xs />
                    <Col style={{ cursor: 'pointer' }}>
                        <CloseOutline
                            width={20}
                            style={{ cursor: 'pointer' }}
                            onClick={this.props.onClose}
                        />
                    </Col>
                </Row>
                {this.state.uploading === 'in-progress' ? (
                    <div
                        style={{
                            textAlign: 'center',
                            width: '100%',
                            paddingBottom: 50,
                        }}
                    >
                        <p>Processing</p>
                        <PropagateLoader loading={true} size={20} />
                    </div>
                ) : (
                    <></>
                )}
                {this.state.uploading === 'completed' ? (
                    <div
                        style={{
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        <img src={complete} width={165} alt="Upload" />
                        <h2 style={{ fontWeight: 400 }}>Upload Complete</h2>
                        <p>
                            {this.state.uploadingResponse.completedCount} of{' '}
                            {this.state.uploadingResponse.attemptedCount} have
                            been successfully uploaded to your portfolio.
                        </p>
                    </div>
                ) : (
                    <></>
                )}
                {this.state.uploading === 'waiting' ||
                this.state.uploading === 'failed' ? (
                    <>
                        <Dropzone
                            onDrop={this.handlePortfolioUpload}
                            onDragEnter={() => this.setState({ over: true })}
                            onDragLeave={() => this.setState({ over: false })}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div
                                        {...getRootProps()}
                                        style={{
                                            width: '100%',
                                            height: 200,
                                            border: '2px dashed #3c90ba',
                                            backgroundColor: this.state.over
                                                ? '#fcfcfc'
                                                : '#e8f1f7',
                                            borderRadius: 30,
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            color: '#3c90ba',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <input {...getInputProps()} />
                                        <p
                                            style={{
                                                marginTop: '17%',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Drag and drop Excel File here, or
                                            click here to import
                                        </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: 30,
                            }}
                        >
                            {this.state.uploading === 'failed' ? (
                                <p style={{ color: '#d55268' }}>
                                    Upload failed. Please try again
                                </p>
                            ) : (
                                <p>
                                    To import your holdings use the Inspire
                                    Holdings Excel Template.
                                </p>
                            )}
                            <Link
                                to="/files/ImportPortfolioTemplate.xlsx"
                                target="_blank"
                                download
                            >
                                <Button type="primary" onClick={() => {}}>
                                    Download Template
                                </Button>
                            </Link>
                            <Button
                                type="outlined"
                                style={{ marginTop: 10 }}
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </Modal>
        )
    }
}
