import React, { useCallback, useEffect, useState } from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { isMobile, isTablet } from 'react-device-detect'
import { RouteComponentProps } from 'react-router-dom'

const messages = [
    'Visiting NYSE, be right back',
    'Fetching obscure data',
    'Checking on the latest inflation data',
    'Firing up the Bloomberg Terminal',
    'Reading Investopedia articles',
    'Using the force',
    'Preparing your red pill',
    'Securing securities',
    'Committing this work for God’s glory',
    'Reticulating tickers',
]

interface MessageLoaderProps {
    defaultMessage?: string
}

function MessageLoader(props: MessageLoaderProps) {
    const [newMessage, setnewMessage] = useState(
        props.defaultMessage ?? 'Loading...'
    )

    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * messages.length)

        setnewMessage(messages[index])
    }, [])

    useEffect(() => {
        const intervalID = setInterval(shuffle, 5000)
        return () => clearInterval(intervalID)
    }, [shuffle])

    return <>{newMessage}</>
}

interface LoadingScreenProps {
    defaultMessage?: string
}
export default class LoadingScreen extends React.Component<LoadingScreenProps> {
    render() {
        return (
            <>
                <div
                    style={{
                        width: '55%',
                        margin:
                            isMobile && !isTablet
                                ? '200px auto 50px'
                                : '20% auto 100px',
                    }}
                >
                    <div
                        style={{
                            margin: '0 auto',
                            width: 42,
                        }}
                    >
                        <ScaleLoader color={'black'} loading={true} />
                    </div>

                    <p
                        style={{
                            textAlign: 'center',
                            fontFamily: 'URW Geometric Cond',
                            textTransform: 'uppercase',
                            fontWeight: 700,
                            fontSize: 13,
                        }}
                    >
                        <MessageLoader
                            defaultMessage={this.props.defaultMessage}
                        />
                    </p>
                </div>
            </>
        )
    }
}
