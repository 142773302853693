import React from 'react'
import { Row, Col } from 'react-flexbox-grid'

import { IAccount, UniversalSearchResultItem } from '../models/api'
import AccountMenu from '../components/Dashboard/AccountMenu'
import axios from 'axios'
import { isMobile } from 'react-device-detect'

import { config } from '../models/config'
import { store } from '../store'

import AuthMenu from '../components/Dashboard/AuthMenu'
import SearchInput from '../components/Portfolios/SearchInput'

import logo from '../images/logo.svg'
import Button from './Button'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
    maxAge: 15 * 60 * 1000,
})

const api = axios.create({
    adapter: cache.adapter,
})

const header: Record<string, any> = {
    width: '100%',
    boxSizing: 'border-box',
    display: 'block',
    // paddingTop: 15,
    backgroundColor: '#F7F7F7',
    top: 0,
    position: 'fixed',
    zIndex: 10,
}

interface HeaderProps extends RouteComponentProps {
    activeItem?: string
    onChange?: () => void
    logo?: boolean
    scrollEnabled?: boolean
    account?: IAccount
    subscriberExempt?: boolean
    refreshPortfolios?: () => void
}

interface HeaderState {
    searchResults: Array<UniversalSearchResultItem>
    loading: boolean
    tokens: Record<string, any>
    query: string
    lastChange: any
    scrollActive: boolean
    prev: number
    account: IAccount | null
    open: boolean
    hasResults: boolean
    ready: boolean
}

class Header extends React.Component<HeaderProps> {
    count: number = 0

    state: HeaderState = {
        scrollActive: false,
        prev: window.scrollY,
        searchResults: [] as UniversalSearchResultItem[],
        loading: false,
        tokens: [],
        query: '',
        lastChange: null,
        account: null,
        open: false,
        hasResults: true,
        ready: false,
    }

    performAction = () => {
        this.setState({
            scrollActive: window.scrollY > 350,
            prev: window.scrollY,
        })
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.performAction)
        this.setState({ ready: true })
        this.getGoodStandingStatus(this.getUserDetails)
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.performAction)
    }

    search = async (value: any) => {
        this.state.tokens.forEach((source: any) => source.cancel())
        // let result = value.target.value
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        this.setState({
            tokens: this.state.tokens.concat(source),
        })

        await axios
            .get(`${config.api}/api/search?query=${value}`)
            .then((response) => {
                this.setState({
                    searchResults: response.data as UniversalSearchResultItem[],
                    hasResults: true,
                })
            })
            .catch((thrown) => {
                this.setState({
                    hasResults: false,
                })
            })
    }

    navigateToInstrument = (item: UniversalSearchResultItem) => {
        if (item.isDomestic) {
            this.props.history.push(`/${item.ticker}`)
            window.location.reload()
        } else {
            this.props.history.push(
                `/${item.ticker}/${item.exchangeLocation
                    .replace('(', '')
                    .replace(')', '')
                    .replace(' ', '')}`
            )
        }
    }

    _handleKeyDown = (e) => {
        if (e.key == 'Enter') {
            if (this.state.searchResults.length) {
                this.navigateToInstrument(this.state.searchResults[0])
            }
        }
    }

    getGoodStandingStatus = (cb) => {
        api({
            url: `${config.api}/api/goodstanding`,
            headers: {
                Authorization: `Bearer ${store.getState().token}`,
            },
        }).then((response: any) => {
            if (
                response.data.toLowerCase() === 'false' &&
                !this.props.subscriberExempt
            ) {
                this.props.history.push('/checkout')
            }

            if (cb) cb()
        })
    }

    getUserDetails = () => {
        if (store.getState().token) {
            api({
                url: `${config.api}/api/account`,
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
                .then((response: any) => {
                    // if (
                    //     !((response.data.goodStanding as string) === 'TRUE') &&
                    //     !this.props.subscriberExempt
                    // ) {
                    //     this.props.history.push('/checkout')
                    // } else {
                    this.setState({ account: response.data, ready: true })
                    // }
                })
                .catch((e) => {})
        }
    }

    render() {
        const loggedIn = store.getState().isLogged
        return (
            <div
                style={{
                    ...header,
                    ...{
                        height: 90,
                    },
                }}
            >
                <Row
                    style={{
                        position: 'fixed',
                        width: '100%',
                        maxWidth: 1400,
                        marginTop: 8,
                    }}
                >
                    <>
                        {this.props.logo ? (
                            <Col
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.props.history.push('/')}
                            >
                                <img
                                    src={logo}
                                    style={{ height: 60, paddingLeft: 20 }}
                                    alt="Inspire Insight"
                                />
                            </Col>
                        ) : (
                            <></>
                        )}
                        <Col xs={5}>
                            <SearchInput
                                refreshPortfolios={this.props.refreshPortfolios}
                            />
                        </Col>
                        {this.state.ready && (
                            <>
                                <Col>
                                    {this.state.account ? (
                                        <AuthMenu
                                            onClick={() =>
                                                this.setState({ open: true })
                                            }
                                            account={this.state.account}
                                            padding={8}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                                {!loggedIn && !this.state.account ? (
                                    <Row
                                        style={{
                                            paddingTop: 4,
                                            position: 'absolute',
                                            right: 0,
                                            paddingRight: 15,
                                        }}
                                    >
                                        <Col xs>
                                            <Button
                                                type="ghost"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    this.props.history.push(
                                                        '/login'
                                                    )
                                                }}
                                            >
                                                Login
                                            </Button>
                                            {/* {!isMobile ? ( */}
                                            {/* <Button
                                                style={{
                                                    cursor: 'pointer',
                                                    width: 150,
                                                }}
                                                type="primary"
                                                onClick={() => {
                                                    this.props.history.push(
                                                        '/plans-and-pricing'
                                                    )
                                                }}
                                            >
                                                Create Account
                                            </Button> */}
                                        </Col>
                                    </Row>
                                ) : (
                                    <AccountMenu
                                        open={this.state.open}
                                        onClose={() =>
                                            this.setState({ open: false })
                                        }
                                        account={this.state.account}
                                    />
                                )}
                            </>
                        )}
                    </>
                </Row>
            </div>
        )
    }
}

export default withRouter(Header)
