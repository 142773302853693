import React from 'react'

export default class Account extends React.Component {
    render() {
        return (
            <>
                <h1>Account</h1>
            </>
        )
    }
}
