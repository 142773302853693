import React, { ReactNode } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Button from '../insightui/Button'
import Header from '../insightui/Header'
import Footer from '../insightui/Footer'
import { css } from 'glamor'
import { Microscope } from '@styled-icons/fluentui-system-regular/Microscope'
import { Skull } from '@styled-icons/remix-line/Skull'
import { Earth } from '@styled-icons/ionicons-outline/Earth'
import { AppBlocking } from '@styled-icons/material/AppBlocking'
import { Cross } from '@styled-icons/fa-solid/Cross'
import { PrayingHands } from '@styled-icons/fa-solid/PrayingHands'
import { CashCoin } from '@styled-icons/bootstrap/CashCoin'
import { Lightbulb } from '@styled-icons/fa-regular/Lightbulb'
import { Building } from '@styled-icons/fa-regular/Building'
import { ArrowDown } from '@styled-icons/feather/ArrowDown'
import { ArrowRight } from '@styled-icons/feather/ArrowRight'
import { GraphUp } from '@styled-icons/bootstrap/GraphUp'
import { ChevronDoubleUp } from '@styled-icons/bootstrap/ChevronDoubleUp'
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort'
import { ChevronDoubleDown } from '@styled-icons/bootstrap/ChevronDoubleDown'
import { ArrowTrending } from '@styled-icons/fluentui-system-regular/ArrowTrending'
import ImpactScoreInfo from '../images/impact-score-update.png'
import { globals } from '../utils/globals'
import { config } from '../models/config'
import { isMobile, isTablet } from 'react-device-detect'
import axios from 'axios'
import { RankedComponent } from '../components/RankedComponent'
import { autoType } from 'd3'
import { RouteComponentProps, withRouter } from 'react-router-dom'

type IconTypes =
    | 'microscope'
    | 'skull'
    | 'world'
    | 'pornography'
    | 'cross'
    | 'prayer'
    | 'investments'
    | 'light'
    | 'building'
    | 'profit'
    | 'arrow-down'
    | 'arrow-right'

enum Views {
    DESKTOP,
    MOBILE,
}

const DEBUG: boolean = false

enum Sizes {
    PADDING = 20,
    STOCK_BOX_HEIGHT = 350,
    ICON = 30,
    VERTICAL_GAP = 20,
    VERTICAL_GAP_MOBILE = 60,
}

const Colors = {
    ORANGE: globals.colors.inspire.orange,
    RED: globals.colors.negative.red,
    BLUE: globals.colors.positive.blue,
    BLUE_BG: globals.colors.positive.bg,
    DARK: globals.colors.inspire.darknavy,
    GREY: globals.colors.inspire.arcpath,
}

const STYLES: any = {
    shared: {
        text: {
            ...globals.typeface.defaultText,
            fontSize: 20,
            fontWeight: 800,
            lineHeight: '30px',
        },
        marginedText: {
            ...globals.typeface.defaultText,
            fontSize: 18,
            fontWeight: 800,
            maxWidth: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        title: {
            ...globals.typeface.defaultText,
            fontSize: 50,
            textAlign: 'center',
        },
    },

    desktop: {
        infoBox: {
            ...globals.typeface.defaultText,
            fontWeight: 0,
            border: '1px solid ' + Colors.BLUE,
            borderLeft: '12px solid ' + Colors.BLUE,
            backgroundColor: Colors.BLUE_BG,
            padding: Sizes.PADDING,
            color: Colors.BLUE,
            fontSize: 40,
            paddingLeft: 40,
        },
    },
    mobile: {
        infoBox: {
            ...globals.typeface.defaultText,
            fontWeight: 0,
            border: '1px solid ' + Colors.BLUE,
            borderLeft: '12px solid ' + Colors.BLUE,
            backgroundColor: Colors.BLUE_BG,
            padding: Sizes.PADDING,
            color: Colors.BLUE,
            fontSize: 25,
            paddingLeft: 40,
        },
    },
}

function renderGap(size?: number) {
    return (
        <div
            style={{ height: size ? size : Sizes.VERTICAL_GAP, width: '100%' }}
        ></div>
    )
}

function renderIcon(name: IconTypes, color: string, size?: number) {
    if (name === 'arrow-down')
        return (
            <>
                <ArrowDown
                    width={size ? size : Sizes.ICON}
                    style={{ color: color }}
                />
            </>
        )
    if (name === 'arrow-right')
        return (
            <>
                <ArrowRight
                    width={size ? size : Sizes.ICON}
                    style={{
                        color: color,
                        marginTop: -2,
                        marginRight: -20,
                    }}
                />
                &nbsp;
            </>
        )
    return <></>
}
function renderIf(isRendered: boolean, node: ReactNode) {
    return globals.util.renderIf(isRendered, node)
}

function renderColoredText(value: string, color: string, noSpace?: boolean) {
    return (
        <div style={{ display: 'inline-block', color: color }}>
            {renderIf(!noSpace, <>&nbsp;</>)}
            {value}
            {renderIf(!noSpace, <>&nbsp;</>)}
        </div>
    )
}

class About extends React.Component<RouteComponentProps> {
    state = {
        view: isMobile ? Views.MOBILE : Views.DESKTOP,
        ready: false,
        ranked: {},
    }

    componentDidMount() {
        this.getRankedTrending()
    }

    getRankedTrending = () => {
        axios.get(`${config.api}/api/ranked`).then((response) => {
            this.setState({
                ranked: response.data,
                ready: true,
            })
        })
    }

    renderStockBox(params?: {
        child?: ReactNode
        marginRight?: number
        marginBottom?: number
    }) {
        const isDesktop: boolean = this.state.view === Views.DESKTOP
        const mr: number = params && params.marginRight ? params.marginRight : 0
        const mb: number =
            params && params.marginBottom ? params.marginBottom : 0
        return <div style={{}}>{this.state.ready ? params?.child : <></>}</div>
    }

    render() {
        const isDesktop: boolean = this.state.view === Views.DESKTOP
        return (
            <div>
                <div
                    {...css({
                        width: '100%',
                        height: 65,
                    })}
                >
                    <Header logo subscriberExempt />
                </div>
                <div
                    style={{
                        height: 'auto',
                        padding: isMobile ? '120px 10px 50px' : '50px 10px',
                    }}
                >
                    <p
                        style={{
                            fontSize: 55,
                            letterSpacing: -2,
                            lineHeight: '55px',
                            fontWeight: 700,
                            textAlign: 'center',
                            width: '85%',
                            margin: '0 auto 30px',
                        }}
                    >
                        What if you could
                        {renderColoredText('instantly', Colors.ORANGE)}
                        find out if your investments
                        {renderColoredText('contradict', Colors.RED)}
                        what you&nbsp;
                        {renderColoredText('value', Colors.BLUE, true)}?
                    </p>
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            marginTop: 0,
                        }}
                    >
                        {renderIcon('arrow-down', Colors.DARK, 70)}
                    </div>

                    <p
                        style={{
                            width: '60%',
                            margin: '20px auto 0',
                            fontSize: 20,
                            lineHeight: '30px',
                        }}
                    >
                        Does the pharmaceutical company in your portfolio
                        produce abortifacients&nbsp; that get distributed to
                        millions of people around the world? Or perhaps your
                        favorite streaming network you invest in distributes
                        pornographic films.
                    </p>

                    <p
                        style={{
                            ...globals.typeface.defaultText,
                            fontSize: 40,
                            color: Colors.ORANGE,
                            width: '70%',
                            margin: '50px auto',
                            letterSpacing: -2,
                            lineHeight: '45px',
                            fontWeight: 700,
                            textAlign: 'center',
                            textShadow: '6px 5px 0 #fbefe5',
                        }}
                    >
                        Don't be unaware. Your investments should reinforce your
                        values, not contradict them.
                    </p>

                    <p
                        style={{
                            width: '60%',
                            margin: '0 auto',
                            fontSize: 20,
                            lineHeight: '30px',
                        }}
                    >
                        As Christians we are called to do all things for the
                        glory of God, and as investors we have a unique
                        opportunity to be "salt and light" in the companies we
                        own and profit from.
                    </p>

                    <Row
                        style={{
                            marginTop: 30,
                            marginBottom: 30,
                        }}
                    >
                        <Col
                            lg={5}
                            md={12}
                            style={{
                                alignSelf: 'center',
                            }}
                        >
                            <div
                                style={{
                                    ...STYLES.shared.text,
                                    fontWeight: 0,
                                }}
                            >
                                <strong>
                                    The Inspire Impact Score is an objective
                                    scoring model that considers the negative
                                    and positive impact a given company has on
                                    their communities, customers, workplace, and
                                    the world.
                                </strong>
                                This qualitative data is evaluated by the
                                rigorous, rules-based Impact Scoring model which
                                returns the final Impact Score, a value from
                                -100 to 100 where 100 is considered a highly
                                Biblically aligned company, and a company with a
                                score of -100 lies contrary to Biblical
                                principles. These factors contribute to the
                                Inspire Impact score being the most
                                comprehensive investing screening tool in the
                                market. A company that has a high Impact Score
                                typically is one of integrity, a blessing to
                                their environment, a blessing to society, and a
                                blessing to the world.
                            </div>
                        </Col>

                        <Col lg={7} md={12}>
                            <img
                                src={ImpactScoreInfo}
                                width={'100%'}
                                alt="Upload"
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 100 }}>
                        <Col lg={12} xl={7}>
                            <Row>
                                <Col md={12} lg={6}>
                                    {this.renderStockBox({
                                        marginRight: Sizes.PADDING,
                                        marginBottom: Sizes.PADDING,
                                        child: (
                                            <RankedComponent
                                                title={
                                                    <span>Inspire Funds</span>
                                                }
                                                icon={
                                                    <ChevronDoubleUp
                                                        width={30}
                                                    />
                                                }
                                                items={
                                                    this.state.ranked[
                                                        'inspireFunds'
                                                    ]
                                                }
                                                disableAll
                                            />
                                        ),
                                    })}
                                </Col>
                                <Col md={12} lg={6}>
                                    {this.renderStockBox({
                                        marginBottom: Sizes.PADDING,
                                        child: (
                                            <RankedComponent
                                                title={
                                                    <span>
                                                        Trending Issue: Abortion
                                                        Travel
                                                    </span>
                                                }
                                                icon={
                                                    <ChevronDoubleUp
                                                        width={30}
                                                    />
                                                }
                                                items={
                                                    this.state.ranked[
                                                        'trendingIssue'
                                                    ]
                                                }
                                                disableAll
                                            />
                                        ),
                                    })}
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} lg={6}>
                                    {this.renderStockBox({
                                        marginRight: Sizes.PADDING,
                                        marginBottom: isDesktop
                                            ? 0
                                            : Sizes.PADDING,
                                        child: (
                                            <RankedComponent
                                                title={
                                                    <span>
                                                        Top Scoring Stocks
                                                    </span>
                                                }
                                                icon={
                                                    <ChevronDoubleDown
                                                        width={30}
                                                    />
                                                }
                                                items={
                                                    this.state.ranked[
                                                        'topStocks'
                                                    ]
                                                }
                                                disableAll
                                            />
                                        ),
                                    })}
                                </Col>
                                <Col md={12} lg={6}>
                                    {this.renderStockBox({
                                        child: (
                                            <RankedComponent
                                                title={
                                                    <span>
                                                        Worst Scoring Stocks
                                                    </span>
                                                }
                                                icon={
                                                    <ChevronDoubleDown
                                                        width={30}
                                                    />
                                                }
                                                items={
                                                    this.state.ranked[
                                                        'worstStocks'
                                                    ]
                                                }
                                                disableAll
                                            />
                                        ),
                                    })}
                                </Col>
                            </Row>
                        </Col>

                        <Col
                            lg={12}
                            xl={5}
                            style={{
                                position: 'relative',
                                marginBottom: 50,
                                alignSelf: 'center',
                            }}
                        >
                            <div
                                style={{
                                    ...STYLES.shared.text,
                                    fontWeight: 0,
                                }}
                            >
                                All you need to do is type your ticker symbols
                                or the name of a company in the search bar and
                                you instantly get all the data related to the
                                biblical values, both good and bad, that company
                                is involved in so that you can make an investment decision
                                that is both “wise as a serpent and innocent as
                                a dove” (Matthew 10:16).
                            </div>
                        </Col>
                    </Row>

                    <p
                        style={{
                            fontSize: isMobile ? 40 : 55,
                            letterSpacing: -2,
                            lineHeight: isMobile ? '40px' : '55px',
                            fontWeight: 700,
                            textAlign: 'left',
                            width: isMobile && !isTablet ? '100%' : '75%',
                            marginBottom: 30,
                        }}
                    >
                        Are you ready to
                        {renderColoredText('discover the truth', Colors.ORANGE)}
                        about your
                        {renderColoredText('investments', Colors.BLUE)}?
                    </p>
                    {renderGap(isDesktop ? -20 : -20)}
                    <div
                        style={{
                            ...STYLES.shared.text,
                            fontWeight: 0,
                            width: isMobile ? '100%' : '60%',
                        }}
                    >
                        Ignorance is certainly not bliss, and we encourage you
                        to take that step and see for yourself what is going on
                        behind closed doors in your portfolio.
                        {renderGap(isDesktop ? 40 : -20)}
                        <div
                            style={{
                                width: '100%',
                                height: 'auto',
                            }}
                        >
                            <Button
                                type="primary"
                                iconPosition={'right'}
                                icon={renderIcon('arrow-right', 'white')}
                                style={{
                                    width: 'auto',
                                    fontSize: 20,
                                    padding: '12px 30px',
                                    backgroundColor: 'black',
                                    borderColor: 'black',
                                }}
                                onClick={() => {
                                    this.props.history.push(`/login`)
                                }}
                            >
                                Get Started
                            </Button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default withRouter(About)
