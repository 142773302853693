import React from 'react'
import Header from '../insightui/Header'
import { Row, Col } from 'react-flexbox-grid'
import { isMobile } from 'react-device-detect'

const faqs = [
    {
        header: 'What is Inspire Insight?',
        content: (
            <span>
                inspireinsight.com is the world’s most comprehensive,
                faith-based investment screening tool that gives you instant
                access to biblical values data on over 23,000 stocks, mutual
                funds and ETFs so you can invest for the glory of God.
            </span>
        ),
    },
    {
        header: 'What is BRI?',
        content: (
            <span>
                BRI is an acronym that stands for Biblically Responsible
                Investing. BRI is the practice of avoiding investing in
                companies that promote or profit from activities that are
                opposed to historical Christian values.
            </span>
        ),
    },
    // {
    //     header: 'What is ESG investing?',
    //     content: (
    //         <span>
    //             ESG stands for Environmental, Social, and Governance — which is
    //             an investment strategy that considers positive social and
    //             environmental impact as well as financial returns. By
    //             integrating ESG factors into valuing a company, you can evaluate
    //             risks and opportunities that go beyond fundamental metrics.
    //             Insert video link (ask me which one) that would open in a new
    //             tab
    //         </span>
    //     ),
    // },
    // {
    //     header: 'Is there a difference between ESG and BRI investments?',
    //     content: (
    //         <span>
    //             Yes. ESG funds do not always take into account convictions to
    //             many Christians as there has been a largely universal tilt
    //             toward progressive, liberal values across the rapidly expanding
    //             Environmental, Social, and Governance (ESG) investing landscape.
    //             For example, companies involved in abortion, alcohol, gambling,
    //             and pornography may meet ESG screening criteria, but would not
    //             necessarily be the type of company a Christian would want to be
    //             involved with. BRI is an extension of ESG investing that
    //             specifically considers issues of interest to Christians through
    //             a biblical lens.
    //         </span>
    //     ),
    // },
    {
        header: 'How do I update my profile information?',
        content: (
            <span>
                To update your profile information, log in and click on Account
                Settings in the drop-down menu in the upper-right of the screen
                and select Update Profile
            </span>
        ),
    },
    {
        header: 'I want to export/download data',
        content: (
            <span>
                Exporting/downloading data is a Premium account feature. First,
                verify you have the correct plan (which is listed under your
                name in the Account drop-down menu), then look for the export
                icon located on the top-right of the data set you want to
                export.{' '}
            </span>
        ),
    },
    {
        header: 'What is the Inspire Impact Score?',
        content: (
            <span>
                The Inspire Impact Score is a faith-based scoring system that
                analyzes issues from a biblical perspective and distills that
                analysis into an easy-to-understand numerical score that ranges
                from -100 to +100. The Inspire Impact Score reflects a
                rules-based, scientifically rigorous methodology of faith-based
                analysis which creates a level of consistency and reliability of
                results necessary for making well-informed, quantitatively
                sound, biblically responsible investment decisions These factors
                contribute to the Inspire Impact score being the most
                comprehensive screening tool in the market. A company that has a
                high Impact Score typically is one of integrity, a blessing to
                their environment, a blessing to society, and a blessing to the
                world. For a more detailed explanation of the methodology behind
                the Inspire Impact Score, you can read our Whitepaper here.
            </span>
        ),
    },
    {
        header: 'How do you calculate impact scores?',
        content: (
            <span>
                The positive and negative scoring operations are mutually
                exclusive. If there are negative screening criteria associated
                with a given financial instrument, a negative score will be
                produced, returning a negative value regardless of the positive
                score. If there are no negative screening criteria, then the
                positive screening is evaluated, returning a positive score. The
                Impact score is designed to provide the necessary information to
                an investor so that they can make a biblically informed decision
                on whether or not a company is investable. • Want to learn more?
                Read our Inspire Impact Score whitepaper for detailed analysis
                in our scoring methodology here: insert link.
            </span>
        ),
    },
    {
        header: 'What themes do you screen for?',
        content: (
            <span>
                We screen for 51 categories across 9 themes listed below with
                include the five Sustainability Accounting Standards Board
                (SASB) categories: environment, social capital, human capital,
                business model & innovation, and leadership & governance (you
                can learn more about the SASB categories at
                materiality.sasb.org): 1. Addiction 2. Armaments (weapons) 3.
                Bioethics 4. Business Ethics 5. Business Model & Innovation 6.
                Environment 7. Human Capital 8. Leadership & Governance 9.
                Social Capital Want to see all 51 categories? Click here Why is
                there no screening data for some companies? Companies are not
                currently required to release information used for reporting. We
                report all publicly available information gathered through
                numerous data management agents. Companies that do not have any
                data will generate a “n/a” result.
            </span>
        ),
    },
    {
        header: 'Why is there no screening data for some companies?',
        content: (
            <span>
                Companies are not currently required to release information used
                for reporting. We report all publicly available information
                gathered through numerous data management agents. Companies that
                do not have any data will generate a “n/a” result.
            </span>
        ),
    },
    {
        header: 'I’m unable to login. What should I do?',
        content: (
            <span>
                If you know your username, try resetting your password by
                clicking on Forgot Password on the sign-in page and follow the
                instructions. The reset link is valid for 24 hours. Then log
                back in using your new password. If you do not receive an email
                with a link to reset your password in your inbox or spam
                folders, then please contact technical support that can help
                retrieve your account information.{' '}
            </span>
        ),
    },
    {
        header: 'I forgot my password. How do I reset it?',
        content: (
            <span>
                To reset your password, either go to Sign in, then Forgot
                password or go to Account Settings, then Password.
            </span>
        ),
    },
    {
        header: 'How do I update my credit card information?',
        content: (
            <span>
                To review or update your credit card on record, Click Account
                Settings, Billing, Update Credit Card
            </span>
        ),
    },
    {
        header: 'How do I cancel my Premium account membership?',
        content: (
            <span>
                You can cancel your Premium membership by visiting Account
                Settings, Plans, and clicking on Cancel. Follow onscreen
                instructions to complete the process
            </span>
        ),
    },
    {
        header: 'What’s the difference between Insight Basic and Insight Premium? ',
        content: (
            <span>
                The core difference between a Basic & Premium membership is
                access to advanced features such as portfolio comparisons,
                exporting data and pre-built screening profiles. Learn more
                here.
            </span>
        ),
    },
    {
        header: 'I can’t update my payment method.',
        content: (
            <span>
                Sometimes, a new payment method cannot be verified. We cannot
                skip this process as it helps to protect your account from
                fraud. Should you get any error when trying to update your
                payment method, try again paying attention to the ZIP code and
                CVV you fill in. They must correspond to the ones associated
                with the cardholder's credit card account.
            </span>
        ),
    },
    {
        header: 'How do I request a new feature?',
        content: (
            <span>
                Thank you for helping make our platform better! You can submit
                your feature request and ideas to inspire@inspireinvesting.com.
                While we greatly value your feedback, we are unable to
                accommodate all requests and will evaluate each inquiry for the
                greatest impact on our user base and mission. Thank you!
            </span>
        ),
    },
]

export default class FAQ extends React.Component {
    render() {
        return (
            <>
                <Header logo subscriberExempt />
                <div style={{ paddingTop: 140 }}>
                    <Row center="lg">
                        <Col style={{ paddingLeft: isMobile ? 30 : 0 }}>
                            <h1 style={{ fontSize: 40 }}>
                                Frequenty Asked Questions
                            </h1>
                        </Col>
                    </Row>
                    {faqs.map((faq) => (
                        <Row center="lg" style={{ marginTop: 30 }}>
                            <Col
                                style={{
                                    backgroundColor: 'white',
                                    padding: 30,
                                    textAlign: 'left',
                                }}
                                lg={4}
                                xs={12}
                            >
                                <Row>
                                    <h3>{faq.header}</h3>
                                </Row>
                                <Row style={{ textAlign: 'left' }}>
                                    <span style={{ fontWeight: 200 }}>
                                        {faq.content}
                                    </span>
                                </Row>
                            </Col>
                        </Row>
                    ))}
                </div>
            </>
        )
    }
}
