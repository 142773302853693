import { Component } from 'react'
import { globals } from '../utils/globals'

interface BarProps {
    speedMS?: number
    steps?: number
    style?: object
    withAnimation?: boolean
    percent?: number
    negative?: boolean
    radius?: number
}

export default class Bar extends Component<BarProps> {
    state = {
        toggle: false,
        percent: 0,
    }

    getBarStyles() {
        const percent: number = Math.abs(this.props.percent as number)
        const animate: boolean =
            this.props.withAnimation === false ? false : true
        const isNegative: boolean = this.props.negative ? true : false
        const radius: number = this.props.radius ? this.props.radius : 0
        return {
            height: '100%',
            border: isNegative
                ? '1px solid ' + globals.colors.negative.red
                : '1px solid ' + globals.colors.positive.blue,
            backgroundColor: isNegative
                ? globals.colors.negative.red
                : globals.colors.positive.blue,
            marginTop: -1,
            marginLeft: 0,
            width: animate ? this.state.percent + '%' : percent + '%',
            borderRadius: radius,
        }
    }

    getContainerStyles() {
        const isNegative: boolean = this.props.negative ? true : false
        const radius: number = this.props.radius ? this.props.radius : 0
        return {
            width: '100%',
            height: 18,
            border: isNegative
                ? '1px solid ' + globals.colors.negative.red
                : '1px solid ' + globals.colors.positive.blue,
            backgroundColor: isNegative
                ? globals.colors.negative.bg
                : globals.colors.positive.bg,
            borderRadius: radius,
            ...this.props.style,
        }
    }

    componentDidMount() {
        const defaultSteps = 0
        const percent: number = Math.abs(this.props.percent as number)
        const steps: number = percent ? percent : defaultSteps
        const speedMS: number = 10
        const speed: number = 3000
        const speedFactor = (1000 / (speed + 1)) * 3
        const newIntervalId = setInterval(() => {
            if (this.state.percent >= steps) {
                clearInterval(newIntervalId)
                return this.setState({
                    percent: steps,
                })
            }
            // fixes vibrating behavior at end of animation
            const nooverflow: boolean =
                this.state.percent + speedFactor <= steps
            const nondecreasing: boolean =
                this.state.percent < this.state.percent + speedFactor
            if (nooverflow && nondecreasing)
                this.setState({
                    percent: this.state.percent + speedFactor,
                })
        }, speedMS)
    }

    render() {
        return (
            <>
                <div style={{ ...this.getContainerStyles() }}>
                    <div style={{ ...this.getBarStyles() }}></div>
                </div>
            </>
        )
    }
}
