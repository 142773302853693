import React from 'react'
import Modal from '../Modal'
import { Row } from 'react-flexbox-grid'
import { IPortfolio } from '../../models/api'
import { Check } from '@styled-icons/feather/Check'
import Button from '../../insightui/Button'
import axios from 'axios'
import { config } from '../../models/config'
import { store } from '../../store'

interface AddToPortfolioProps {
    isActive: boolean
    portfolios?: Array<IPortfolio>
    addToPortfolio: (portfolios: number[], cb?: () => void) => void
    onClose: () => any
    refreshPortfolios?: () => void
}

interface AddToPortfolioState {
    selectedPortfolios: Array<number>
    portfoliosList: Array<any>
    ready: boolean
}

export default class AddToPortfolio extends React.Component<AddToPortfolioProps> {
    state: AddToPortfolioState = {
        selectedPortfolios: [],
        portfoliosList: [],
        ready: false,
    }

    componentDidMount(): void {
        if (!this.props.portfolios) {
            this.fetchPortfolios()
        } else {
            this.setState({ portfolioList: this.props.portfolios, ready: true })
        }
    }

    fetchPortfolios = async () => {
        axios
            .get(`${config.api}/api/portfolios`, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response: any) => {
                let data = response.data.portfolios
                let newData: any = []
                data.forEach((item: any) => {
                    item['checked'] = false
                    newData.push(item)
                })

                this.setState({ portfoliosList: newData, ready: true })
            })
    }

    setCheck = (portfolioId: number) => {
        if (this.state.selectedPortfolios.includes(portfolioId)) {
            //uncheck
            let newPortfolios = this.state.selectedPortfolios
            const index = newPortfolios.indexOf(portfolioId)
            newPortfolios.splice(index, 1)
            this.setState({ selectedPortfolios: newPortfolios })
        } else {
            //check
            this.setState({
                selectedPortfolios:
                    this.state.selectedPortfolios.concat(portfolioId),
            })
        }
    }

    render() {
        const { isActive, addToPortfolio, onClose } = this.props
        const { portfoliosList, ready } = this.state

        return (
            <Modal isActive={isActive}>
                <Row>
                    <p
                        style={{
                            fontWeight: 500,
                            fontSize: 16,
                            letterSpacing: 2,
                            paddingLeft: 8,
                        }}
                    >
                        ADD TO PORTFOLIO
                    </p>
                </Row>
                {ready ? (
                    <>
                        <div
                            style={{
                                height: 200,
                                overflowY: 'scroll',
                                padding: 20,
                            }}
                        >
                            {portfoliosList.map((item: Record<string, any>) => (
                                <Row style={{ marginBottom: 7 }}>
                                    <div
                                        style={{
                                            width: 20,
                                            height: 20,
                                            outline: '0.5px solid black',
                                            display: 'inline',
                                            marginRight: 10,
                                        }}
                                        onClick={() => this.setCheck(item.id)}
                                    >
                                        {this.state.selectedPortfolios.includes(
                                            item.id
                                        ) ? (
                                            <Check width={20} />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    {item.name}
                                </Row>
                            ))}
                        </div>
                        <Row style={{ paddingTop: 20 }}>
                            <Button
                                type="outlined"
                                style={{ width: 110 }}
                                onClick={() => {
                                    onClose()
                                    this.setState({ selectedPortfolios: [] })
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                style={{ width: 120, marginLeft: 30 }}
                                onClick={() => {
                                    addToPortfolio(
                                        this.state.selectedPortfolios,
                                        this.props.refreshPortfolios
                                    )
                                }}
                            >
                                Add
                            </Button>
                        </Row>
                    </>
                ) : (
                    <>Loading...</>
                )}
            </Modal>
        )
    }
}
