import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Button from '../insightui/Button'

import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort'
import ImpactScore from '../insightui/ImpactScore'
import { withRouter } from 'react-router-dom'

class RankedComponentClass extends React.Component {
    redirect = (item) => {
        if (
            item.exchange === 'UNITED_STATES_OF_AMERICA' ||
            item.exchange === undefined
        ) {
            this.props.history.push(`/${item.ticker}`)
        } else {
            this.props.history.push(`/${item.ticker}/${item.exchange}`)
        }
        // this.props.history.push(`/${item.ticker}`)
    }

    render() {
        const { title, icon, items, frontPage, onClick, disableAll } =
            this.props
        return (
            <Col style={{ paddingRight: 10 }}>
                {frontPage ? title : <></>}
                <Row style={{ paddingLeft: 20 }}>
                    {!frontPage ? (
                        <Col
                            style={{
                                paddingTop: 15,
                                marginRight: 10,
                            }}
                        >
                            {icon}
                        </Col>
                    ) : (
                        <></>
                    )}
                    {!frontPage ? (
                        <>
                            <Col>
                                <h3 style={{ fontWeight: 350 }}>{title}</h3>
                            </Col>
                            <Col xs />
                            {!disableAll ? (
                                <Col
                                    style={{
                                        paddingTop: 18,
                                        marginLeft: 15,
                                        paddingRight: 15,
                                    }}
                                >
                                    <Button type="mini" onClick={onClick}>
                                        All <ArrowRightShort width={20} />
                                    </Button>
                                </Col>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </Row>
                {items.map((item) => (
                    <Row
                        style={{
                            cursor: 'pointer',
                            backgroundColor: 'white',
                            margin: 6,
                            paddingLeft: 7,
                        }}
                        onClick={() => {
                            this.redirect(item)
                        }}
                    >
                        <Col style={{ paddingRight: 30 }} xs={2}>
                            <p
                                style={{
                                    fontWeight: 700,
                                    fontSize: 14,
                                    fontFamily: 'URW Geometric Cond',
                                    marginBottom: 0,
                                }}
                            >
                                {item.ticker}
                            </p>
                        </Col>
                        <Col xs={6}>
                            <p
                                style={{
                                    fontSize: 15,
                                    fontFamily: 'URW Geometric Cond',
                                    marginBottom: 0,
                                }}
                            >
                                {item.name.length > 20
                                    ? item.name.slice(0, 20) + '...'
                                    : item.name}
                            </p>
                        </Col>
                        <Col xs />
                        <Col style={{ paddingTop: 9 }}>
                            <ImpactScore
                                score={item.impactScore}
                                withAnimation={false}
                                type="small"
                                match={item.impactScore < 0}
                            />
                        </Col>
                    </Row>
                ))}
            </Col>
        )
    }
}

const RankedComponent = withRouter(RankedComponentClass)
export { RankedComponent }
