import { Component } from 'react'
import { globals } from '../utils/globals'
import { css } from 'glamor'

export type ButtonType =
    | 'primary'
    | 'outlined'
    | 'ghost'
    | 'elastic'
    | 'search'
    | 'mini'
    | 'outlinedGrey'
    | 'tag'
    | 'inspireBlue'
    | 'inspireOrange'
    | 'whiteShadow'
    | 'searchMini'

type iconPositionType = 'right' | 'left'

interface ButtonProps {
    // onClickOnDisabled - when onclick needs to be called even when the button is disabled (pseudo-radio functionality)
    type?: ButtonType
    disabled?: boolean
    width?: number | string
    height?: number
    icon?: any
    stateIndex?: number
    style?: Record<string, any>
    iconPosition?: iconPositionType
    onClick?: () => void
    onClickOnDisabled?: () => void
}

export default class Button extends Component<ButtonProps> {
    state = {
        stateindex: 0,
        disabled: false,
    }

    tagTypeDidMount(props: Record<string, any>) {
        if (props && props.disabled) this.setState({ disabled: props.disabled })
    }

    componentDidMount() {
        const type: string = this.props.type ? this.props.type : ''

        switch (type) {
            case 'tag':
                return this.tagTypeDidMount(this.props)
            default:
                return
        }
    }

    getCommonStyles() {
        const style: Record<string, any> = {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            ...this.props.style,
        }
        return style
    }

    getGhostStyles() {
        const normal: Record<string, any> = {
            padding: '0.9em 1.7em',
            lineHeight: '1em',
            backgroundColor: 'transparent',
            color: '#0b192c',
            transition: '250ms',
            borderColor: 'transparent',
        }

        const onHover: Record<string, any> = {
            ':hover': {
                color: 'silver',
            },
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getWhiteShadow() {
        const normal: Record<string, any> = {
            paddingTop: '0.9em',
            paddingBottom: '0.9em',
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: 'white',
            color: globals.colors.inspire.darknavy,
            transition: '200ms',
            width: this.props.width
                ? this.props.width
                : globals.sizes.button.default.width,
            height: this.props.height ? this.props.height : 60,
            lineHeight: '1em',
            fontWeight: 800,
            fontSize: 14,
            fontFamily: 'URW Geometric Ext',
            cursor: 'pointer',
            border: '1px solid rgba(33,33,33,.08)',
        }

        const onHover: Record<string, any> = {
            ':hover': {
                boxShadow: '0 0 12px rgba(33,33,33,.08)',
            },
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getMiniStyles() {
        const normal: Record<string, any> = {
            ...globals.typeface.miniButtonText,
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: 4,
            backgroundColor: '#eff7fb',
            color: '#0b192c',
            transition: '250ms',
            border: 'none',
            lineHeight: '1em',
            width: globals.sizes.button.mini.width,
        }

        const onHover: Record<string, any> = {
            ':hover': {
                backgroundColor: '#2c8aaf',
                color: 'white',
            },
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getSearchMini() {
        const normal: Record<string, any> = {
            ...globals.typeface.miniButtonText,
            padding: '3px 5px 2px',
            fontSize: '12px',
            borderRadius: 0,
            backgroundColor: '#0b192c',
            color: 'white',
            transition: '250ms',
            border: 'none',
            lineHeight: '1em',
            width: 'auto',
        }

        const onHover: Record<string, any> = {
            ':hover': {
                backgroundColor: '#2c8aaf',
                color: 'white',
            },
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getTagStyles() {
        const source: any = this.props.onClickOnDisabled
            ? this.props
            : this.state
        const disabled: boolean = source.disabled ? source.disabled : false

        const stateindex: number = this.state.stateindex
        const mouseDown: boolean = stateindex === 1
        const normal: Record<string, any> = {
            marginTop: '0.5em',
            marginRight: '0.5em',
            marginBottom: '0.5em',
            padding: '0.3em 0.9em',
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
            borderLeft: '1px solid rgba(0, 0, 0, 0.05)',
            borderBottom: '1px solid transparent',
            borderRight: '1px solid transparent',
            borderRadius: '4px',
            backgroundColor: '#2c8aaf',
            boxShadow: '1px 1px 5px -3px rgba(0, 0, 0, 0.9)',
            WebkitTransition: 'all 300ms ease',
            transition: 'all 300ms ease',
            fontFamily: "'URW Geometric Cond', sans-serif",
            color: 'white',
            fontWeight: 600,
            textAlign: 'center',
            textDecoration: 'none',
            boxSizing: 'border-box',
            fontSize: 14,
            height: 32,
        }

        const onHover: Record<string, any> = {
            ':mousedown': {
                boxShadow: '-1px -1px 5px -3px rgba(0, 0, 0, 0.9)',
            },
        }

        const onMouseDown: Record<string, any> =
            mouseDown && !disabled
                ? {
                      backgroundColor: 'hsla(200, 23.81%, 91.25%, 1)',
                      boxShadow: '-3px -3px 4px -3px rgba(0, 0, 0, 0.2)',
                      border: '1px solid rgba(0, 0, 0, 0.05)',
                  }
                : {}
        const onDisable: Record<string, any> = disabled
            ? {
                  backgroundColor: 'hsla(196.947, 26.83%, 89.98%, 1)',
                  border: '1px solid transparent',
                  color: 'black',
              }
            : {}

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onMouseDown,
            ...onDisable,
            ...onResolution_min300,
        })
    }

    getElasticStyles() {
        const normal: Record<string, any> = {
            padding: '0.9em 1.7em',
            lineHeight: '1em',
            backgroundColor: 'white',
            color: '#0b192c',
            transition: '250ms',
            border: 'none',
            width: this.props.width ? this.props.width : '100%',
            fontWeight: 800,
            fontSize: 16,
            fontFamily: 'URW Geometric Ext',
        }

        const onHover: Record<string, any> = {
            ':hover': {
                backgroundColor: 'whitesmoke',
            },
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getInspireBlueStyles() {
        const isEnabled = !this.props.disabled

        const normal: Record<string, any> = {
            paddingTop: '0.9em',
            paddingBottom: '0.9em',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 4,
            backgroundColor: globals.colors.positive.blue,
            color: 'white',
            transition: '250ms',
            border: `1px solid ${globals.colors.positive.blue}`,
            width: this.props.width
                ? this.props.width
                : globals.sizes.button.default.width,
            lineHeight: '1em',
            fontWeight: 800,
            fontSize: 16,
            fontFamily: 'URW Geometric Ext',
            cursor: isEnabled ? 'pointer' : 'default',
            opacity: isEnabled ? 1 : 0.5,
        }

        const onHover: Record<string, any> = {
            ':hover': isEnabled
                ? {
                      backgroundColor: globals.colors.inspire.darknavy,
                      border: `1px solid ${globals.colors.inspire.darknavy}`,
                      color: 'white',
                  }
                : {},
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getInspireOrangeStyles() {
        const isEnabled = !this.props.disabled

        const normal: Record<string, any> = {
            paddingTop: '0.9em',
            paddingBottom: '0.9em',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 4,
            backgroundColor: globals.colors.inspire.orange,
            color: 'white',
            transition: '250ms',
            border: `1px solid ${globals.colors.inspire.orange}`,
            width: this.props.width
                ? this.props.width
                : globals.sizes.button.default.width,
            lineHeight: '1em',
            fontWeight: 800,
            fontSize: 16,
            fontFamily: 'URW Geometric Ext',
            cursor: isEnabled ? 'pointer' : 'default',
            opacity: isEnabled ? 1 : 0.5,
        }

        const onHover: Record<string, any> = {
            ':hover': isEnabled
                ? {
                      backgroundColor: globals.colors.inspire.darknavy,
                      border: `1px solid ${globals.colors.inspire.darknavy}`,
                      color: 'white',
                  }
                : {},
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getPrimaryStyles() {
        const normal: Record<string, any> = {
            paddingTop: '0.9em',
            paddingBottom: '0.9em',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 4,
            backgroundColor: '#2c8aae',
            color: 'white',
            transition: '250ms',
            border: '1px solid #2c8aae',
            width: globals.sizes.button.default.width,
            lineHeight: '1em',
            fontWeight: 800,
            fontSize: 16,
            fontFamily: 'URW Geometric Ext',
            cursor: 'pointer',
        }

        const onHover: Record<string, any> = {
            ':hover': {
                backgroundColor: '#0b192b',
                border: '1px solid #0b192b',
                color: 'white',
            },
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getOutlineGreyStyles() {
        const normal: Record<string, any> = {
            paddingTop: '0.9em',
            paddingBottom: '0.9em',
            borderRadius: 4,
            backgroundColor: 'white',
            color: '#0b192c',
            transition: '250ms',
            border: '1px solid #0b192c',
            width: this.props.width
                ? this.props.width
                : globals.sizes.button.default.width,
            fontWeight: 800,
            fontSize: 16,
            fontFamily: 'URW Geometric Ext',
        }

        const onHover: Record<string, any> = {
            ':hover': {
                backgroundColor: 'white',
                border: '1px solid silver',
                color: 'silver',
            },
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getOutlineStyles() {
        const paddingRight: number =
            this.props.icon && this.props.iconPosition !== 'right' ? 20 : 0

        const normal: Record<string, any> = {
            paddingTop: '0.9em',
            paddingBottom: '0.9em',
            paddingRight: paddingRight,
            backgroundColor: 'white',
            color: '#0b192c',
            transition: '250ms',
            boxShadow: 'rgb(11 25 44 / 25%) 1px 1px 6px -3px',
            border: 'none',
            width: globals.sizes.button.default.width,
            fontWeight: 800,
            fontSize: 16,
            fontFamily: 'URW Geometric Ext',
        }

        const onHover: Record<string, any> = {
            ':hover': {
                backgroundColor: '#0b192c',
                color: 'white',
            },
        }

        const onResolution_min300: Record<string, any> = {
            '@media(min-width: 300px)': {},
        }

        return css({
            ...normal,
            ...onHover,
            ...onResolution_min300,
        })
    }

    getGlamorStyles(type: any) {
        const name: string = type as string
        switch (name) {
            case 'outlined':
                return this.getOutlineStyles()
            case 'primary':
                return this.getPrimaryStyles()
            case 'inspireBlue':
                return this.getInspireBlueStyles()
            case 'inspireOrange':
                return this.getInspireOrangeStyles()
            case 'outlinedGrey':
                return this.getOutlineGreyStyles()
            case 'elastic':
                return this.getElasticStyles()
            case 'mini':
                return this.getMiniStyles()
            case 'ghost':
                return this.getGhostStyles()
            case 'tag':
                return this.getTagStyles()
            case 'whiteShadow':
                return this.getWhiteShadow()
            case 'searchMini':
                return this.getSearchMini()
            default:
                return this.getPrimaryStyles()
        }
    }

    getIconIf(isActive: boolean, icon: any) {
        if (isActive)
            return (
                <div
                    style={{
                        display: 'inline-block',
                        marginLeft: 10,
                        marginRight: 10,
                    }}
                >
                    {icon}
                </div>
            )
        return <></>
    }

    getStyleIf(isActive: boolean) {
        let styles: Record<string, any> = {}
        if (isActive)
            styles = {
                height: 50,
                paddingTop: 0,
                paddingBottom: 0,
            }
        return styles
    }

    getStylesText() {
        const styles: Record<string, any> = {
            display: 'inline-block',
            width: 'auto',
            textAlign: 'center',
        }
        return styles
    }

    render() {
        const { icon } = this.props
        const onClick: any =
            this.props.onClick && !this.props.disabled
                ? this.props.onClick
                : () => {}
        const onClickOnDisabled: any = this.props.onClickOnDisabled
            ? this.props.onClickOnDisabled
            : () => {}
        const onClickExtended: any = function (e: any) {
            onClick(e)
            onClickOnDisabled(e)
        }

        const isRight: boolean = icon && this.props.iconPosition === 'right'
        const isLeft: boolean = icon && !isRight

        const scope: any = this
        return (
            <button
                {...this.getGlamorStyles(this.props.type)}
                onClick={onClickExtended}
                onMouseDown={function () {
                    scope.setState({
                        stateindex: 1,
                        disabled: !scope.state.disabled,
                    })
                }}
                onMouseUp={function () {
                    scope.setState({ stateindex: 0 })
                }}
                style={{
                    ...this.getCommonStyles(),
                    ...this.getStyleIf(isRight || isLeft),
                }}
            >
                {this.getIconIf(isLeft, icon)}
                <div style={this.getStylesText()}>{this.props.children}</div>
                {this.getIconIf(isRight, icon)}
            </button>
        )
    }
}
