import { Component } from 'react'
import { globals } from '../utils/globals'
import CheckboxCustom, { CheckboxState } from '../insightui/CheckboxCustom'

type DisclaimerTypes = 'default'

const DEBUG: boolean = false

export default class Disclaimer extends Component<{
    type?: DisclaimerTypes
    width?: number | string
    onChange?: (checkboxState: number) => void
}> {
    state = {
        type: this.props.type ? this.props.type : 'default',
        validated: false,
    }

    validate = (stateindex: number) => {
        const validated: boolean =
            stateindex === CheckboxState.CHECKED ? true : false
        this.setState({
            validated: validated,
        })
    }

    render() {
        const h: number = 58.7

        return (
            <div
                style={{
                    ...globals.typeface.disclaimer,
                    marginTop: 20,
                    height: h,
                    paddingLeft: 10,
                    backgroundColor: '#e8f1f7',
                    borderRadius: 5,
                    display: 'inline-block',
                }}
            >
                <div
                    style={{
                        verticalAlign: 'top',
                        display: 'inline-block',
                        width: 20,
                        height: h,
                        backgroundColor: DEBUG ? 'red' : 'transparent',
                    }}
                >
                    <div
                        style={{
                            display: 'table-cell',
                            verticalAlign: 'top',
                            paddingTop: 15,
                            width: 'auto',
                            height: h,
                            backgroundColor: DEBUG ? 'lime' : 'transparent',
                        }}
                    >
                        <CheckboxCustom
                            setToStateIndex={this.validate}
                            isChecked={this.state.validated}
                            onChange={
                                this.props.onChange
                                    ? this.props.onChange
                                    : () => {}
                            }
                        />
                    </div>
                </div>
                <div
                    style={{
                        paddingTop: 0,
                        marginTop: 0,
                        verticalAlign: 'top',
                        display: 'inline-block',
                        width: `calc(100% - ${26}px)`,
                        height: h,
                        backgroundColor: DEBUG ? 'blue' : 'transparent',
                    }}
                >
                    <div
                        style={{
                            display: 'table-cell',
                            width: '100%',
                            height: h,
                            verticalAlign: 'middle',
                            paddingRight: 5,
                            paddingLeft: 5,
                        }}
                    >
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}
