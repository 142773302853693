import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import Button from '../../insightui/Button'
import ImpactScore from '../../insightui/ImpactScore'
import {
    FundHoldingsTable,
    IPortfolio,
    IPortfolioHolding,
    PortfolioHoldingWeightMethod,
} from '../../models/api'
import Modal from '../Modal'
import { Delete } from '@styled-icons/fluentui-system-regular/Delete'
import InputField from '../../insightui/InputFieldCustom'
import axios from 'axios'
import { config } from '../../models/config'
import { store } from '../../store'
import { toast } from 'react-toastify'

interface PositionModalProps {
    isActive: boolean
    position: IPortfolioHolding
    portfolio: IPortfolio
    holdings: Array<IPortfolioHolding>
    callBack: () => void
    onClose?: () => void
}

export default class PositionModal extends React.Component<PositionModalProps> {
    state = {
        percentage: parseFloat(this.props.position[4]),
        marketValue: this.props.position[3].toFixed(2),
        holdingWeightMethod: PortfolioHoldingWeightMethod.VALUE_BASIS,
    }

    changeHoldingWeightMethod = (
        targetState: PortfolioHoldingWeightMethod,
        cb: any
    ) => {
        axios
            .put(
                `${config.api}/api/portfolios/update-holding-weight-method`,
                {
                    portfolioId: this.props.portfolio.id,
                    holdingWeightMethod: targetState,
                },
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then(() => cb())
            .catch((err) => {})
    }

    deleteHoldingCallback = (holdingId) => {
        axios
            .delete(
                `${config.api}/api/portfolios/${this.props.portfolio.id}/holdings/${holdingId}`,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then(() => {
                this.props.callBack()
            })
            .catch((err) => {
                toast.error('Holding Failed!')
            })
    }

    deleteHolding = () => {
        this.props.holdings.forEach((holding: any) => {
            if (
                holding.ticker === this.props.position[0] &&
                holding.name === this.props.position[1]
            ) {
                this.deleteHoldingCallback(holding.portfolioHoldingId)
            }
        })
    }

    updatePositionValue = () => {
        let holdingEntry
        this.props.holdings.forEach((holding: any) => {
            if (
                holding.ticker === this.props.position[0] &&
                holding.name === this.props.position[1]
            ) {
                holdingEntry = holding
            }
        })

        let requestDTO = {
            id: holdingEntry.portfolioHoldingId,
            financialInstrumentId: holdingEntry.id,
        }

        requestDTO['value'] = parseFloat(this.state.marketValue)
        axios
            .put(
                `${config.api}/api/portfolios/${this.props.portfolio.id}/holdings`,
                requestDTO,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then(() => {
                this.props.callBack()
            })
            .catch((err) => {})
    }

    handleSave = () => {
        if (
            this.props.portfolio.holdingWeightMethod !==
            this.state.holdingWeightMethod
        ) {
            this.changeHoldingWeightMethod(
                this.state.holdingWeightMethod,
                this.updatePositionValue
            )
        } else {
            this.updatePositionValue()
        }
    }

    render() {
        const { isActive, position, onClose } = this.props
        return (
            <>
                {position && (
                    <Modal isActive={isActive} onClose={onClose}>
                        <Row>
                            <Col>
                                <h2>Edit Portfolio Holding</h2>
                            </Col>
                            <Col xs />
                            <Col
                                style={{
                                    paddingTop: 10,
                                    paddingRight: 5,
                                    cursor: 'pointer',
                                }}
                                onClick={this.deleteHolding}
                            >
                                <Delete width={25} />
                            </Col>
                        </Row>
                        <Row
                            style={{
                                backgroundColor: '#F7F7F7',
                                padding: 7,
                                borderRadius: 22,
                            }}
                        >
                            <Col style={{ paddingLeft: 10, paddingTop: 10 }}>
                                <ImpactScore
                                    score={position[2]}
                                    type="small"
                                    match
                                />
                            </Col>
                            <Col
                                style={{
                                    fontWeight: 800,
                                    fontSize: 14,
                                    paddingTop: 14,
                                }}
                            >
                                <span>{position[0]}</span>
                            </Col>
                            <Col
                                style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    paddingTop: 14,
                                    paddingLeft: 10,
                                }}
                            >
                                <span>{position[1].slice(0, 23)}</span>
                            </Col>
                            <Col xs />
                            <Col
                                style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    paddingTop: 14,
                                    paddingLeft: 10,
                                }}
                            >
                                <span>{this.state.percentage}%</span>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 5 }}>
                            <Col
                                style={{ cursor: 'pointer' }}
                                xs
                            >
                                <InputField
                                    value={this.state.marketValue.toString()}
                                    title="Market Value"
                                    id={'1'}
                                    disableIf={
                                        this.state.holdingWeightMethod ===
                                        PortfolioHoldingWeightMethod.SHARE_COUNT
                                    }
                                    width={'100%'}
                                    onChange={(e) =>
                                        this.setState({
                                            marketValue: e.target.value,
                                        })
                                    }
                                />
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 30 }}>
                            <Col xs>
                                <Button
                                    type="outlined"
                                    style={{ width: '100%', cursor: 'pointer' }}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs>
                                <Button
                                    type="primary"
                                    style={{ width: '100%', cursor: 'pointer' }}
                                    onClick={this.handleSave}
                                >
                                    Save Holding
                                </Button>
                            </Col>
                        </Row>
                    </Modal>
                )}
            </>
        )
    }
}
