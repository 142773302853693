import ImpactScore from '../insightui/ImpactScore'
import { isMobile } from 'react-device-detect'

export interface IValuesBasedEntity {
    impactScore?: number | null

    environmentalScore: number
    environmentalTotal: number

    socialScore: number
    socialTotal: number

    governanceScore: number
    governanceTotal: number
}

export const enum FinancialInstrumentType {
    COMMON_STOCK = 'COMMON_STOCK',
    EXCHANGE_TRADED_FUND = 'EXCHANGE_TRADED_FUND',
    MUTUALFUND = 'MUTUALFUND',
    UNKNOWN = 'UNKNOWN',
}

export const enum IntegrationProvider {
    IEX_TRADING = 'IEX_TRADING',
    INTRINIO = 'INTRINIO',
    VALUE_LINE = 'VALUE_LINE',
    XIGNITE = 'XIGNITE',
}

export interface IFinancialInstrument {
    id?: number
    ticker?: string
    name?: string
    previousSharePrice?: number
    type?: FinancialInstrumentType
    source?: IntegrationProvider
    sourceId?: string
    retrieved?: any
    impactScore?: number
    calculated?: any
    marketCapitalization?: number
    isDomestic?: boolean
}

export interface ICategory {
    name: string
    count?: number
    isPositive: boolean
    criteria?: ICategory[]
}

export interface ICategoryTemplate {
    title: string
    name: string
    positions: IPosition[]
}

export interface ICustomPosition {
    positive: boolean
    criterion: string
}

export interface IPosition {
    positive: boolean
    criterion: {
        title: string
        esgType: string
        name: string
    }
}

export interface ISettingsCategory {
    id?: number
    title: string
    name: string
    state: string
}

export interface ICategorySettingsTemplate {
    id: number
    name: string
    title: string
    categories?: ISettingsCategory[]
}

export interface IStocksIssues extends ICategory {
    id: string
    name: string
    count: number
    criteria?: IStocksIssues[]
}

export interface IEsgCriterionCount {
    criterion: {
        title: string
        esgIssueCriterionGroup?: any
        esgType?: string
        name: string
    }
    count: number
}

export interface IEsgCriterionRollup {
    criterion: {
        title: string
        esgIssueCriterionGroup?: any
        esgType: string
        name: string
    }
    weightedPercentage: number
    numberOfHoldings?: number
    positive?: boolean
}

export interface IEsgCriterionDetail {
    date: string
    source: string
    detail: string
    url?: string
    criterion: {
        title: string
        esgType: string
        name: string
    }
    title: string
}

export interface IEsgCriterionDetailPage {
    totalCount: number
    data: IEsgCriterionDetail[]
}

export interface IInstrumentListingApi {
    financialInstrumentId: number
    ticker: string
    name: string
}

export interface IInstrumentListing {
    id: number
    name: string
    ticker: string
    impactScore?: number
}

export interface IStock extends IValuesBasedEntity {
    id: number
    name: string
    ticker: string
    type: string
    issues?: IStocksIssues[]
    hasConstituents: boolean
    isDomestic: boolean

    open: number
    close: number
    date: Date

    impactScore: number | null
    location: string
}

export interface IStockApi {
    ticker: string
    name: string
    impactScore?: number | null
    environmentalScore?: number
    financialInstrumentId: number
    socialScore?: number
    governanceScore?: number
    type?: {
        description: string
        name: string
        fund: boolean
    }
    hasConstituents: boolean
    isDomestic: boolean
    esgIssueCriteriaCount?: IEsgCriterionCount[]
    esgIssueCriteriaRollup?: IEsgCriterionRollup[]
    exchangeLocation: {
        description: string
        name: string
    }
}

export interface IFundHoldingApi {
    financialInstrumentId: number
    ticker: string
    name: string
    score: number
    holdingPercentage: number
}

export interface IFundApi {
    fund: {
        id: number
        ticker: string
        exchange: string
        name: string
        impactScore: number
        sector: string
        industry: string
        isin: string
        type: string
    }

    holdingsAmount: number
    currentPage: number
    totalPages: number
    totalHoldings: number

    holdings: IFundHoldingApi[]
}

export interface IIssueDetailModalData {
    id: number
    isPortfolio: boolean
    criterionName: string
    criterionTitle: string
}

export interface UniversalSearchResultItem {
    id: number
    name: string
    ticker: string
    impactScore: number | null
    memberOfPortfolioId: boolean
    exchange: string
    industry: string
    type: string
    sector: string
    exchangeLocation: string
    isDomestic: boolean
}

export interface ChartItem {
    date: string
    time: string | null
    label: string
    sharePrice: number
    changeOverPeriodPercent: number
}

export interface ChartResponse {
    financialInstrumentId: number
    ticker: string
    exchange: {
        description: string
        name: string
    }
    period: string
    resolution: string
    date: string
    time: string | null
    timeZoneAbbreviation: string
    close: number
    changeOverPeriodMonetary: number
    changeOverPeriodPercent: number
    chart: Array<ChartItem>
}

export interface IAccount {
    activated: boolean
    authorities: string[]
    affiliate?: string
    email: string
    fullName: string
    langKey: string
    login: string
    imageUrl: string | null
    zipCode: string
    phoneNumber: string
    lastName: string
    firstName: string
    lastModifiedDate: string
    lastModifiedBy: string
    isWordpressManaged: boolean
    id: number
    footer: null
    criterionPositions: any
    createdDate: string
    createdBy: string
    categoryTemplateName: any
}

export interface IPortfolioIssue extends ICategory {
    id?: number
    name: string
    count: number
    isPositive: boolean
    criteria?: IPortfolioIssue[]
}

export interface IPortfolioByFinancialInstrument {
    id: number
    name: string
    impactScore?: number
    includesFinancialInstrument: boolean
    numberOfHoldings?: number
}

export interface IPortfolioDTO {
    id: number
    name: string
    created: string
    categoryTemplateName: string
    numberOfHoldings: number
    impactScore?: number
    showImpactScore: boolean
}

export interface IPortfolioAPI {
    id: number
    name: string
    created: string
    cumulativeImpactScore: number
    impactScore?: number
    lastModified: string
    showImpactScore: boolean
    totalHoldings: number
    criterionPositions: IPosition[]
    categoryTemplateName: string
    environmentalScore: number
    socialScore: number
    governanceScore: number
    esgIssueCriteriaRollup?: IEsgCriterionRollup[]
    holdingWeights: IPortfolioHoldingWeight[]
    monetaryValue: number
    type: string
    holdingWeightMethod: string
    containsForeign: boolean
}

export interface IListPortfoliosDTO {
    portfolios: IPortfolioDTO[]
    totalCount: number
    impactScoreLastUpdatedAt: string
}

export interface IPortfolio extends IValuesBasedEntity {
    id: number
    name: string
    categoryTemplateName: string
    lastModified: Date
    showImpactScore: boolean
    totalHoldings: number
    holdings: IPortfolioHolding[]
    criterionPositions: IPosition[]
    issues: IPortfolioIssue[]
    environmentalScore: number
    socialScore: number
    governanceScore: number
    holdingWeights: IPortfolioHoldingWeight[]
    monetaryValue: number
    holdingWeightMethod: string
    containsForeign: boolean
    type: string
}

export interface IPortfolioHolding {
    id: number
    shares: number
    financialInstrument: IFinancialInstrument
    value: number
}

export interface IPortfolioHoldingWeight {
    ticker: string
    weight: number
}

export interface IPortfolioHoldingPage {
    holdings: IPortfolioHolding[]
    totalHoldings: number
    totalPages: number
}

export interface IComparePortfolioModalInput {
    portfolios: IPortfolioDTO[]
    portfolioId: number
    portfolioName: string
}

export interface ICreateOrEditPortfolio {
    id?: number
    name: string
    templateName: string
    type?: string
    templateTitle?: string
}

export interface ICreateOrEditPortfolioModalInput {
    currentCategoryTemplateName: string
    portfolioName: string | never
    selectedTemplate: ICategorySettingsTemplate | never
    showImpactScore: boolean
    modalTitle: string
    callToActionButtonCopy: string
    isNewPortfolio: boolean
    isPremiumUser?: boolean
}

export interface IUpdatePortfolioSettings {
    id: number
    templateName: string
    templateTitle?: string
    showImpactScore: boolean
    categories: ISettingsCategory[]
}

export interface IImportPortfolioHoldingsDTO {
    attemptedCount: number
    completedCount: number
    invalid: IFailedImportPortfolioHoldingDTO[]
}

export interface IFailedImportPortfolioHoldingDTO {
    reason: string
    message: string
    row: number
}

export interface IPortfolioHoldingWeightMethod {
    name: string
    title: string
}

export enum PortfoliosSortOption {
    ALPHABETICAL = 'name,asc',
    IMPACT_SCORE = 'impactScore,desc',
    NEWEST = 'created,desc',
    OLDEST = 'created,asc',
}

export enum PortfolioHoldingWeightMethod {
    SHARE_COUNT = 'SHARE_COUNT',
    VALUE_BASIS = 'VALUE_BASIS',
}

export interface IAdvancedSearchRequest {
    page?: string
    size?: string
    direction?: string
    sort?: string
    query: string
    types: string[]
    marketCapitalizations: string[]
    sectors: string[]
    impactScoreMinimum: number
    impactScoreMaximum: number
    environmentalScoreMinimum: number
    environmentalScoreMaximum: number
    socialScoreMinimum: number
    socialScoreMaximum: number
    governanceScoreMinimum: number
    governanceScoreMaximum: number
    categoryFilters?: ICategoryFilter[]
    listedExchanges: string[]
}

export interface ICategoryFilter {
    criterion: string
    present: boolean
}

export interface IAdvancedSearchResultsPage {
    results: IFinancialInstrument[]
    totalResults: number
}

export const FundHoldingsTable = [
    {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        format: (e: any) => <strong>{e}</strong>,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        format: (title) => {
            let string
            if (title) {
                if (title.length > 15 && isMobile) {
                    string = title.slice(0, 15) + '...' //mobile
                } else if (title.length > 30) {
                    string = title.slice(0, 30) + '...' //desktop
                } else {
                    string = title //default
                }

                return (
                    <a
                        style={{
                            color: '#2d8aaf',
                            textDecoration: 'underline',
                        }}
                    >
                        {string}
                    </a>
                )
            } else {
                return '-'
            }
        },
    },
    {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        format: (value: any) => {
            return !isMobile ? (
                <ImpactScore
                    withAnimation={false}
                    score={value}
                    type="small"
                    style={{ marginLeft: 100 }}
                ></ImpactScore>
            ) : (
                value
            )
        },
    },
    {
        title: 'Asset %',
        dataIndex: 'holdingPercentage',
        format: (e: any) => {
            const item = parseFloat(e)
            return item.toFixed(2) + '%'
        },
        key: 'holdingPercentage',
    },
]

export interface ITrendingGroup {
    name: string
    impactScore: number
    ticker: string
    searches: number
    id: number
    financialInstrumentId: number
}

export interface IRankedItem {
    id: number
    name: string
    ticker: string
    impactScore: number
}

export interface IRankedGroup {
    topFunds: Array<IRankedItem>
    worstFunds: Array<IRankedItem>
    topStocks: Array<IRankedItem>
    worstStocks: Array<IRankedItem>
}

export interface ICustomProfile {
    positive: Array<String>
    negative: Array<String>
    name: string
    id: number
}

const exampleScreeningProfile: ICustomProfile = {
    positive: ['CUSTOMER_PRIVACY_BIC', 'ENERGY_MANAGEMENT_BIC'],
    negative: ['GAMBLING', 'GMO_PRODUCTS'],
    name: 'test Profile',
    id: 123123123,
}
export type IDetailsAPI = {
    date: string
    source: string
    detail: string
    title: string
    url: string
}

export interface createSubscription {
    priceId: string
    couponId?: string
}

export interface IBacktest {
    chart?: Array<ChartItem>
    alpha: String
    beta: String
    corr: String
    downSideDeviation: String
    ftAlpha: String
    rSquared: String
    standardDeviation: String
    sortino: String
    treynor: String
    ui: String
    upi: String
}

export interface INews {
    ticker: String
    datetime: number //utc
    headline: String
    url: String
    related: String
}

export interface ICoupon {
    couponId: String
    amountOff: number
    duration: String
    durationInMonths: number
    name: String
    percentOff: number
    valid: boolean
    appliesTo: Array<String>
}

export type INewsResponse = Array<INews>
