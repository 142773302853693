import React from 'react'
import { isMobile } from 'react-device-detect'

interface ModalProps {
    isActive: boolean
    onClose?: () => void
    width?: number
    style?: Record<string, any>
    height?: number
    top?: number | string
}

export default class Modal extends React.Component<ModalProps> {
    componentDidMount() {
        if (this.props.isActive) {
            document.body.style.overflow = 'hidden'
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset'
    }

    width = () => {
        if (isMobile) {
            return window.innerWidth - 50
        } else {
            return this.props.width ?? 400
        }
    }

    desktop = () => {
        return isMobile
            ? {
                  left: '1%',
                  top: '1%',
              }
            : {}
    }

    render() {
        return (
            <>
                {this.props.isActive ? (
                    <>
                        {(document.body.style.overflow = 'hidden').replace(
                            'hidden',
                            ''
                        )}
                        <div
                            style={{
                                opacity: 0.8,
                                background: '#2d89ae',
                                width: '100%',
                                height: '100%',
                                zIndex: 999,
                                top: 0,
                                left: 0,
                                position: 'fixed',
                            }}
                            onClick={() => {
                                if (this.props.onClose) this.props.onClose()
                            }}
                        />
                        <div
                            style={{
                                padding: isMobile ? 60 : 25,
                                width: this.width(),
                                height: isMobile ? window.innerHeight : 'auto',
                                paddingBottom: isMobile ? 0 : 35,
                                position: 'fixed',
                                zIndex: 1000,
                                backgroundColor: 'white',
                                left: ' 50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)',
                                ...this.props.style,
                            }}
                        >
                            {this.props.children}
                        </div>
                    </>
                ) : (
                    <>
                        {(document.body.style.overflow = 'unset').replace(
                            'unset',
                            ''
                        )}
                    </>
                )}
            </>
        )
    }
}
