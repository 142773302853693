import React from 'react'
import axios from 'axios'
import { store } from '../../store'
import { toast } from 'react-toastify'
import { config } from '../../models/config'
import { Row, Col } from 'react-flexbox-grid'
import Charge from './Charge'
import Button from '../../insightui/Button'
import UpdatePaymentMethod from './UpdatePaymentMethod'
import Confirm from '../../utils/components/Confirm'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'

export interface ICharge {
    amount: number
    receiptUrl: string
    paid: boolean
    createdDate: number //unix timestamp
}

interface IPaymentMethod {
    lastFour: string
    expMonth: number
    expYear: number
    issuer: string
    brand: string
}

type PropsType = RouteComponentProps<{}>
class Billing extends React.Component<PropsType> {
    state: {
        charges: Array<ICharge>
        paymentMethod: IPaymentMethod
        updatePaymentMethod: boolean
        cancelSubscription: boolean
    } = {
        charges: null,
        paymentMethod: null,
        updatePaymentMethod: false,
        cancelSubscription: false,
    }

    componentDidMount(): void {
        try {
            this.getCharges()
            this.getPaymentMethodDetails()
        } catch {
            toast.error('There was an error fetching your account details')
        }
    }

    getCharges = () => {
        axios
            .get(`${config.api}/api/payment/charges`, {
                headers: { Authorization: `Bearer ${store.getState().token}` },
            })
            .then((response: any) => {
                let charges: Array<ICharge> = response.data
                this.setState({
                    charges: charges,
                })
            })
    }

    getPaymentMethodDetails = () => {
        axios
            .get(`${config.api}/api/payment/payment-method `, {
                headers: { Authorization: `Bearer ${store.getState().token}` },
            })
            .then((response: any) => {
                this.setState({
                    paymentMethod: response.data,
                })
            })
    }

    cancelSubscription = () => {
        axios
            .delete(`${config.api}/api/payment/subscription`, {
                headers: { Authorization: `Bearer ${store.getState().token}` },
            })
            .then(() => {
                this.props.history.push('/')
            })
    }

    openUpdatePaymentMethodModal = () => {
        this.setState({ updatePaymentMethod: true })
    }

    closeUpdatePaymentMethodModal = () => {
        this.setState({ updatePaymentMethod: false })
    }

    openCancelSubscriptionModal = () =>
        this.setState({ cancelSubscription: true })

    closeCancelSubscriptionModal = () =>
        this.setState({ cancelSubscription: false })

    render() {
        const {
            paymentMethod,
            charges,
            updatePaymentMethod,
            cancelSubscription,
        } = this.state
        return (
            <>
                <h2>Billing Details</h2>
                <UpdatePaymentMethod
                    isActive={updatePaymentMethod}
                    onClose={this.closeUpdatePaymentMethodModal}
                />
                <Confirm
                    message={
                        'Are you sure you want to cancel your Premium Subscription?'
                    }
                    isActive={cancelSubscription}
                    onClose={this.closeCancelSubscriptionModal}
                    subTitle={
                        'You will lose access to Premium features and have to upgrade again to continue using the service.'
                    }
                    onConfirm={this.cancelSubscription}
                />
                {paymentMethod && charges && (
                    <>
                        <Row>
                            <Col xs={12} lg={6}>
                                <div
                                    style={{
                                        backgroundColor: '#eff7fb',
                                        padding: 20,
                                        borderRadius: 4,
                                        marginBottom: 15,
                                    }}
                                >
                                    <h4
                                        style={{
                                            margin: 0,
                                            fontWeight: 'normal',
                                            marginBottom: 8,
                                        }}
                                    >
                                        <strong>{paymentMethod.brand}</strong>{' '}
                                        ending in{' '}
                                        <strong>
                                            {paymentMethod.lastFour}
                                        </strong>
                                    </h4>
                                    <p
                                        style={{
                                            fontSize: 12,
                                            margin: 0,
                                        }}
                                    >
                                        Expires:{' '}
                                        <strong>
                                            {paymentMethod.expMonth}/
                                            {paymentMethod.expYear}
                                        </strong>
                                    </p>
                                    {/* <p>issuer: {paymentMethod.issuer}</p> */}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3} sm={12}>
                                <Button
                                    type="primary"
                                    onClick={this.openUpdatePaymentMethodModal}
                                    style={{ width: 150, marginBottom: 10 }}
                                >
                                    Update
                                </Button>
                            </Col>
                            <Col lg={3} sm={12}>
                                <Button
                                    style={{ width: 150 }}
                                    type="outlined"
                                    onClick={this.openCancelSubscriptionModal}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>

                        <hr
                            style={{
                                border: 0,
                                borderBottom: '1px solid #eff7fb',
                                marginTop: 20,
                            }}
                        />
                        <Row>
                            <Col>
                                <h3>Receipts</h3>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.charges.map((charge) => (
                                <Charge {...charge} />
                            ))}
                        </Row>
                    </>
                )}
            </>
        )
    }
}

export default withRouter(Billing)
