import React, { ReactNode } from 'react'
import Header from '../insightui/Header'
import Button, { ButtonType } from '../insightui/Button'
import { store } from '../store'
import Table from '../insightui/Table'
import { isMobile } from 'react-device-detect'
import { Row, Col } from 'react-flexbox-grid'
import { globals } from '../utils/globals'
import Footer from '../insightui/Footer'
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { Plans } from '../pages/Register'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
const DEBUG_VIEW = 'desktop'

enum Plan {
    NONE = 0,
    BASIC = 1,
    PRO = 2,
    DESCRIPTION = 3,
    CUSTOM = 4,
}

enum Sizes {
    VERTICAL_SPACE = 15,
    ROW_HEIGHT = 50,
    TEXT_MARGIN = 15,
    HEADER_OFFSET = 80,
}

type View = 'mobile' | 'desktop'

const SHARED: any = {
    data: {
        title: 'Plans & Pricing',
        subtitle: `Choose the plan that's right for you`,
        plans: {
            basic: {
                title: 'Insight Basic',
                subtitle: '$5/month',
                buttonTitle: store
                    .getState()
                    .authorities.includes('ROLE_PREMIUM')
                    ? 'Go Basic'
                    : 'Go Basic',
                buttonType: 'primary',
            },
            pro: {
                title: 'Insight Premium',
                subtitle: '$20/month',
                buttonTitle: !store
                    .getState()
                    .authorities.includes('ROLE_PREMIUM')
                    ? 'Go Premium'
                    : 'Go Premium',
                buttonType: 'inspireOrange',
            },
            advisor: {
                title: 'Insight Advisor',
                subtitle: 'Coming Soon',
                buttonTitle: 'Coming Soon',
                buttonType: 'inspireBlue',
            },
        },
    },
    style: {
        typeface: {
            title: {
                ...globals.typeface.advancedSearch.title,
                fontSize: 32,
                margin: 0,
            },
            subtitle: {
                ...globals.typeface.advancedSearch.title,
                fontSize: 26,
                margin: 0,
            },
            text: {
                ...globals.typeface.advancedSearch.title,
                fontSize: 18,
                margin: 0,
                fontWeight: 0,
            },
        },
    },
}

const TEMPLATE: any = {
    desktop: {
        typeface: {
            title: {
                ...SHARED.style.typeface.title,
            },
            subtitle: {
                ...SHARED.style.typeface.subtitle,
            },
            text: {
                ...SHARED.style.typeface.text,
            },
        },
        buttonBox: {
            wrapper: {
                width: 200,
                height: 200,
                textAlign: 'center',
            },
        },
        tableColorScheme: {
            odd: globals.colors.inspire.background,
            even: 'hsla(199, 23.81%, 91.25%, 1.00)',
        },
        page: {
            title: {
                ...SHARED.style.typeface.title,
            },
            subtitle: {
                ...SHARED.style.typeface.text,
            },
        },
        descriptiveColStyle: {
            basic: { marginLeft: '-25%', marginTop: 0, textAlign: 'center' },
            pro: { marginLeft: '25%', marginTop: -40, textAlign: 'center' },
            advisor: { marginLeft: '76%', marginTop: -40, textAlign: 'center' },
        },
    },
    mobile: {
        typeface: {
            title: {
                ...SHARED.style.typeface.title,
            },
            subtitle: {
                ...SHARED.style.typeface.subtitle,
            },
            text: {
                ...SHARED.style.typeface.text,
            },
        },
        buttonBox: {
            wrapper: {
                width: '100%',
                height: 'auto',
                textAlign: 'center',
            },
        },
        tableColorScheme: {
            odd: 'white',
            even: globals.colors.inspire.background,
        },
        page: {
            title: {
                ...SHARED.style.typeface.title,
                paddingTop: Sizes.HEADER_OFFSET + Sizes.VERTICAL_SPACE * 2,
                paddingBottom: Sizes.VERTICAL_SPACE,
            },
            subtitle: {
                ...SHARED.style.typeface.text,
                paddingBottom: Sizes.VERTICAL_SPACE * 3,
            },
        },
        descriptiveColStyle: {
            basic: { marginLeft: '50%', marginTop: 0, textAlign: 'center' },
            pro: { marginLeft: '50%', marginTop: 0, textAlign: 'center' },
            advisor: { marginLeft: '50%', marginTop: 0, textAlign: 'center' },
        },
    },
}

function getView(override?: View) {
    return (override ? override : 'desktop') as View
}

function renderButtonBox(params: {
    title: string
    subtitle: string
    buttonTitle: string
    buttonType?: ButtonType
    color?: string
    view?: View
    style?: Record<string, any>
    onClick?: () => void
}) {
    const type: ButtonType = params.buttonType
        ? (params.buttonType as ButtonType)
        : 'primary'

    const fontColor: Record<string, any> = {
        color: params.color ? params.color : globals.colors.inspire.darknavy,
    }

    const view: View = params.view ? params.view : 'desktop'

    return (
        <div className="renderButtonBox" style={{ ...params.style }}>
            <p
                style={{
                    ...TEMPLATE[view].typeface.subtitle,
                    ...fontColor,
                }}
                className="plans-pricing-centered"
            >
                {params.title}
            </p>
            <p
                style={{
                    ...TEMPLATE[view].typeface.subtitle,
                    ...fontColor,
                    fontSize: 20,
                    marginTop: 5,
                }}
                className="plans-pricing-centered"
            >
                {params.subtitle}
            </p>
            {params.title !== 'Insight Advisor' && (
                <>
                    <p
                        style={{
                            fontFamily: 'URW Geometric Cond, sans-serif',
                            textAlign: 'center',
                            fontSize: 15,
                            fontWeight: 'bold',
                            ...fontColor,
                            margin: 0,
                        }}
                    >
                        Billed Annually
                    </p>

                    {params.buttonTitle === 'Current Plan' ? (
                        <>
                            <Button
                                type={type}
                                style={{
                                    width: 130,
                                    margin: '10px auto 0',
                                    textAlign: 'center',
                                    display: 'block',
                                    cursor: 'default',
                                    background: 'none',
                                    color: 'rgb(11, 25, 44',
                                    borderColor: 'rgb(11, 25, 44',
                                }}
                            >
                                {params.buttonTitle}
                            </Button>
                        </>
                    ) : (
                        <></>
                        // <Link to="/register">
                        //     <Button
                        //         type={type}
                        //         style={{
                        //             width: 130,
                        //             margin: '10px auto 0',
                        //             textAlign: 'center',
                        //             display: 'block',
                        //         }}
                        //     >
                        //         {params.buttonTitle}
                        //     </Button>
                        // </Link>
                    )}
                </>
            )}
        </div>
    )
}

function formatTableText(value: string) {
    return (
        <div
            style={{
                textTransform: 'none',
            }}
        >
            {value}
        </div>
    )
}

function formatTableTitle(value: string) {
    return (
        <div
            style={{
                textTransform: 'none',
            }}
        >
            <strong>{value}</strong>
        </div>
    )
}

function formatTableCell(
    value: number | string | ReactNode,
    style?: Record<string, any>
) {
    const prefab: Array<ReactNode> = [
        <></>,
        <CheckCircleFill
            width="20"
            style={{ color: globals.colors.positive.blue, ...style }}
        ></CheckCircleFill>,
        <CheckCircleFill
            width="20"
            style={{ color: globals.colors.inspire.orange, ...style }}
        ></CheckCircleFill>,
    ]

    const isNumber: boolean = typeof value === 'number' && value < prefab.length
    //if (isNumber) console.log('isNumber: ' + isNumber + ' : ' + value)
    if (isNumber) return prefab[value as unknown as number]

    const isString: boolean = typeof value === 'string'
    //if (isString) console.log('isString: ' + isString + ' : ' + value)
    if (isString) return <>{formatTableText(value as string)}</>
    else return value
}

function renderTable(
    headers: any,
    dataSource: any,
    rowColorScheme?: { even: string; odd: string }
) {
    return (
        <Table
            value={'Advanced Search Results'}
            style={{
                marginBottom: globals.sizes.component.verticalSpace,
            }}
            headers={headers}
            dataSource={dataSource}
            hasPagination={false}
            hasTitle={false}
            hasHeaders={false}
            rowColorScheme={rowColorScheme}
        ></Table>
    )
}

function renderComponentHeader() {
    return <>{<Header logo />}</>
}

interface PlansAndPricingProps extends RouteComponentProps {
    onSubmission?: (nav: number) => void
    disableFooter?: boolean
}

class PlansAndPricing extends React.Component<PlansAndPricingProps> {
    render() {
        const view: View = getView(DEBUG_VIEW)

        return (
            <>
                {!isMobile ? (
                    <PlansAndPricingDesktop
                        onSubmission={this.props.onSubmission}
                        disableFooter={this.props.disableFooter}
                    />
                ) : (
                    <PlansAndPricingMobile
                        onSubmission={this.props.onSubmission}
                        disableFooter={this.props.disableFooter}
                        history={this.props.history}
                    />
                )}
            </>
        )
    }
}

export default withRouter(PlansAndPricing)

export class PlansAndPricingMobile extends React.Component<{
    onSubmission?: (nav: number) => void
    disableFooter?: boolean
    history: any
}> {
    state = {
        view: 'mobile',
    }
    render() {
        const container: Record<string, any> = {
            width: '99%',
            height: 'auto',
            minHeight: 100,
            border: '1px solid black',
            paddingTop: Sizes.VERTICAL_SPACE,
            paddingBottom: Sizes.VERTICAL_SPACE,
            backgroundColor: 'white',
            marginBottom: Sizes.VERTICAL_SPACE,
            verticalAlign: 'top',
        }

        const tableTitle: Record<string, any> = {
            ...globals.typeface.spacedUppercaseThin,
            fontWeight: 800,
            fontSize: 12,
            width: `calc(100% - ${Sizes.TEXT_MARGIN}px)`,
            borderBottom: '1px solid black',
            height: Sizes.ROW_HEIGHT,
            marginTop: Sizes.VERTICAL_SPACE,
            lineHeight: 5.5,
            paddingLeft: Sizes.TEXT_MARGIN,
        }
        const view: View = this.state.view as View

        return (
            <>
                {!this.props.disableFooter && (
                    <div>{renderComponentHeader()}</div>
                )}

                <div
                    style={{
                        padding: !this.props.disableFooter
                            ? '130px 20px 20px'
                            : 0,
                        overflow: 'hidden',
                    }}
                >
                    <p
                        style={{ fontSize: 32, fontWeight: '700' }}
                        className="plans-pricing-centered"
                    >
                        {SHARED.data.title}
                    </p>
                    <p
                        style={TEMPLATE[view].page.subtitle}
                        className="plans-pricing-centered"
                    >
                        {SHARED.data.subtitle}
                    </p>

                    <div style={container}>
                        {renderButtonBox({
                            view: view,
                            color: globals.colors.inspire.darknavy,
                            ...SHARED.data.plans.basic,
                            onClick: () => {
                                if (this.props.onSubmission)
                                    this.props.onSubmission(Plans.BASIC)
                            },
                        })}
                        <div style={tableTitle}>Screening</div>
                        {renderTable(
                            getHeaders(['title', 'basic']),
                            getScreeningData(view),
                            TEMPLATE[view].tableColorScheme
                        )}
                        <div style={tableTitle}>Portfolio Analysis</div>
                        {renderTable(
                            getHeaders(['title', 'basic']),
                            getPortfolioData(),
                            TEMPLATE[view].tableColorScheme
                        )}
                        <div style={tableTitle}>Advisor Tools</div>
                        {renderTable(
                            getHeaders(['title', 'basic']),
                            getAdvisorData(),
                            TEMPLATE[view].tableColorScheme
                        )}
                    </div>

                    <div style={container}>
                        {renderButtonBox({
                            view: view,
                            color: globals.colors.inspire.orange,
                            ...SHARED.data.plans.pro,
                            onClick: () => {
                                if (this.props.onSubmission)
                                    this.props.onSubmission(Plans.PRO)
                            },
                        })}
                        <div style={tableTitle}>Screening</div>
                        {renderTable(
                            getHeaders(['title', 'pro']),
                            getScreeningData(view),
                            TEMPLATE[view].tableColorScheme
                        )}
                        <div style={tableTitle}>Portfolio Analysis</div>
                        {renderTable(
                            getHeaders(['title', 'pro']),
                            getPortfolioData(),
                            TEMPLATE[view].tableColorScheme
                        )}
                        <div style={tableTitle}>Advisor Tools</div>
                        {renderTable(
                            getHeaders(['title', 'pro']),
                            getAdvisorData(),
                            TEMPLATE[view].tableColorScheme
                        )}
                    </div>
                    <div style={container}>
                        {renderButtonBox({
                            view: view,
                            color: globals.colors.positive.blue,
                            ...SHARED.data.plans.advisor,
                            onClick: () => {
                                if (this.props.onSubmission)
                                    this.props.onSubmission(Plans.ADVISOR)
                            },
                        })}
                        <div style={tableTitle}>Screening</div>
                        {renderTable(
                            getHeaders(['title', 'advisor']),
                            getScreeningData(view),
                            TEMPLATE[view].tableColorScheme
                        )}
                        <div style={tableTitle}>Portfolio Analysis</div>
                        {renderTable(
                            getHeaders(['title', 'advisor']),
                            getPortfolioData(),
                            TEMPLATE[view].tableColorScheme
                        )}
                        <div style={tableTitle}>Advisor Tools</div>
                        {renderTable(
                            getHeaders(['title', 'advisor']),
                            getAdvisorData(),
                            TEMPLATE[view].tableColorScheme
                        )}
                    </div>
                </div>
            </>
        )
    }
}

export class PlansAndPricingDesktop extends React.Component<{
    onSubmission?: (nav: number) => void
    disableFooter?: boolean
}> {
    state = {
        view: 'desktop',
    }

    componentDidMount() {
        document.body.style.backgroundColor = globals.colors.inspire.background
    }

    render() {
        const view: View = this.state.view as View

        return (
            <div className="plansPricingContainer">
                {!this.props.disableFooter && (
                    <div>{renderComponentHeader()}</div>
                )}
                <div
                    style={{
                        // width: 'calc(100% - 110px)',
                        // minWidth: 1000,
                        // marginLeft: 50,
                        padding: !this.props.disableFooter
                            ? '120px 20px 20px'
                            : 0,
                        overflow: 'hidden',
                        width: 'auto',
                    }}
                >
                    <div
                        style={{
                            // position: 'sticky',
                            width: '100%',
                            height: 'auto',
                        }}
                    >
                        <div style={{}}>
                            <Row
                                style={{
                                    paddingTop: !this.props.disableFooter
                                        ? 50
                                        : 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                }}
                            >
                                <Col
                                    lg={3}
                                    md={12}
                                    style={{}}
                                    className="plansPricingTitle"
                                >
                                    <p style={TEMPLATE[view].typeface.title}>
                                        {SHARED.data.title}
                                    </p>
                                    <p style={TEMPLATE[view].typeface.text}>
                                        {SHARED.data.subtitle}
                                    </p>
                                </Col>
                                <Col
                                    lg={3}
                                    md={12}
                                    style={{
                                        margin: '0 auto',
                                        textAlign: 'center',
                                    }}
                                >
                                    {renderButtonBox({
                                        view: view,

                                        color: globals.colors.inspire.darknavy,
                                        ...SHARED.data.plans.basic,
                                        style: {},
                                        onClick: () => {
                                            if (this.props.onSubmission)
                                                this.props.onSubmission(
                                                    Plans.BASIC
                                                )
                                        },
                                    })}
                                </Col>
                                <Col
                                    lg={3}
                                    md={12}
                                    style={{
                                        margin: '0 auto',
                                        textAlign: 'center',
                                    }}
                                >
                                    {renderButtonBox({
                                        view: view,
                                        color: globals.colors.inspire.orange,
                                        ...SHARED.data.plans.pro,
                                        style: {},
                                        onClick: () => {
                                            if (this.props.onSubmission)
                                                this.props.onSubmission(
                                                    Plans.PRO
                                                )
                                        },
                                    })}
                                </Col>
                                <Col
                                    lg={3}
                                    md={12}
                                    style={{
                                        margin: '0 auto',
                                        textAlign: 'center',
                                    }}
                                >
                                    {renderButtonBox({
                                        view: view,
                                        color: globals.colors.positive.blue,
                                        ...SHARED.data.plans.advisor,
                                        style: {},
                                        onClick: () => {
                                            if (this.props.onSubmission)
                                                this.props.onSubmission(
                                                    Plans.ADVISOR
                                                )
                                        },
                                    })}
                                </Col>
                            </Row>
                        </div>
                        <div
                            className="plansPricingDetails"
                            style={{
                                marginTop: 80,
                            }}
                        >
                            <p style={TEMPLATE[view].typeface.subtitle}>
                                Screening
                            </p>
                            {renderTable(
                                getHeaders(),
                                getScreeningData(view),
                                TEMPLATE[view].tableColorScheme
                            )}
                            <p style={TEMPLATE[view].typeface.subtitle}>
                                Portfolio Analysis
                            </p>
                            {renderTable(
                                getHeaders(),
                                getPortfolioData(),
                                TEMPLATE[view].tableColorScheme
                            )}
                            <p style={TEMPLATE[view].typeface.subtitle}>
                                Advisor Tools
                            </p>
                            {renderTable(
                                getHeaders(),
                                getAdvisorData(),
                                TEMPLATE[view].tableColorScheme
                            )}
                        </div>
                    </div>
                    <div style={{}}>
                        <Row
                            style={{
                                paddingTop: !this.props.disableFooter ? 50 : 0,
                                marginLeft: 0,
                                marginRight: 0,
                            }}
                        >
                            <Col
                                lg={3}
                                md={12}
                                style={{}}
                                className="plansPricingTitle"
                            >
                                <p style={TEMPLATE[view].typeface.title}>
                                    {SHARED.data.title}
                                </p>
                                <p style={TEMPLATE[view].typeface.text}>
                                    {SHARED.data.subtitle}
                                </p>
                            </Col>
                            <Col
                                lg={3}
                                md={12}
                                style={{
                                    margin: '0 auto',
                                    textAlign: 'center',
                                }}
                            >
                                {renderButtonBox({
                                    view: view,

                                    color: globals.colors.inspire.darknavy,
                                    ...SHARED.data.plans.basic,
                                    style: {},
                                    onClick: () => {
                                        if (this.props.onSubmission)
                                            this.props.onSubmission(Plans.BASIC)
                                    },
                                })}
                            </Col>
                            <Col
                                lg={3}
                                md={12}
                                style={{
                                    margin: '0 auto',
                                    textAlign: 'center',
                                }}
                            >
                                {renderButtonBox({
                                    view: view,
                                    color: globals.colors.inspire.orange,
                                    ...SHARED.data.plans.pro,
                                    style: {},
                                    onClick: () => {
                                        if (this.props.onSubmission)
                                            this.props.onSubmission(Plans.PRO)
                                    },
                                })}
                            </Col>
                            <Col
                                lg={3}
                                md={12}
                                style={{
                                    margin: '0 auto',
                                    textAlign: 'center',
                                }}
                            >
                                {renderButtonBox({
                                    view: view,
                                    color: globals.colors.positive.blue,
                                    ...SHARED.data.plans.advisor,
                                    style: {},
                                    onClick: () => {
                                        if (this.props.onSubmission)
                                            this.props.onSubmission(
                                                Plans.ADVISOR
                                            )
                                    },
                                })}
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: 300 }}>
                        {!this.props.disableFooter && <Footer />}
                    </div>
                </div>
            </div>
        )
    }

    getDateString() {
        const date: any = new Date()
        const year: string = `20${(date.getFullYear() % 100).toString()}`
        const monthNumber = date.getMonth() + 1
        const dayNumber = date.getDay() - 2
        const month: string =
            monthNumber < 10
                ? '0' + monthNumber.toString()
                : monthNumber.toString()
        const day: string =
            dayNumber < 10 ? '0' + dayNumber.toString() : dayNumber.toString()
        let name: string = `${year}-${month}-${day}`
        return name
    }

    renderFooter() {
        return (
            <div
                style={{
                    paddingTop: 20,
                }}
            >
                {!this.props.disableFooter && <Footer />}
            </div>
        )
    }
}

export function getHeaders(filter?: string[]) {
    interface Column {
        title: string
        dataIndex: string
        key: string
        format?: (value: number | string | ReactNode) => ReactNode
    }

    interface Ref {
        [key: string]: any
    }

    const title: Column = {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        format: (value: number | string | ReactNode) => {
            return formatTableCell(value)
        },
    }
    const basic: Column = {
        title: 'Basic',
        dataIndex: 'basic',
        key: 'basic',
        format: (value: number | string | ReactNode) => {
            return formatTableCell(value, {})
        },
    }

    const pro: Column = {
        title: 'Pro',
        dataIndex: 'pro',
        key: 'pro',
        format: (value: number | string | ReactNode) => {
            return formatTableCell(value, {})
        },
    }

    const advisor: Column = {
        title: 'Advisor',
        dataIndex: 'advisor',
        key: 'advisor',
        format: (value: number | string | ReactNode) => {
            return formatTableCell(value, {})
        },
    }

    if (!filter) return [title, basic, pro, advisor]

    const ref: Ref = {
        title: title,
        basic: basic,
        pro: pro,
        advisor: advisor,
    }

    return filter.map((name: string) => {
        return ref[name]
    })
}

export function getScreeningData(view?: View) {
    view = view ? view : 'desktop'
    const dataSource = [
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Inspire Impact Scores on 25,000+ Tickers',
            basic: Plan.BASIC,
            pro: Plan.BASIC,
            advisor: Plan.BASIC,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Unique Biblically Responsible Investing Data',
            basic: Plan.BASIC,
            pro: Plan.BASIC,
            advisor: Plan.BASIC,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Screen Stocks, Mutual Funds and ETFs',
            basic: Plan.BASIC,
            pro: Plan.BASIC,
            advisor: Plan.BASIC,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Investor Activism Campaigns',
            basic: Plan.BASIC,
            pro: Plan.BASIC,
            advisor: Plan.BASIC,
        },
    ]
    return dataSource
}

export function getPortfolioData() {
    let key: number = 0
    const dataSource = [
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Comprehensive Analysis',
            basic: Plan.BASIC,
            pro: Plan.BASIC,
            advisor: Plan.BASIC,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Save Portfolios and Track Over Time',
            basic: Plan.BASIC,
            pro: Plan.BASIC,
            advisor: Plan.BASIC,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Import Portfolios',
            basic: Plan.BASIC,
            pro: Plan.BASIC,
            advisor: Plan.BASIC,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Export Portfolios',
            basic: Plan.NONE,
            pro: Plan.PRO,
            advisor: Plan.BASIC,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Side-by-Side Portfolio Comparisons',
            basic: Plan.NONE,
            pro: Plan.PRO,
            advisor: Plan.BASIC,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Generate PDF Reports',
            basic: Plan.NONE,
            pro: Plan.PRO,
            advisor: Plan.BASIC,
        },
    ]
    return dataSource
}

export function getAdvisorData() {
    let key: number = 0
    const dataSource = [
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Investor Data Gathering Questionnaire',
            basic: Plan.NONE,
            pro: Plan.NONE,
            advisor: Plan.PRO,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Custom URL',
            basic: Plan.NONE,
            pro: Plan.NONE,
            advisor: Plan.PRO,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Client Management',
            basic: Plan.NONE,
            pro: Plan.NONE,
            advisor: Plan.PRO,
        },
        {
            key: `pricingData-${globals.util.getRandomString()}`,
            name: 'Lead Generation',
            basic: Plan.NONE,
            pro: Plan.NONE,
            advisor: Plan.PRO,
        },
    ]
    return dataSource
}
