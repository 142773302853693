import React from 'react'

interface TagItemSelectProps {
    title: string
    value: string
    selected: boolean
    onClick: (value: string) => void
}

export class TagSelectItem extends React.Component<TagItemSelectProps> {
    render() {
        const { title, selected, onClick } = this.props
        return (
            <div
                style={{
                    marginLeft: 10,
                    padding: 7,
                    marginTop: 12,
                    color: selected ? 'white' : 'black',
                    borderRadius: 5,
                    cursor: 'pointer',
                    userSelect: 'none',
                    display: 'inline-block',
                    fontWeight: 400,
                    fontSize: 14,
                    backgroundColor: selected ? '#2c8aae' : '#e3eaef',
                }}
                onClick={() => onClick(this.props.value)}
            >
                <div
                    style={{
                        width: 10,
                        height: 10,
                        borderRadius: 5,
                        marginRight: 5,
                        backgroundColor: selected ? 'white' : '#e3eaef',
                        border: selected
                            ? '0.5px solid white'
                            : '0.5px solid black',
                        display: 'inline-block',
                    }}
                />
                {title}
            </div>
        )
    }
}

interface Item {
    title: string
    value: string
}

interface TagSelectProps {
    value?: string
    onChange?: (output: string) => void
    items: Array<Item>
    select?: 'ONE' | 'MANY'
}

export default class TagSelect extends React.Component<TagSelectProps> {
    state: any = { ready: false, items: {} }

    componentDidMount() {
        this.createSelections()
    }

    createSelections = () => {
        let newState = this.state as any
        Object.keys(this.props.items).forEach((key) => {
            newState.items[key] = false
        })

        if (this.props.value) {
            newState.items[this.props.value] = true
        }

        this.setState(newState)
    }

    handleTagSelect = (value: string) => {
        if (this.props.onChange) this.props.onChange(value)
        if (this.props.select === 'MANY') {
            this.setState({
                items: {
                    ...this.state.items,
                    [value]: !this.state.items[value],
                },
            })
        } else {
            //select is ONE or select is undefined
            let newState = this.state

            Object.keys(this.state.items).forEach((item: string) => {
                newState.items[item] = false
            })

            newState.items = {
                ...newState.items,
                [value]: !this.state.items[value],
            }

            this.setState(newState)
        }
    }

    render() {
        const { items } = this.props
        return (
            <div style={{ clear: 'both' }}>
                {items.map((item) => (
                    <TagSelectItem
                        title={item.title}
                        value={item.value}
                        selected={this.state.items[item.value]}
                        onClick={this.handleTagSelect}
                    />
                ))}
            </div>
        )
    }
}
