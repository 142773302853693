import React, { Component, ReactNode } from 'react'
import { globals } from '../utils/globals'
import { XCircleFill } from '@styled-icons/bootstrap/XCircleFill'
import { Circle } from '@styled-icons/bootstrap/Circle'
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill'
import { ExclamationCircleFill } from '@styled-icons/bootstrap/ExclamationCircleFill'
const DEBUG: boolean = false

export type InlineNotifType = 'success' | 'failure' | 'nutral' | 'dark'

export enum PrefabNotifications {
    PROMO_REMOVED,
    PROMO_ADDED,
    BILLED,
    PASSWORD_LENGTH,
    USER_EXISTS,
    FIRST_NAME_LENGTH_INVALID,
    LAST_NAME_LENGTH_INVALID,
    EMAIL_LENGTH_INVALID,
    ZIPCODE_LENGTH_INVALID,
    PASSWORD_LENGTH_INVALID,
}

interface NotifTemplate {
    Icon: ReactNode
    color: string
    typeface: Record<string, any>
}

interface Notif {
    type: InlineNotifType
    value: string
    width?: number
    widthIcon?: number
    height?: number
    title?: string
    style?: Record<string, any>
    addComponent?: {
        component: ReactNode
        width: number
        style?: Record<string, any>
    }
}

function getTemplate() {
    const nutral: NotifTemplate = {
        Icon: <Circle />,
        color: 'silver',
        typeface: {
            ...globals.typeface.defaultText,
            fontSize: 16,
            color: globals.colors.inspire.darknavy,
        },
    }

    const failure: NotifTemplate = {
        Icon: <XCircleFill />,
        color: globals.colors.negative.red,
        typeface: {
            ...globals.typeface.defaultText,
            fontSize: 16,
            color: globals.colors.negative.red,
        },
    }

    const dark: NotifTemplate = {
        Icon: <ExclamationCircleFill />,
        color: globals.colors.inspire.darknavy,
        typeface: {
            ...globals.typeface.defaultText,
            fontSize: 16,
            color: globals.colors.inspire.darknavy,
        },
    }

    const success: NotifTemplate = {
        Icon: <CheckCircleFill />,
        color: globals.colors.inspire.green,
        typeface: {
            ...globals.typeface.defaultText,
            fontSize: 16,
            color: globals.colors.inspire.green,
        },
    }

    return { success: success, failure: failure, nutral: nutral, dark: dark }
}

const TEMPLATE: any = getTemplate()

function renderIf(isRendered: boolean, component: ReactNode) {
    if (isRendered) return component
    return <></>
}

const PREFAB_LIBRARY: any = {
    USER_EXISTS: {
        width: 700,
        height: 30,
        value: 'Email already reistered to another user',
        type: 'nutral',
    },
    FIRST_NAME_LENGTH_INVALID: {
        width: 700,
        height: 30,
        value: 'First name requires more characters',
        type: 'nutral',
    },
    LAST_NAME_LENGTH_INVALID: {
        width: 700,
        height: 30,
        value: 'Last name requires more characters',
        type: 'nutral',
    },
    EMAIL_LENGTH_INVALID: {
        width: 700,
        height: 30,
        value: 'Email does not meet length criteria',
        type: 'nutral',
    },
    ZIPCODE_LENGTH_INVALID: {
        width: 700,
        height: 30,
        value: 'Zipcode must contain five numbers',
        type: 'nutral',
    },
    PASSWORD_LENGTH_INVALID: {
        width: 700,
        height: 30,
        value: 'Password requires more characters',
        type: 'nutral',
    },
}

export class MultilineNotifPresets extends Component<{
    prefabCodes: Array<PrefabNotifications>
    spacing?: number
    style?: Record<string, any>
}> {
    render() {
        const components: Array<Notif> = []

        this.props.prefabCodes.forEach((code: PrefabNotifications) => {
            if (
                code === PrefabNotifications.USER_EXISTS ||
                code === PrefabNotifications.FIRST_NAME_LENGTH_INVALID ||
                code === PrefabNotifications.LAST_NAME_LENGTH_INVALID ||
                code === PrefabNotifications.EMAIL_LENGTH_INVALID ||
                code === PrefabNotifications.ZIPCODE_LENGTH_INVALID ||
                code === PrefabNotifications.PASSWORD_LENGTH_INVALID
            ) {
                components.push(PREFAB_LIBRARY[PrefabNotifications[code]])
            }
        })

        return components.length < 1 ? (
            <></>
        ) : (
            <div style={{ ...this.props.style }}>
                {components.map((params: Notif) => {
                    return (
                        <div
                            style={{
                                marginBottom: this.props.spacing
                                    ? this.props.spacing
                                    : 0,
                                marginTop: this.props.spacing
                                    ? this.props.spacing
                                    : 0,
                            }}
                        >
                            <InlineNotif
                                width={params.width ? params.width : 700}
                                height={params.height ? params.height : 30}
                                value={params.value}
                                type={params.type}
                                title={params.title}
                                style={params.style}
                                addComponent={params.addComponent}
                                widthIcon={params.widthIcon}
                            ></InlineNotif>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export class MultilineNotif extends Component<{
    components: Array<Notif>
    spacing?: number
    style?: Record<string, any>
}> {
    render() {
        return this.props.components.length < 1 ? (
            <></>
        ) : (
            <div style={{ ...this.props.style }}>
                {this.props.components.map((params: Notif) => {
                    return (
                        <div
                            style={{
                                marginBottom: this.props.spacing
                                    ? this.props.spacing
                                    : 0,
                                marginTop: this.props.spacing
                                    ? this.props.spacing
                                    : 0,
                            }}
                        >
                            <InlineNotif
                                width={params.width ? params.width : 700}
                                height={params.height ? params.height : 30}
                                value={params.value}
                                type={params.type}
                                title={params.title}
                                style={params.style}
                                addComponent={params.addComponent}
                                widthIcon={params.widthIcon}
                            ></InlineNotif>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default class InlineNotif extends Component<{
    width?: number
    height?: number
    type?: InlineNotifType
    icon?: ReactNode
    value?: string
    title?: string
    style?: Record<string, any>
    widthIcon?: number
    addComponent?: {
        component: ReactNode
        width: number
        style?: Record<string, any>
    }
}> {
    state = {
        type: 'nutral',
    }

    componentDidMount() {}
    render() {
        const type: string = this.props.type ? this.props.type : this.state.type
        const color: string = TEMPLATE[type].color
        const h: number = this.props.height ? this.props.height : 30
        const w: number = this.props.width ? this.props.width : 700
        const x: number = 6
        const borderOffset: number = 3
        const widthIcon: number = this.props.widthIcon
            ? this.props.widthIcon
            : h
        const Icon = React.cloneElement(TEMPLATE[type].Icon, {
            width: 17,
            height: 17,
            color: color,
        })

        const componentWidth: number = this.props.addComponent
            ? this.props.addComponent.width
            : 0

        const component: ReactNode =
            this.props.addComponent && this.props.addComponent.component ? (
                this.props.addComponent.component
            ) : (
                <></>
            )

        return (
            <>
                <div
                    style={{
                        width: w,
                        height: h,
                        backgroundColor: 'white',
                        marginTop: 0,
                        border: `1px solid ${TEMPLATE[type].color}`,
                        borderRadius: 5,
                        ...this.props.style,
                    }}
                >
                    <div
                        style={{
                            width: x,
                            height: h,
                            backgroundColor: TEMPLATE[type].color,
                            display: 'inline-block',
                            borderRadius: `${2.5}px 0 0 ${2.5}px`,
                        }}
                    ></div>
                    <div
                        style={{
                            width: w - x,
                            height: h,
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }}
                    >
                        <div
                            style={{
                                verticalAlign: 'top',
                                display: 'inline-block',
                                width: widthIcon + borderOffset,
                                height: h,
                            }}
                        >
                            <div
                                style={{
                                    display: 'table-cell',
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                    backgroundColor: DEBUG
                                        ? 'purple'
                                        : 'transparent',
                                    width: widthIcon + borderOffset,
                                    height: h,
                                }}
                            >
                                {this.props.icon ? this.props.icon : Icon}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'inline-block',
                                width:
                                    w -
                                    x -
                                    widthIcon -
                                    borderOffset -
                                    componentWidth,
                                height: h,
                                backgroundColor: DEBUG
                                    ? 'purple'
                                    : 'transparent',
                            }}
                        >
                            <div
                                style={{
                                    ...TEMPLATE[type].typeface,
                                    color: color,
                                    display: 'table-cell',
                                    width:
                                        w -
                                        x -
                                        widthIcon -
                                        borderOffset -
                                        componentWidth,
                                    height: h,
                                    verticalAlign: 'middle',
                                    backgroundColor: DEBUG
                                        ? 'orange'
                                        : 'transparent',
                                }}
                            >
                                {renderIf(
                                    !!this.props.title,
                                    <strong>{this.props.title}&nbsp;</strong>
                                )}

                                {this.props.value}
                            </div>
                        </div>
                        {renderIf(
                            !!this.props.addComponent,
                            <div
                                style={{
                                    display: 'inline-block',
                                    width: componentWidth,
                                    height: h,
                                    backgroundColor: DEBUG
                                        ? 'purple'
                                        : 'transparent',
                                }}
                            >
                                <div
                                    style={{
                                        ...TEMPLATE[type].typeface,
                                        color: color,
                                        display: 'table-cell',
                                        width: componentWidth,
                                        height: h,
                                        fontWeight: 800,
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        backgroundColor: DEBUG
                                            ? 'lime'
                                            : 'transparent',
                                    }}
                                >
                                    {component}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )
    }
}
