import { Component } from 'react'

import Visa from '../images/card-visa.svg'
import Mastercard from '../images/card-master.svg'
import Amx from '../images/card-amx.svg'
import Discover from '../images/card-discover.svg'
import Jcb from '../images/card-jcb.svg'
import Diners from '../images/card-diners.svg'
import Stripe from '../images/card-powered-by-stripe.svg'

enum Sizes {
    DEFAULT_HEIGHT = 40,
    DEFAULT_WIDTH = 50,
}

export default class PaymentType extends Component<{
    width?: number | string
    height?: number
}> {
    state = {
        height: Sizes.DEFAULT_HEIGHT,
    }

    componentDidMount() {
        if (this.props.height)
            this.setState({
                height: this.props.height,
            })
    }
    renderCardLogo(logo: any, alt: string, style?: Record<string, any>) {
        return (
            <div
                style={{
                    display: 'table-cell',
                    height: this.state.height,
                    verticalAlign: 'middle',
                }}
            >
                <img
                    alt={alt}
                    src={logo}
                    height={style ? undefined : this.state.height}
                    style={{ ...style, marginRight: 5 }}
                />
            </div>
        )
    }

    render() {
        return (
            <div
                style={{
                    width: this.props.width ? this.props.width : '100%',
                    height: this.state.height,
                }}
            >
                <div
                    style={{
                        display: 'table-cell',
                        height: Sizes.DEFAULT_HEIGHT,
                        width: Sizes.DEFAULT_WIDTH,
                        verticalAlign: 'middle',
                        float: 'left',
                    }}
                >
                    {this.renderCardLogo(Visa, 'Visa')}
                    {this.renderCardLogo(Mastercard, 'Visa')}
                    {this.renderCardLogo(Amx, 'American Express')}
                    {this.renderCardLogo(Discover, 'Discover')}
                    {this.renderCardLogo(Jcb, 'JCB')}
                    {this.renderCardLogo(Diners, 'Diners')}
                </div>
                <div
                    style={{
                        float: 'right',
                    }}
                >
                    <div
                        style={{
                            display: 'absolute',
                            height: 0,
                            marginRight: -20,
                        }}
                    >
                        {this.renderCardLogo(Stripe, 'Powered by Stripe', {
                            height: 70,
                            width: 150,
                            marginTop: -22,
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
