import React from 'react'
import Modal from '../Modal'
import { IContributor } from './ESGCriterionFund'
import { Row, Col } from 'react-flexbox-grid'
import { config } from '../../models/config'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { withRouter, RouteComponentProps } from 'react-router-dom'

interface ContributingSecuritiesProps extends RouteComponentProps {
    isActive: boolean
    contributors: Array<IContributor>
    style?: Record<string, any>
    onClose: () => void
}

class ContributingSecurities extends React.Component<ContributingSecuritiesProps> {
    render() {
        return (
            <Modal isActive={this.props.isActive} onClose={this.props.onClose}>
                <div>
                    <Row
                        style={{
                            paddingRight: 6,
                            cursor: 'pointer',
                            backgroundColor: '#2C8AAE',
                            height: 30,
                            fontWeight: 800,
                            fontSize: 16,
                            paddingTop: 7,
                            color: 'white',
                        }}
                    >
                        <Col xs={11}>
                            <span>Contributing Securities</span>
                        </Col>
                        <Col
                            xs={1}
                            style={{ textAlign: 'right' }}
                            onClick={() => this.props.onClose()}
                        >
                            <span
                                style={{
                                    position: 'relative',
                                    bottom: 4,
                                }}
                            >
                                <CloseOutline style={{ width: 24 }} />
                            </span>
                        </Col>
                    </Row>
                </div>
                <div
                    style={{
                        height: 280,
                        padding: 0,
                        margin: 0,
                        overflowY: 'scroll',
                    }}
                >
                    {this.props.contributors.map(
                        (contributor: IContributor, index) => (
                            <Row
                                style={{
                                    cursor: 'pointer',
                                    padding: 10,
                                    backgroundColor:
                                        index % 2 === 0 ? '#e3eaef' : 'white',
                                    fontSize: 16,
                                }}
                                onClick={() => {
                                    this.props.history.push(
                                        `/${contributor.ticker}`
                                    )
                                }}
                            >
                                <Col xs={3}>
                                    <span>{contributor.ticker}</span>
                                </Col>
                                <Col>
                                    <span
                                        style={{
                                            textDecoration: 'underline',
                                            color: '#2C8AAE',
                                        }}
                                    >
                                        {contributor.name.length > 30
                                            ? contributor.name.slice(0, 30) +
                                              '...'
                                            : contributor.name}
                                    </span>
                                </Col>
                            </Row>
                        )
                    )}
                </div>
            </Modal>
        )
    }
}

export default withRouter(ContributingSecurities)
