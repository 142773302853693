import { ICustomProfile } from '../models/api'
import { Authority, AuthStatus } from '../models/state'

export const login = (
    token: string | null,
    isLogged: boolean,
    authStatus: string
) => {
    return {
        type: 'SIGNIN',
        token: token,
        isLogged: isLogged,
        authStatus: authStatus,
    }
}

export const logout = () => {
    return {
        type: 'SIGNOUT',
    }
}

export const setProfile = (profile: ICustomProfile) => {
    return { type: 'SET', profile: profile }
}

export const setAuthority = (authority: Array<Authority>) => {
    return { type: 'SETAUTH', authority: authority}
}

export const setAuthState = (authState: AuthStatus) => {
    return { type: authState }
}
