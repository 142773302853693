import { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import { Search } from '@styled-icons/evil/Search'
import { Filter } from '@styled-icons/bootstrap/Filter'
import { Tag } from '@styled-icons/bootstrap/Tag'
import { Gear } from '@styled-icons/evil/Gear'
import { LogOut } from '@styled-icons/feather/LogOut'
import { toast, ToastContainer } from 'react-toastify'
import { isMobile } from 'react-device-detect'

import { config } from '../../models/config'
import { store } from '../../store'
import { logout } from '../../actions'
import { CardText } from '@styled-icons/bootstrap/CardText'
import { BarChart2 } from '@styled-icons/feather/BarChart2'

import { useComponentVisible } from '../../utils/hooks'
import { fetchPlanTitle } from '../../utils/globals'
import { useHistory, withRouter } from 'react-router-dom'

const AccountMenu = ({ open, onClose, account }) => {
    const { ref, isComponentVisible } = useComponentVisible(true, onClose)
    const history = useHistory()
    return (
        <div>
            <ToastContainer
                position="bottom-center"
                closeOnClick
                hideProgressBar
            />
            {open ? (
                <div
                    ref={ref}
                    style={{
                        width: 310,
                        height: isMobile ? 400 : 315,
                        position: 'absolute',
                        backgroundColor: 'white',
                        right: 26,
                        borderBottomRightRadius: 4,
                        borderBottomLeftRadius: 4,
                    }}
                >
                    <div
                        style={{
                            height: 80,
                            backgroundColor: '#2c8aae',
                            borderTopRightRadius: 8,
                            padding: 6,
                            paddingLeft: 20,
                            borderTopLeftRadius: 8,
                            marginBottom: 10,
                        }}
                    >
                        <p
                            style={{
                                color: 'white',
                                fontWeight: 800,
                                fontSize: 25,
                                lineHeight: 0.1,
                            }}
                        >
                            Hi, {account.firstName}.
                        </p>
                        <span
                            style={{
                                color: 'white',
                                fontWeight: 800,
                                fontSize: 14,
                            }}
                        >
                            Current Plan: {fetchPlanTitle()}
                        </span>
                    </div>
                    {/* Note: The following section is not included in the template
                        but needs to be added.  */}
                    {isMobile && (
                        <>
                            <Row
                                style={{
                                    paddingLeft: 20,
                                    fontSize: 13,
                                    color: '#323841',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    history.push('/dashboard')
                                }}
                            >
                                <Col
                                    style={{ paddingTop: 10, paddingRight: 10 }}
                                >
                                    <CardText width={25} />
                                </Col>
                                <Col>
                                    <p>Dashboard</p>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    paddingLeft: 20,
                                    fontSize: 13,
                                    color: '#323841',
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    history.push('/portfolios')
                                    window.location.reload()
                                }}
                            >
                                <Col
                                    style={{ paddingTop: 10, paddingRight: 10 }}
                                >
                                    <BarChart2 width={25} />
                                </Col>
                                <Col>
                                    <p>Portfolios</p>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row
                        style={{
                            paddingLeft: 20,
                            fontSize: 13,
                            color: '#323841',
                            fontWeight: 600,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            history.push('/screening-profiles')
                        }}
                    >
                        <Col style={{ paddingTop: 10, paddingRight: 10 }}>
                            <Filter width={25} />
                        </Col>
                        <Col>
                            <p>Screening Profiles</p>
                        </Col>
                    </Row>
                    {/* <Row
                        style={{
                            paddingLeft: 20,
                            fontSize: 13,
                            color: '#323841',
                            cursor: 'pointer',
                            fontWeight: 600,
                        }}
                        onClick={() => {
                            history.push('/plans-and-pricing')
                        }}
                    >
                        <Col style={{ paddingTop: 10, paddingRight: 10 }}>
                            <Tag width={25} />
                        </Col>
                        <Col>
                            <p>Plans & Pricing</p>
                        </Col>
                    </Row> */}
                    <Row
                        style={{
                            paddingLeft: 20,
                            fontSize: 13,
                            color: '#323841',
                            fontWeight: 600,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            history.push('/account-settings')
                        }}
                    >
                        <Col style={{ paddingTop: 10, paddingRight: 10 }}>
                            <Gear width={25} />
                        </Col>
                        <Col>
                            <p>Account Settings</p>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            paddingLeft: 20,
                            fontSize: 13,
                            color: '#323841',
                            fontWeight: 600,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            store.dispatch(logout())
                            window.localStorage.clear()
                            history.push('/')
                            toast.info("You've been logged out.", {
                                onClose: () => {},
                            })
                        }}
                    >
                        <Col style={{ paddingTop: 10, paddingRight: 10 }}>
                            <LogOut width={25} />
                        </Col>
                        <Col>
                            <p>Logout</p>
                        </Col>
                    </Row>
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}

export default AccountMenu
