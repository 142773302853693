import app from './app.json'
import qa from './app-qa.json'
import container from './app-contianer.json';
import production from './app-production.json'
import remote from './app-remote.json'
import { RootConfig, AltConfig } from '../configModel'

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: 'AIzaSyDMzPkTzhJzARqfJh7R8-2h4-Xlqq_FWvo',
    authDomain: 'inspire-543e9.firebaseapp.com',
    projectId: 'inspire-543e9',
    storageBucket: 'inspire-543e9.appspot.com',
    messagingSenderId: '208775084014',
    appId: '1:208775084014:web:f428522547ff551873c0ff',
    measurementId: 'G-HZ6PMCCBNV',
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)

const qaProfile: AltConfig = qa
const productionProfile: AltConfig = production
const remoteProfile: AltConfig = remote
const containerProfile: AltConfig = container; 

let config: RootConfig = {} as RootConfig

type Profile = 'dev' | 'remote' | 'production' | 'qa' | 'container' | null | undefined

const createProfile = (root: RootConfig, target: any): RootConfig => {
    const defaultKeys = Object.keys(app)
    const targetKeys = Object.keys(target)
    const keyIntersect = defaultKeys.filter((key: string) =>
        targetKeys.includes(key)
    )

    let appParameters: any = app
    keyIntersect.forEach((key: string) => {
        appParameters[key] = target[key]
    })

    return appParameters
}

switch (process.env.REACT_APP_PROFILE as Profile) {
    case 'dev':
        config = app as RootConfig
        break
    case 'remote':
        config = createProfile(app, remoteProfile)
        break
    case 'qa':
        config = createProfile(app, qaProfile)
        break
    case 'container':
        config = createProfile(app, containerProfile)
        break
    default:
        config = createProfile(app, productionProfile)
        break
}

export { config, firebaseApp, analytics }
