import { Component } from 'react'
import { globals } from '../utils/globals'

export const enum CheckboxState {
    UNCHECKED,
    CHECKED,
}

type CheckboxTypes = 'default'

const TEMPLATE: any = {
    default: {
        container: {
            unchecked: {
                height: 10,
                width: 10,
                border: '2px solid black',
                backgroundColor: 'transparent',
            },
            checked: {
                height: 10,
                width: 10,
                border: '2px solid black',
                backgroundColor: globals.colors.positive.blue,
            },
        },
    },
}

export default class CheckboxCustom extends Component<{
    setToStateIndex?: (stateindex: number) => void
    isChecked?: boolean
    type?: CheckboxTypes
    onChange?: (checkboxState: number) => void
}> {
    state = {
        stateindex: CheckboxState.UNCHECKED,
        type: this.props.type ? this.props.type : 'default',
    }

    componentDidMount() {
        if (this.props.type)
            if (this.props.isChecked)
                this.setState({
                    stateindex: CheckboxState.CHECKED,
                })
    }

    render() {
        const type: string = this.state.type
        const toggle: string =
            this.state.stateindex === CheckboxState.CHECKED
                ? 'checked'
                : 'unchecked'

        return (
            <div
                onClick={() => {
                    const newIndex: number =
                        this.state.stateindex >= CheckboxState.CHECKED
                            ? CheckboxState.UNCHECKED
                            : CheckboxState.CHECKED

                    this.setState({
                        stateindex: newIndex,
                    })

                    if (this.props.setToStateIndex)
                        this.props.setToStateIndex(newIndex)

                    if (this.props.onChange) this.props.onChange(newIndex)
                }}
                style={TEMPLATE[type].container[toggle]}
            ></div>
        )
    }
}
