import React, { Component } from 'react'
import Footer from '../insightui/Footer'
import Header from '../insightui/Header'
import Sider from '../insightui/Sider'

import { Row } from 'react-flexbox-grid'

interface LayoutProps {
    sider?: boolean
    subscriberExempt?: boolean
}

export default class Layouts extends Component<LayoutProps> {
    render() {
        return (
            <div>
                {this.props.sider === false ? <></> : <Sider />}
                <Header
                    logo={!this.props.sider}
                    subscriberExempt={this.props.subscriberExempt}
                />
                <div
                    style={{
                        paddingTop: 80,
                    }}
                >
                    {this.props.children}
                </div>
            </div>
        )
    }
}
