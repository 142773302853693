import React from 'react'
import { IAccount } from '../../models/api'
import { Row, Col } from 'react-flexbox-grid'
import Button from '../../insightui/Button'
import { config } from '../../models/config'
import { isMobile, isTablet } from 'react-device-detect'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface AuthMenuProps extends RouteComponentProps {
    account: IAccount
    padding: number
    onClick: () => void
}
class AuthMenu extends React.Component<AuthMenuProps> {
    render() {
        const { account, padding } = this.props
        return (
            <Row
                style={{
                    paddingTop: padding,
                    position: 'absolute',
                    right: 0,
                    paddingRight: isMobile ? 15 : 30,
                }}
            >
                {(!isMobile || isTablet) && (
                    <>
                        <Col>
                            <Button
                                type="ghost"
                                onClick={() => {
                                    this.props.history.push('/dashboard')
                                }}
                            >
                                Dashboard
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="ghost"
                                onClick={() => {
                                    this.props.history.push('/portfolios')
                                    window.location.reload()
                                }}
                            >
                                Portfolios
                            </Button>
                        </Col>{' '}
                    </>
                )}

                <Col xs={2}>
                    {account.imageUrl ? (
                        <img
                            src={account.imageUrl}
                            width={40}
                            height={40}
                            style={{ borderRadius: '50%', cursor: 'pointer' }}
                            onClick={() => {
                                this.props.onClick()
                            }}
                            alt="profile"
                        />
                    ) : (
                        <div
                            style={{
                                borderRadius: 50,
                                paddingTop: 10,
                                height: 40,
                                width: 40,
                                backgroundColor: '#2c8aae',
                                textAlign: 'center',
                                display: 'block',
                                cursor: 'pointer',
                                boxSizing: 'border-box',
                                color: 'white',
                                fontWeight: 800,
                                fontSize: 18,
                            }}
                            onClick={() => {
                                this.props.onClick()
                            }}
                        >
                            <span>{account.firstName[0].toUpperCase()}</span>
                        </div>
                    )}
                </Col>
            </Row>
        )
    }
}

export default withRouter(AuthMenu)
