import React, { ReactNode, useState } from 'react'
import { css } from 'glamor'
import { Row, Col } from 'react-flexbox-grid'
import { globals } from '../utils/globals'
import DropdownMenu, { MenuItem } from '../insightui/DropdownMenu'
import chevronUp from '../images/chevron-up.svg'
import chevronDown from '../images/chevron-down.svg'
import { ArrowUp } from '@styled-icons/feather/ArrowUp'
import { ArrowDown } from '@styled-icons/feather/ArrowDown'
import { isMobile } from 'react-device-detect'
import { Edit } from '@styled-icons/feather/Edit'
import { ConsoleSqlOutlined } from '@ant-design/icons'

const DEBUG: boolean = false

enum Batch {
    small = 25,
    medium = 50,
    large = 100,
}

enum Margin {
    FOOTER_TOP_MARGIN = 30,
}

interface Header {
    title: string
    dataIndex: string
    key: string
    format?: (value: number | string | ReactNode) => number | string | ReactNode
    onChange?: (value: string) => void
    editable?: boolean
}

export interface TableParams {
    data?: any[]
    style?: Record<string, any>
}

const commonStyles: Record<string, any> = {
    textAlign: 'center',
    paddingTop: 15,
    height: 50,
    fontSize: globals.typeface.tableRow.fontSize,
    textTransform: 'uppercase',
}
const headerStyle: Record<string, any> = {
    ...commonStyles,
    ...globals.typeface.tableRow.odd,
    marginLeft: -1,
    width: '100%',
    fontWeight: 800,
}
const rowStyle: Record<string, any> = {
    ...commonStyles,
    fontWeight: 400,
}
interface TableProps {
    type?: string
    value?: string
    dataSource?: any[]
    headers: any[]
    index?: number
    total?: number
    batch?: number
    style?: Record<string, any>
    deletable?: boolean
    printing?: boolean
    styleHeader?: Record<string, any>
    styleBody?: Record<string, any>
    styleTitle?: Record<string, any>
    onRowEdit?: (e: any) => void
    onClick?: (input: Array<any>) => void
    hasPagination?: boolean
    isPortfolio?: boolean
    hasTitle?: boolean
    hasHeaders?: boolean
    rowColorScheme?: { even: string; odd: string }
}

interface sortableHeaderProps {
    headers: Array<String>
    onChange: (key: String, direction: 'ASC' | 'DSC') => void
    deletable?: boolean
    printing: boolean
}

function SortableHeader(props: sortableHeaderProps) {
    const { headers, onChange } = props
    const defaultState = Object.fromEntries(
        headers.map((_, i) => [headers[i], 0])
    )

    const [directions, setDirections] = useState(defaultState)

    function handleDirectionChange(header: String) {
        let value = directions[header as any]
        setDirections({
            ...defaultState,
            [header as any]: value === 2 ? 0 : (value += 1),
        })

        onChange(header, value === 1 ? 'ASC' : 'DSC')
    }

    return (
        <Row style={headerStyle}>
            {directions ? (
                <>
                    {headers.map((header: String) => (
                        <Col
                            xs
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDirectionChange(header as any)}
                        >
                            <span
                                style={{
                                    marginRight: 4,
                                    fontFamily: 'URW Geometric Cond',
                                    fontSize: 15,
                                    textTransform: 'capitalize',
                                }}
                            >
                                {header}
                            </span>
                            {directions[header as any] === 1 ? (
                                <ArrowUp size={15} />
                            ) : directions[header as any] === 2 ? (
                                <ArrowDown size={15} />
                            ) : (
                                <></>
                            )}
                        </Col>
                    ))}
                    {props.deletable && !props.printing && !isMobile && (
                        <Col xs />
                    )}
                </>
            ) : (
                <></>
            )}
        </Row>
    )
}

export default class Table extends React.Component<TableProps> {
    state = {
        index: 0,
        total: 0,
        batch: this.initBatch(),
        data: [],
        value: [],
        headers: [],
        sortedHeader: this.props.isPortfolio ? 'asset' : 'holdingPercentage', //default sorts
        sortedHeaderDirection: 'DSC',
    }

    componentDidMount() {
        const batch: number = this.state.batch
        const headers: string[] = this.getHeaders()
        const dataSource: any = this.getDataSource()
        const index: number = this.props.index ? this.props.index : 0
        const total: number = dataSource.ids ? dataSource.ids.length : 0
        const value: any[] = this.getCurrent({
            batch: batch,
            dataSource: dataSource,
            headers: headers,
        })

        this.setState({
            headers: headers,
            data: dataSource,
            batch: batch,
            total: total,
            index: index,
            value: value,
        })
    }

    fetchDataIndexFromTitle = (title: string) => {
        let dataIndex = this.state.sortedHeader
        this.props.headers.forEach((header) => {
            if (header.title === title) {
                dataIndex = header.dataIndex
            }
        })
        return dataIndex
    }

    sort = (rows: any): Row[] => {
        console.log(this.state)
        let direction = this.state.sortedHeaderDirection
        let header = this.fetchDataIndexFromTitle(this.state.sortedHeader)

        const dataSource = Object.values(this.getDataSource())
        // console.log(dataSource)

        let data: any = this.getDataSource()
        delete data.ids

        let resultSet = Object.values(data).sort(function (r1: any, r2: any) {
            if (r1[header] < r2[header]) {
                return direction === 'ASC' ? -1 : 1
            }
            if (r1[header] > r2[header]) {
                return direction === 'ASC' ? 1 : -1
            }
            return 0
        }) as Array<Row>
        return resultSet.slice(
            this.state.index,
            this.state.index + this.state.batch
        )
    }

    renderTable(params: any) {
        const headerStyle: Record<string, any> = params.headerStyle
            ? params.headerStyle
            : {}
        const rowStyle: Record<string, any> = params.rowStyle
            ? params.rowStyle
            : {}

        const oddColor: string = this.props.rowColorScheme
            ? this.props.rowColorScheme.odd
            : globals.typeface.tableRow.odd.backgroundColor

        const evenColor: string = this.props.rowColorScheme
            ? this.props.rowColorScheme.even
            : globals.typeface.tableRow.even.backgroundColor

        const bgColor: string = this.props.rowColorScheme
            ? this.props.rowColorScheme.odd
            : 'white'

        const style: Record<string, any> = {
            backgroundColor: bgColor,
            minHeight: 300,
        }

        const rows: any[] = this.state.value

        let isEven: boolean,
            index: number = 0

        const headers: string[] = this.props.headers.map((item: Header) => {
            return item.title
        })

        return (
            <div style={style}>
                {this.props.hasHeaders === false ? (
                    <></>
                ) : this.state.headers.length ? (
                    <SortableHeader
                        onChange={(key, direction) =>
                            this.setState({
                                sortedHeader: key,
                                sortedHeaderDirection: direction,
                            })
                        }
                        printing={this.props.printing}
                        deletable={this.props.deletable}
                        headers={headers}
                    />
                ) : (
                    <></>
                )}
                {this.sort(rows).map((row: any) => {
                    isEven = index++ % 2 === 0 ? true : false

                    if (this.containsKey(row, 'values')) return <></>

                    const data: any[] = Object.keys(row)
                        .filter((item) => item !== 'params')
                        .map((val) => {
                            return row[val]
                        })

                    return this.renderRowComponent({
                        data: data,
                        headers: this.props.headers,
                        style: {
                            ...rowStyle,
                            marginLeft: 0,
                            width: '100%',
                            ...{
                                backgroundColor: isEven ? evenColor : oddColor,
                            },
                        },
                        params: row.params ? row.params : {},
                    })
                })}
            </div>
        )
    }

    containsKey(data: any, key: string) {
        let match: boolean = false
        if (data)
            Object.keys(data).forEach((fieldName: string) => {
                if (fieldName === key) match = true
            })
        return match
    }

    getStyleContainer() {
        let overrideStyles: Record<string, any> = {
            ...(this.props.style ? this.props.style : {}),
        }
        let type = this.props.type ? this.props.type : 'data'
        if (type === 'data')
            return css({
                width: '100%',
                height: 'auto',
                ...globals.styles.presets.noselect,
                ...overrideStyles,
            })
        else return css({})
    }

    getStyleTitle() {
        let overrideStyles: Record<string, any> = {
            ...(this.props.styleBody ? this.props.styleBody : {}),
        }

        let type = this.props.type ? this.props.type : 'data'
        if (type === 'data')
            return css({
                width: '100%',
                height: 50,
                fontWeight: 900,
                backgroundColor: 'rgb(247, 247, 247)',
                ...overrideStyles,
            })
        else return css({})
    }

    getStyleBody() {
        let overrideStyles: Record<string, any> = {
            ...(this.props.styleBody ? this.props.styleBody : {}),
        }

        let type = this.props.type ? this.props.type : 'data'
        if (type === 'data')
            return css({
                width: '100%',
                height: 'auto',
                fontWeight: 900,
                ...overrideStyles,
            })
        else return css({})
    }

    getStyleCell() {
        const style: Record<string, any> = css({
            height: 20,
        })
        return style
    }

    getTitleComponent(title?: string) {
        if (this.props.hasTitle === false) return <></>
        let overrideStyles: Record<string, any> = {
            ...(this.props.styleHeader ? this.props.styleHeader : {}),
        }
        let type = this.props.type ? this.props.type : 'data'
        const styleHeader: Record<string, any> =
            type === 'data'
                ? css({
                      ...globals.typeface.spacedUppercase,
                      padding: '15px 25px',
                      backgroundColor: globals.colors.positive.blue,
                      color: 'white',
                      ...overrideStyles,
                  })
                : css({})
        const titleStyle: Record<string, any> = css({
            color: 'white',
            fontWeight: '1000',
            fontSize: '18px',
            margin: 0,
            letterspacing: 0,
            fontFamily: `'URW Geometric Ext', 'Helvetica', 'Arial', sans-serif`,
        })
        return (
            <div {...styleHeader}>
                <p {...titleStyle}>
                    <span>{title}</span>
                </p>
            </div>
        )
    }

    formatTableNumber(value: number) {
        const polarity: string = value < 0 ? '-' : ''

        const valueString: string =
            polarity +
            '$' +
            Math.abs(value)
                .toFixed(0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return valueString
    }

    renderColumnCell(value: string, onChange?: (e: any) => void) {
        return (
            <Col
                xs
                contentEditable={onChange ? true : false}
                key={globals.util.getRandomString()}
                onKeyUpCapture={onChange}
            >
                {value}
            </Col>
        )
    }

    compileColumns(headers: Header[], data: any[], params: any) {
        let maxLen: number = 40
        let cols: ReactNode[] = []
        let val: string
        let datum: any

        let noformat = (value: number | string | ReactNode) => {
            return value
        }

        const functions: Array<any> = []
        for (let i = 0; i < headers.length; i++) {
            if (headers[i].format) functions.push(headers[i].format)
            else functions.push(noformat)
        }

        const isConcatToSingleColumn: boolean = params.isSingleColumn
            ? params.isSingleColumn
            : false

        let header: any

        if (!isConcatToSingleColumn) {
            for (let i = 0; i < data.length; i++) {
                datum = data[i]
                val = functions[i](datum)
                cols.push(
                    this.renderColumnCell(
                        val,
                        headers[i].onChange
                            ? (e: any) => {
                                  e.preventDefault()
                                  if (e.code === 'Enter') {
                                      header = headers[i]

                                      let rowData: any = {}
                                      Object.keys(data).forEach(
                                          (index: any) => {
                                              const headerName: string =
                                                  this.state.headers[
                                                      index as number
                                                  ]

                                              const value: string =
                                                  data[index as number]

                                              rowData = {
                                                  ...rowData,
                                                  [headerName]: value,
                                              }
                                          }
                                      )
                                      header.onChange(
                                          e.target.innerText,
                                          rowData
                                      )
                                  }
                              }
                            : undefined
                    )
                )
            }

            if (this.props.isPortfolio && !this.props.printing && !isMobile) {
                cols.push(
                    <Col
                        xs
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.props.onRowEdit(data)}
                    >
                        <Edit width={20} />
                    </Col>
                )
            }
            return cols
        } else if (isConcatToSingleColumn) {
            for (let i = 0; i < data.length; i++) {
                datum = data[i]
                cols.push(functions[i](datum))
            }
            return (
                <Col xs key={globals.util.getRandomString()}>
                    {cols}
                </Col>
            )
        }

        return cols
    }

    renderRowComponent(params: any) {
        const data: any[] = params && params.data ? params.data : []

        const overrides: Record<string, any> =
            params && params.style ? params.style : {}

        const headers: Header[] = params.headers

        let style: Record<string, any> = {
            ...overrides,
        }

        if (params.params.rowStyle)
            style = { ...style, ...params.params.rowStyle }

        const component: ReactNode = (
            <Row
                {...css(style)}
                onClick={() => {
                    if (this.props.onClick) this.props.onClick(data)
                }}
            >
                {this.compileColumns(headers, data, params.params)}
            </Row>
        )
        return component
    }

    renderFooterComponent() {
        let hasPagination: boolean = this.props.hasPagination !== false

        const batch: number = this.getBatchSize()
        const index: number = Math.ceil(this.state.index / batch)
        const total: number = Math.ceil(this.state.total / batch)
        const marginH: number = 100
        const containerStyle: Record<string, any> = css({
            width: '100%',
            height: 100,
            textAlign: 'center',
            margin: 0,
            padding: 0,
        })

        const commonStyle: Record<string, any> = {
            display: 'inline-block',
            //backgroundColor: 'whitesmoke',
            height: 100,
            width: 150,
            fontSize: 14,
            verticalAlign: 'top',
        }

        const intervalDropdown: Record<string, any> = css({
            ...commonStyle,
            float: 'left',
            width: 160,
            textAlign: 'left',
            verticalAlign: 'top',
        })

        const footerNav: Record<string, any> = css({
            ...commonStyle,
            float: 'right',
            //backgroundColor: 'red',
            width: 250,
        })

        const footerTextStyle: Record<string, any> = css({
            marginTop: Margin.FOOTER_TOP_MARGIN,
            display: 'inline-block',
            verticalAlign: 'top',
            float: 'left',
        })

        function renderDropdown(This: any) {
            const style: Record<string, any> = {
                borderRadius: 0,
                display: 'inline-block',
                verticalAlign: 'top',
                marginTop: Margin.FOOTER_TOP_MARGIN,
                borderColor: 'transparent',
                marginLeft: 0,
                paddingLeft: 0,
            }

            const dataSize: number =
                This.props.dataSource && This.props.dataSource.length
                    ? This.props.dataSource.length
                    : 0

            const smallestBatch: number =
                dataSize < Batch.small
                    ? This.props.dataSource.length
                    : Batch.small

            const isMedium: boolean = dataSize >= Batch.medium
            const isLarge: boolean = dataSize >= Batch.large

            return (
                <>
                    <p {...footerTextStyle}></p>
                    <DropdownMenu
                        //initial
                        //params={{}}
                        value={smallestBatch + ' Results'}
                        width={150}
                        height={40}
                        fontMarginTop={12}
                        style={{ ...style }}
                    >
                        {getIf(true, () => {
                            return (
                                <MenuItem
                                    value={
                                        smallestBatch.toString() + ' Results'
                                    }
                                    onClick={() => {
                                        setBatchState(smallestBatch)
                                    }}
                                ></MenuItem>
                            )
                        })}

                        {getIf(isMedium, () => {
                            return (
                                <MenuItem
                                    value={Batch.medium.toString() + ' Results'}
                                    onClick={() => {
                                        setBatchState(Batch.medium)
                                    }}
                                ></MenuItem>
                            )
                        })}

                        {getIf(isLarge, () => {
                            return (
                                <MenuItem
                                    value={Batch.large.toString() + ' Results'}
                                    onClick={() => {
                                        setBatchState(Batch.large)
                                    }}
                                ></MenuItem>
                            )
                        })}
                    </DropdownMenu>
                </>
            )

            function setBatchState(val: number) {
                const value: any = This.getCurrent({ batch: val })
                const newState: any = {
                    batch: val,
                    index: 0,
                    value: value,
                }
                This.setState(newState)
            }

            function getIf(bool: boolean, fcn: any) {
                if (!bool) return <></>
                return fcn()
            }
            function addIf(bool: boolean, count: number) {
                if (!bool) return count
                return count + 1
            }
        }
        const w: number = 120

        if (hasPagination && !this.props.printing)
            return (
                <div {...containerStyle}>
                    <Row>
                        <Col xs={6} sm={3}>
                            <div {...intervalDropdown}>
                                {renderDropdown(this)}
                            </div>
                        </Col>
                        <Col xs={6} sm={9} style={{ paddingTop: 10 }}>
                            <div {...footerNav}>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                    }}
                                >
                                    {this.renderChevronLeft()}
                                </div>
                                <div
                                    style={{
                                        //backgroundColor: 'yellow',
                                        width: w,
                                        height: 50,
                                        display: 'inline-block',
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            //backgroundColor: 'green',
                                            display: 'inline-block',
                                            width: w,
                                            height: 50,
                                            marginLeft: -60,
                                        }}
                                    >
                                        {
                                            <p
                                                style={{
                                                    ...footerTextStyle,
                                                    position: 'absolute',
                                                    display: 'inline-block',
                                                    //backgroundColor: 'blue',
                                                    height: 'auto',
                                                    width: '100%',
                                                    marginLeft: -60,
                                                    marginTop: 20,
                                                }}
                                            >
                                                Page {total > 0 ? index + 1 : 0}{' '}
                                                of {total}
                                            </p>
                                        }
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        marginTop: 20,
                                    }}
                                >
                                    <div>{this.renderChevronRight()}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        return <></>
        function callIf(bool: boolean, fcn: any) {
            if (!bool) return
            return fcn()
        }
    }

    renderChevronLeft() {
        function callIf(bool: boolean, fcn: any) {
            if (!bool) return
            return fcn()
        }
        return (
            <img
                style={{
                    transform: 'rotate(90deg)',
                    height: 40,
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    marginTop: 20,
                }}
                alt=""
                src={chevronDown}
                onClick={() => {
                    callIf(this.hasMore(), () => this.getNext())
                }}
            />
        )
    }

    renderChevronRight() {
        function callIf(bool: boolean, fcn: any) {
            if (!bool) return
            return fcn()
        }
        return (
            <img
                style={{
                    transform: 'rotate(90deg)',
                    height: 40,
                    cursor: 'pointer',
                    backgroundColor: 'white',
                }}
                alt=""
                src={chevronUp}
                onClick={() => {
                    callIf(this.hasMore(), () => this.getPrev())
                }}
            />
        )
    }

    hasMore() {
        const dataLen: number = this.props.dataSource
            ? this.props.dataSource.length
            : 0
        return this.state.batch < dataLen
    }

    initBatch() {
        const dataSize: number = this.props.dataSource
            ? this.props.dataSource.length
            : Batch.small
        const batchSize: number =
            dataSize < Batch.small ? dataSize : Batch.small
        if (this.props.printing) {
            return 15
        } else return batchSize
    }

    getBatchSize() {
        if (this.state.batch) return this.state.batch
        return Batch.small
    }

    getSubarray(array: ReactNode[], index1: number, index2: number) {
        let build: any[] = []
        for (let i = index1; i < index2; i++) {
            build.push(array[i])
        }
        return build
    }

    fetchData(index1: number, index2: number, data: ReactNode[]) {
        // eventually check of the data has already been stored
        // if not then grab from server, else return local
        return this.getSubarray(data, index1, index2)
    }

    applyUniformRowsFix(params: any) {
        /*
        inserts n number of empty blank rows on final page, for example, 
        if the number of available rows is less than the batch size. This
        is necessary to prevent the height (auto) attribute from going
        bonkers.
        */
        const value: ReactNode[] = params.value ? params.value : 0
        const batch: number = params.batch ? params.batch : this.getBatchSize()
        const cols: number = this.state.headers.length
        let buffer: any[]
        while (value.length < batch) {
            buffer = []
            for (let i = 0; i < cols; i++) {
                buffer.push('')
            }
            value.push({ values: buffer })
        }
        return value
    }

    getPrevIndex(state: any) {
        const nextIndex: number = Math.min(
            state.index + state.batch,
            state.total
        )
        const index1: number = nextIndex < state.total ? nextIndex : 0
        const index2: number =
            nextIndex < state.total
                ? Math.min(index1 + state.batch, state.total)
                : state.batch
        logIndices(DEBUG, index1, index2)
        return { index: index1, length: index2 }
    }

    getNextIndex(state: any) {
        const prevIndex: number = state.index - state.batch
        const index1: number = prevIndex < 0 ? state.total : prevIndex
        const index2: number = Math.min(index1 + state.batch, state.total)
        return { index: index1, length: index2 }
    }

    getNext() {
        const Next: any = this.getNextIndex(this.state)
        const output: any[] = this.getTraversal({ Traversal: Next })
        this.setState({
            value: output,
            index: Next.index,
        })

        return output
    }

    getPrev() {
        const Prev: any = this.getPrevIndex(this.state)
        const output: any[] = this.getTraversal({ Traversal: Prev })
        this.setState({
            value: output,
            index: Prev.index,
        })
    }

    getCurrent(params: any) {
        const data: string[] =
            params && params.dataSource ? params.dataSource : this.state.data

        const headers: string[] = params.headers
            ? params.headers
            : this.state.headers
        const batch: number = params.batch ? params.batch : this.state.batch
        const Current: any = {
            index: 0,
            length: batch,
        }
        const output: any[] = this.getTraversal({
            dataSource: data,
            headers: headers,
            batch: batch,
            Traversal: Current,
        })
        return output
    }

    getTraversal(params: any) {
        const batch: number = params.batch ? params.batch : this.state.batch
        const headers: string[] = params.headers
            ? params.headers
            : this.state.headers
        const Traversal: any = params.Traversal
            ? params.Traversal
            : { index: 0, length: batch }
        const data: any = params.dataSource
            ? params.dataSource
            : this.state.data
        const ids: any[] = this.fetchData(
            Traversal.index,
            Traversal.length,
            data.ids
        )
        const output: ReactNode[] = this.getDataByIds(ids, headers, data)
        //const patchedOutput: ReactNode[] = this.applyUniformRowsFix({
        //    value: output,
        //    batch: batch,
        //})

        return output
    }

    getHeaders() {
        const headers: string[] = this.props.headers.map((datum: Header) => {
            return datum.dataIndex
        })
        return headers
    }

    toObject(keys: string[], rowAsJson?: any, id?: string) {
        let buffer: any = {},
            key: string | number

        for (let j = 0; j < keys.length; j++) {
            key = keys[j]
            buffer[key] = rowAsJson ? rowAsJson[key] : id
        }
        if (rowAsJson.params) buffer.params = rowAsJson.params
        return buffer
    }

    appendToObject(
        data: { [name: string]: any },
        values: any[],
        keys: string[],
        index: number
    ) {
        const id: string = `id-${index}`
        const rowAsJson: {}[] = values[index]
        data[id] = this.toObject(keys, rowAsJson, id)
        if (!data.ids) data['ids'] = []
        data.ids.push(id)
        if (DEBUG) console.log('added: ' + id + '...')
        if (DEBUG) console.log(data[id])
        return data
    }

    getDataSource(/*headers: string[]*/) {
        const headers: string[] = this.props.headers.map((item: Header) => {
            return item.dataIndex
        })

        const data: {}[] = this.props.dataSource as { [name: string]: object }[]
        let dataSource: { [name: string]: object } = {}
        let keys: string[] = [...headers]
        for (let i = 0; i < data.length; i++) {
            dataSource = this.appendToObject(dataSource, data, keys, i)
        }

        return dataSource
    }

    getDataByIds(
        ids: string[],
        headers: string[],
        data: { [name: string]: any }
    ) {
        let output: ReactNode[] = []
        let id: string, row: any, key: string, val: string
        ids = ids.filter((element) => element !== undefined)
        for (let i = 0; i < ids.length; i++) {
            id = ids[i]
            row = {}
            for (let j = 0; j < headers.length; j++) {
                key = headers[j]
                val = data[id][key]
                row = {
                    ...row,
                    [key]: val,
                }
            }
            if (data[id]['params']) {
                row = {
                    ...row,
                    ['params']: data[id]['params'],
                }
            }
            output.push(row)
        }

        return output
    }

    render() {
        const TitleComponent: ReactNode = this.getTitleComponent(
            this.props.value
        )
        const style: Record<string, any> = this.getStyleContainer()
        const styleBody: Record<string, any> = this.getStyleBody()

        return (
            <div {...style}>
                {TitleComponent}
                <div className="condensed-font-style">
                    {this.renderTable({
                        headerStyle: headerStyle,
                        rowStyle: rowStyle,
                    })}
                </div>
                <div {...styleBody}>{this.renderFooterComponent()}</div>
            </div>
        )
    }
}

function logIndices(override: boolean, index1: number, index2: number) {
    if (override || DEBUG) console.log('setting: ' + index1 + ',' + index2)
}
