import { UploadCloud } from '@styled-icons/feather/UploadCloud'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../insightui/Button'
import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { store } from '../../store'
import axios from 'axios'
import { config } from '../../models/config'
import { IAccount } from '../../models/api'
import { CloseO } from '@styled-icons/evil/CloseO'

import Modal from '../../components/Modal'
import Dropzone from 'react-dropzone'
import PropagateLoader from 'react-spinners/PropagateLoader'
import complete from '../../images/complete.png'

import { isMobile } from 'react-device-detect'
import { firebaseApp } from '../../models/config'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const storage = getStorage(firebaseApp)

export default class Account extends React.Component<{ account: IAccount }> {
    state: {
        account: IAccount | null
        currentPassword: string
        newPassword: string
        newPasswordConfirm: string
        upload: boolean
        uploading: 'in-progress' | 'failed' | 'completed' | 'waiting'
        uploadingResponse: any
    } = {
        account: null,
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        upload: false,
        uploading: 'waiting',
        uploadingResponse: null,
    }

    componentDidMount() {
        if (!this.state.account) {
            this.setState({ account: this.props.account })
        }
    }

    notify = (
        message: string,
        type: 'success' | 'failure',
        callback?: () => void
    ) => {
        if (type === 'success') {
            toast.success(message, {
                onClose: () => (callback ? callback() : null),
            })
        } else {
            toast.error(message, {
                onClose: () => (callback ? callback() : null),
            })
        }
    }

    updateAccount = (skipreload?: boolean) => {
        axios
            .put(`${config.api}/api/account`, this.state.account, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response: any) => {
                this.notify(
                    'Account Updated',
                    'success',
                    () => !skipreload && window.location.reload()
                )
            })
            .catch((error) =>
                this.notify(
                    'Account updated failed. Please try again',
                    'failure'
                )
            )
    }

    resetPassword = () => {
        if (
            this.state.newPassword === this.state.newPasswordConfirm &&
            this.state.newPassword.length > 5
        ) {
            axios
                .put(
                    `${config.api}/api/account/change-password`,
                    {
                        currentPassword: this.state.currentPassword,
                        newPassword: this.state.newPasswordConfirm,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${store.getState().token}`,
                        },
                    }
                )
                .then((response: any) => {
                    this.notify('Password has been updated', 'success')
                    this.setState({ currentPassword: '' })
                    this.setState({ newPassword: '' })
                    this.setState({ newPasswordConfirm: '' })
                })
                .catch((error) => {
                    this.notify(
                        'Password update failed, check password and try again later',
                        'failure'
                    )
                })
        } else {
            this.notify('Passwords do not match', 'failure')
        }
    }

    render() {
        const { account } = this.props
        return (
            <div>
                <Modal isActive={this.state.upload} width={500}>
                    <Row
                        style={{
                            justifyContent: 'right',
                            flexDirection: 'row',
                            marginBottom: 25,
                        }}
                    >
                        <CloseO
                            width={25}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                this.setState({
                                    upload: false,
                                    uploading: 'waiting',
                                })
                            }
                        />
                    </Row>
                    {this.state.uploading === 'in-progress' ? (
                        <div
                            style={{
                                textAlign: 'center',
                                width: '100%',
                                paddingBottom: 50,
                            }}
                        >
                            <p>Processing</p>
                            <PropagateLoader loading={true} size={20} />
                        </div>
                    ) : (
                        <></>
                    )}
                    {this.state.uploading === 'completed' ? (
                        <div
                            style={{
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            <img src={complete} width={165} alt="Upload" />
                            <h2 style={{ fontWeight: 400 }}>Upload Complete</h2>
                        </div>
                    ) : (
                        <></>
                    )}
                    {this.state.uploading === 'waiting' ||
                    this.state.uploading === 'failed' ? (
                        <>
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    this.setState({
                                        uploading: 'inprogress',
                                    })
                                    const file = acceptedFiles[0]

                                    const reference = ref(
                                        storage,
                                        `${account.firstName.toLowerCase()}-${account.lastName.toLowerCase()}-${
                                            account.id
                                        }`
                                    )

                                    uploadBytes(reference, file).then(
                                        (snapshot: any) => {
                                            getDownloadURL(reference).then(
                                                (url) => {
                                                    this.setState({
                                                        account: {
                                                            ...this.state
                                                                .account,
                                                            imageUrl: url,
                                                        },
                                                    })
                                                    this.updateAccount()
                                                    this.setState({
                                                        uploading: 'completed',
                                                        upload: false,
                                                    })
                                                }
                                            )
                                        }
                                    )
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div
                                            {...getRootProps()}
                                            style={{
                                                width: '100%',
                                                height: 200,
                                                border: '2px dashed #3c90ba',
                                                backgroundColor: '#e8f1f7',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                                color: '#3c90ba',
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <p
                                                style={{
                                                    marginTop: '17%',
                                                }}
                                            >
                                                Drag and drop picture here, or
                                                click here to import
                                            </p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: 30,
                                }}
                            >
                                {this.state.uploading === 'failed' ? (
                                    <p style={{ color: '#d55268' }}>
                                        Upload failed. Please try again
                                    </p>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </Modal>
                {this.state.account ? (
                    <div>
                        <ToastContainer
                            position="bottom-center"
                            hideProgressBar
                        />
                        <Row
                            style={{
                                borderBottom: '1px solid #eff7fb',
                                paddingBottom: '25px',
                            }}
                        >
                            <div
                                style={{
                                    float: 'left',
                                    margin: '0 25px 15px 0',
                                }}
                            >
                                {account.imageUrl ? (
                                    <img
                                        src={account.imageUrl}
                                        width={80}
                                        height={80}
                                        style={
                                            isMobile
                                                ? {
                                                      display: 'block',
                                                      borderRadius: '50%',
                                                      width: '50',
                                                      height: '50',
                                                  }
                                                : { borderRadius: '50%' }
                                        }
                                        alt="profile"
                                    />
                                ) : (
                                    <div
                                        style={{
                                            width: 80,
                                            height: 47,
                                            borderRadius: '50%',
                                            textAlign: 'center',
                                            backgroundColor: '#2c8aae',
                                            paddingTop: 33,
                                            color: 'white',
                                            fontSize: 40,
                                            lineHeight: 0.5,
                                            fontWeight: 600,
                                            display: 'block',
                                        }}
                                    >
                                        {this.state.account.firstName[0]
                                            ? this.state.account.firstName[0].toUpperCase()
                                            : ''}
                                    </div>
                                )}
                            </div>
                            <Col style={{}}>
                                <Row>
                                    <span
                                        style={{
                                            fontSize: 20,
                                            fontWeight: 700,
                                        }}
                                    >
                                        Profile Picture
                                    </span>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col>
                                        <Button
                                            type="primary"
                                            style={{
                                                width: 150,
                                                backgroundColor: '#2c8aae',
                                                border: 0,
                                                marginRight: '10px',
                                            }}
                                            onClick={() =>
                                                this.setState({ upload: true })
                                            }
                                        >
                                            <UploadCloud
                                                width={20}
                                                style={{ marginRight: 10 }}
                                            />
                                            Upload
                                        </Button>
                                        {this.state.account.imageUrl ? (
                                            <Button
                                                type="primary"
                                                style={{
                                                    width: 140,
                                                    backgroundColor: '#2c8aae',
                                                    border: 0,
                                                    height: 50,
                                                    marginTop: isMobile
                                                        ? 15
                                                        : 0,
                                                }}
                                                onClick={() => {
                                                    let newState = this.state
                                                    if (newState.account) {
                                                        newState.account.imageUrl =
                                                            null
                                                        this.setState(newState)
                                                        this.updateAccount()
                                                    }

                                                    // this.updateAccount()
                                                }}
                                            >
                                                Reset Image
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.account.affiliate && (
                                <>
                                    Affiliate Code:{' '}
                                    {this.state.account.affiliate}
                                </>
                            )}
                        </Row>
                        <div>
                            <Col xs={12} sm={12} md={10} lg={10}>
                                <Row
                                    style={{
                                        paddingTop: 30,
                                        paddingBottom: 20,
                                        position: 'relative',
                                    }}
                                >
                                    <Col>
                                        <span
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 20,
                                                position: 'relative',
                                                top: '5px',
                                            }}
                                        >
                                            Account {isMobile ? '' : 'Info'}
                                        </span>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="mini"
                                            onClick={() => this.updateAccount()}
                                            style={{
                                                position: 'absolute',
                                                right: '0',
                                            }}
                                        >
                                            Save {isMobile ? '' : 'Changes'}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        lg={6}
                                        xs={6}
                                        style={{ paddingRight: 15 }}
                                    >
                                        <input
                                            style={{
                                                backgroundColor: '#e3eaef',
                                                border: 'none',
                                                borderRadius: 8,
                                                width: '100%',
                                                height: 30,
                                                padding: 10,
                                                color: 'black',
                                            }}
                                            value={this.state.account.firstName}
                                            onChange={(e) =>
                                                this.setState({
                                                    account: {
                                                        ...this.state.account,
                                                        firstName:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                            placeholder="First Name"
                                        />
                                    </Col>
                                    <Col lg={6} xs={6} style={{}}>
                                        <input
                                            style={{
                                                backgroundColor: '#e3eaef',
                                                border: 'none',
                                                borderRadius: 8,
                                                width: '100%',
                                                height: 30,
                                                padding: 10,
                                            }}
                                            placeholder="Last Name"
                                            value={this.state.account.lastName}
                                            onChange={(e) =>
                                                this.setState({
                                                    account: {
                                                        ...this.state.account,
                                                        lastName:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col xs={12}>
                                        <input
                                            style={{
                                                backgroundColor: '#e3eaef',
                                                border: 'none',
                                                borderRadius: 8,
                                                width: '100%',
                                                height: 30,
                                                padding: 10,
                                            }}
                                            placeholder="Email"
                                            value={this.state.account.email}
                                            onChange={(e) =>
                                                this.setState({
                                                    account: {
                                                        ...this.state.account,
                                                        email: e.target.value,
                                                    },
                                                })
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <div>
                            <Col xs={12} sm={12} md={10} lg={10}>
                                <Row
                                    style={{
                                        paddingTop: 30,
                                        paddingBottom: 20,
                                        position: 'relative',
                                    }}
                                >
                                    <Col>
                                        <span
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 20,
                                                position: 'relative',
                                                top: '5px',
                                            }}
                                        >
                                            Password
                                        </span>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="mini"
                                            onClick={() => this.resetPassword()}
                                            style={{
                                                position: 'absolute',
                                                right: '0',
                                            }}
                                        >
                                            Save {isMobile ? '' : 'Changes'}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <input
                                            style={{
                                                backgroundColor: '#e3eaef',
                                                border: 'none',
                                                borderRadius: 8,
                                                width: '100%',
                                                height: 30,
                                                padding: 10,
                                            }}
                                            type="password"
                                            placeholder="Current Password"
                                            value={this.state.currentPassword}
                                            onChange={(e) =>
                                                this.setState({
                                                    currentPassword:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={12}>
                                        <input
                                            style={{
                                                backgroundColor: '#e3eaef',
                                                border: 'none',
                                                borderRadius: 8,
                                                width: '100%',
                                                height: 30,
                                                padding: 10,
                                            }}
                                            type="password"
                                            placeholder="New Password"
                                            value={this.state.newPassword}
                                            onChange={(e) =>
                                                this.setState({
                                                    newPassword: e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: 20 }}>
                                    <Col lg={12}>
                                        <input
                                            style={{
                                                backgroundColor: '#e3eaef',
                                                border: 'none',
                                                borderRadius: 8,
                                                width: '100%',
                                                height: 30,
                                                padding: 10,
                                            }}
                                            type="password"
                                            placeholder="Confirm New Password"
                                            value={
                                                this.state.newPasswordConfirm
                                            }
                                            onChange={(e) =>
                                                this.setState({
                                                    newPasswordConfirm:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        )
    }
}
