import React from 'react'
import Header from '../../../insightui/Header'
import axios from 'axios'
import { store } from '../../../store'
import { config } from '../../../models/config'
import {
    IFinancialInstrument,
    IPortfolio,
    IPortfolioHolding,
} from '../../../models/api'
import { Row, Col } from 'react-flexbox-grid'
import ESGCriterion from '../../InstrumentDetails/ESGCriterionFund'
import ImpactScore from '../../../insightui/ImpactScore'
import Button from '../../../insightui/Button'
import LoadingScreen from '../../../insightui/LoadingScreen'
import PortfolioUpload from '../PortfolioUpload'
import SettingsModal from '../SettingsModal'

import { PrinterOutline } from '@styled-icons/evaicons-outline/PrinterOutline'
import { Delete } from '@styled-icons/fluentui-system-regular/Delete'
import { fetchPlanTitle, globals } from '../../../utils/globals'
import Table, { TableParams } from '../../../insightui/Table'
import { css } from 'glamor'
import { Gear } from '@styled-icons/evil/Gear'
import Confirm from '../../../utils/components/Confirm'
import CompareModal from '../CompareModal'
import { isMobile, isTablet } from 'react-device-detect'
import ImpactCalculator from '../ImpactCalculator'
import Fundamentals, {
    numberFormatter,
} from '../../InstrumentDetails/Fundamentals'
import { ParentSize } from '@visx/responsive'
import BrushChart, { SelectionType } from '../../../insightui/BrushChart'
import ScreeningProfileModal from '../../ScreeningProfileModal'
import { ArrowExportUp } from '@styled-icons/fluentui-system-regular/ArrowExportUp'
import { CompareArrows } from '@styled-icons/material-rounded/CompareArrows'
import { ArrowDownload } from '@styled-icons/fluentui-system-regular/ArrowDownload'
import Footer from '../../../insightui/Footer'
import { toast } from 'react-toastify'
import { checkExport } from '../../InstrumentDetails/DetailsHeader'
import PositionModal from '../PositionModal'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import { timeThursdays } from 'd3'

function renderTable(
    headerSource: any,
    dataSource: any,
    onRowEdit: (e: any) => void,
    printing?: boolean,
    params?: TableParams
) {
    const style: Record<string, any> = css({
        marginTop: 40,
        ...(params && params.style ? params.style : {}),
    })

    const data: any = dataSource ? dataSource : []
    const headers: any = headerSource ? headerSource : []

    return (
        <Table
            {...style}
            value={'Portfolio Holdings'}
            deletable
            onRowEdit={onRowEdit}
            key={Math.random() * 1000}
            printing={printing}
            isPortfolio
            style={{
                marginBottom: globals.sizes.component.verticalSpace,
                marginTop: 80,
                cursor: 'pointer',
            }}
            headers={headers}
            dataSource={data}
        />
    )
}
const HoldingsColumns = [
    {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        format: (name: string) => {
            let breakpoint = 20
            let string
            if (name.length > breakpoint) {
                string = name.slice(0, breakpoint) + '...'
            } else {
                string = name
            }
            return (
                <Link
                    style={{
                        color: '#2d8aaf',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                    to={`/${name}`}
                >
                    {string}
                </Link>
            )
        },
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        format: (name: string) =>
            `${name.slice(0, 20)} ${name.length > 20 && '...'}`,
    },
    {
        title: 'Impact Score',
        dataIndex: 'impactscore',
        key: 'impactscore',
        format: (number: number, row: any) => (
            <ImpactScore
                style={{ marginLeft: 40 }}
                type="small"
                match
                score={number}
            />
        ),
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        format: (e: any) => (e ? '$' + e.toFixed(2) : '-'),
        // onChange: this.handlePortfolioValueChange,
    },
    {
        title: 'Asset %',
        dataIndex: 'asset',
        key: 'asset',
        format: (e: number) => e.toFixed(2) + '%',
    },
]

const HoldingsColumnsPrint = [
    {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
        format: (e: any) => <strong>{e}</strong>,
    },
    {
        title: 'Impact Score',
        dataIndex: 'impactscore',
        key: 'impactscore',
        format: (number: number) => <span>{number ?? 'NA'}</span>,
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        format: (e: any) => (e ? '$' + e.toFixed(2) : 'NA'),
        // onChange: this.handlePortfolioValueChange,
    },
    {
        title: 'Asset %',
        dataIndex: 'asset',
        key: 'asset',
        format: (e: number) => (e ? e.toFixed(2) + '%' : 'NA'),
    },
]

interface holdingsTableItem {
    portfolioHoldingId: number
    id?: number
    ticker?: string
    name?: string
    impactscore?: number
    marketcapitalization?: any
    shares?: number
    price?: number
    value?: number
    asset?: number
}

interface PortfolioDetailsProps extends RouteComponentProps {
    portfolioId: number
    portfolios: Array<IPortfolio>
    onCompare: (id: number) => void
    compare?: boolean
}

class PortfoliosDetails extends React.Component<PortfolioDetailsProps> {
    private ref: any

    constructor(props) {
        super(props)
        this.ref = React.createRef()
    }

    state: any = {
        ready: false,
        portfolio: null,
        nameEdit: false,
        import: false,
        uploading: 'waiting',
        holdings: [],
        deleteRequested: false,
        comparePortfolios: false,
        chart: [],
        metrics: null,
        profileSwithch: false,
        tableSpec: HoldingsColumns,
        position: null,
        positionEdit: false,
        printing: false,
    }

    fetchTableHeader = () => {
        return this.state.printing || isMobile || this.props.compare
            ? HoldingsColumnsPrint
            : HoldingsColumns
    }

    refreshPortfolios = () => {
        this.getPortfolioData()
        this.handleTimePeriodChange('5Y')

        this.setState({
            table: renderTable(
                this.fetchTableHeader(),
                this.state.holdings,
                this.handleRowEdit,
                this.state.printing
            ),
        })
    }

    componentDidMount() {
        this.refreshPortfolios()
    }

    getPortfolioData = async () => {
        await axios
            .get(`${config.api}/api/portfolios/${this.props.portfolioId}`, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response) => {
                this.setState({ portfolio: response.data, ready: true })
                this.getPortfolioHoldings()
            })
            .catch((err) => {
                this.props.history.push('/404')
            })
    }

    fetchChart = async (start: string, end: string) => {
        await axios
            .get(
                `${config.api}/api/portfolios/${this.props.portfolioId}/backtest?startDate=${start}-01&endDate=${end}`,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response) => {
                let chartSeries: Array<{ date: string; close: number }> = []

                ;(response.data as any).chart.forEach((item: any) => {
                    chartSeries.push({
                        date: item.label,
                        close: item.sharePrice,
                    })
                })

                this.setState({
                    chart: chartSeries,
                    metrics: response.data,
                })
            })
    }

    dateFormatter = (date: Date) => {
        return (
            date.getFullYear().toString() +
            '-' +
            (date.getMonth() < 10
                ? '0' + date.getMonth()
                : date.getMonth().toString()) +
            '-' +
            (date.getDay() < 10
                ? '0' + date.getDay()
                : date.getDay().toString())
        )
    }

    handleTimePeriodChange = (item: SelectionType) => {
        const dates = {
            '1M': 21,
            '3M': 63,
            '6M': 126,
            '1Y': 252,
            '2Y': 504,
            '5Y': 1260,
        }

        let today = new Date()
        let priorDate = new Date(
            new Date().setDate(today.getDate() - dates[item])
        )

        let start = this.dateFormatter(priorDate)
        let end = this.dateFormatter(today)

        this.fetchChart(start, end)
    }

    getPortfolioHoldings = async () => {
        await axios
            .get(
                `${config.api}/api/portfolios/${this.props.portfolioId}/holdings?size=10000`,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response) => {
                let holdingsData: Array<holdingsTableItem> = []
                let holdingsResponse: Array<IPortfolioHolding> = (
                    response.data as any
                ).holdings
                const portfolioValue = parseFloat(
                    this.state.portfolio.monetaryValue
                )

                holdingsResponse.forEach((item: IPortfolioHolding) => {
                    if (item.financialInstrument) {
                        const fi: IFinancialInstrument =
                            item.financialInstrument
                        const assetPercent = (item.value / portfolioValue) * 100;
                        holdingsData.push({
                            ticker: fi.ticker,
                            portfolioHoldingId: item.id,
                            name: fi.name,
                            id: fi.id,
                            impactscore: fi.impactScore,
                            marketcapitalization: fi.marketCapitalization,
                            shares: item.shares,
                            price: fi.previousSharePrice,
                            value: item.value,
                            asset: assetPercent,
                        })
                    }
                })

                this.setState({
                    //table: <ScaleLoader color={'black'} loading={true} />,
                    table: <></>,
                })

                this.setState({
                    holdings: holdingsData,
                    table: renderTable(
                        this.fetchTableHeader(),
                        holdingsData,
                        this.handleRowEdit,
                        this.state.printing
                    ),
                })
            })
            .catch((err) => console.log(err))
    }

    closePortfolioModal = () => {
        this.setState({ import: false })
    }

    getActivePositionId = () => {
        let position = this.state.position
        this.state.holdings.forEach((holding) => {
            if (
                position[0] === holding.ticker &&
                position[1] === holding.name
            ) {
                return holding.portfolioHoldingId
            }
        })
    }

    deletePortfolio = () => {
        axios
            .delete(`${config.api}/api/portfolios/${this.props.portfolioId}`, {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            })
            .then((response) => {
                this.props.history.push('/portfolios')
                window.location.reload()
            })
    }

    exportPortfolio = () => {
        checkExport() &&
            axios
                .post(
                    `${config.api}/api/portfolios/${this.props.portfolioId}/export-csv`,
                    {
                        filename: 'PortfolioExport.csv',
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${store.getState().token}`,
                        },
                    }
                )
                .then((response: any) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'PortfolioExport.csv')
                    document.body.appendChild(link)
                    link.click()
                })
    }

    importPortfolio = () => {
        this.setState({ import: true })
    }

    editPortfolio = () => {
        this.setState({ nameEdit: true })
    }

    closePortfolioEdit = () => {
        this.setState({ nameEdit: false })
    }

    handlePortfolioSearch = (e: any) => {
        this.setState({
            portfolio: {
                ...this.state.portfolio,
                name: e.target.value,
            },
        })
    }

    switchHoldingWeightMethod = (newHoldingWeightMethod: string) => {
        axios.put(
            `${config.api}/api/portfolios/update-holding-weight-method`,
            {
                portfolioId: this.state.portfolio.id,
                holdingWeightMethod: newHoldingWeightMethod,
            },
            {
                headers: {
                    Authorization: `Bearer ${store.getState().token}`,
                },
            }
        )
    }

    handleRowEdit = (e: any) => {
        this.setState({
            positionEdit: true,
            position: e,
        })
    }

    closeRowEditModal = () => {
        this.setState({ positionEdit: false })
    }

    handlePortfolioValueChange = (newValue: string, row: any) => {
        const holdingWeightMethod = this.state.portfolio.holdingWeightMethod

        let holdingEntry
        this.state.holdings.forEach((holding: any) => {
            if (holding.ticker === row.ticker && holding.name === row.name) {
                holdingEntry = holding
            }
        })

        let requestDTO = {
            id: holdingEntry.portfolioHoldingId,
            financialInstrumentId: holdingEntry.id,
        }

        if (newValue.includes('$')) {
            //value
            if (holdingWeightMethod === 'SHARE_COUNT') {
                this.switchHoldingWeightMethod('VALUE_BASIS')
            }
            requestDTO['value'] = parseFloat(
                newValue.replace('$', '').replace(/ /g, '')
            )
        } else {
            //shares
            if (holdingWeightMethod === 'VALUE_BASIS') {
                this.switchHoldingWeightMethod('SHARE_COUNT')
            }
            requestDTO['shares'] = parseFloat(newValue)
        }
        //send new value
        axios
            .put(
                `${config.api}/api/portfolios/${this.state.portfolio.id}/holdings`,
                requestDTO,
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then(() => {
                toast.success('Holdings Updated')
                this.getPortfolioHoldings()
                this.setState({
                    table: renderTable(
                        this.fetchTableHeader(),
                        this.state.holdings,
                        this.handleRowEdit,
                        this.state.printing
                    ),
                })
            })
            .catch((e) => toast.error('There was an error'))

        //set result
    }

    handlePositionEditComplete = () => {
        this.closeRowEditModal()
        this.getPortfolioData()
        this.getPortfolioHoldings()
        toast.success('Portfolio Holdings Updated!')
    }

    fetchPrintContent = () => {
        return this.ref.current
    }

    render() {
        const { compare } = this.props
        return (
            <>
                {this.state.ready ? (
                    <div
                        style={{
                            overflowX: 'hidden',
                        }}
                    >
                        <PortfolioUpload
                            import={this.state.import}
                            portfolioId={this.props.portfolioId}
                            onFinish={this.getPortfolioData}
                            onClose={this.closePortfolioModal}
                        />
                        <CompareModal
                            portfolios={this.props.portfolios}
                            isActive={this.state.comparePortfolios}
                            activePortfolio={this.state.portfolio}
                            onClose={() =>
                                this.setState({ comparePortfolios: false })
                            }
                            portfolio={this.state.portfolio}
                            onCompare={this.props.onCompare}
                        />
                        <Confirm
                            message="Are you sure you want to delete this portfolio?"
                            onClose={() =>
                                this.setState({ deleteRequested: false })
                            }
                            onConfirm={this.deletePortfolio}
                            isActive={this.state.deleteRequested}
                        />
                        <SettingsModal
                            portfolio={this.state.portfolio}
                            isActive={this.state.nameEdit}
                            onClose={this.closePortfolioEdit}
                            onUpdate={this.getPortfolioData}
                        />
                        <ScreeningProfileModal
                            isActive={this.state.profileSwitch}
                            onClose={() =>
                                this.setState({ profileSwitch: false })
                            }
                        />
                        {this.state.position && (
                            <PositionModal
                                isActive={this.state.positionEdit}
                                key={Math.random() * 100000}
                                portfolio={this.state.portfolio}
                                holdings={this.state.holdings}
                                position={this.state.position}
                                callBack={this.handlePositionEditComplete}
                                onClose={this.closeRowEditModal}
                            />
                        )}
                        {!compare ? (
                            <Header
                                logo
                                refreshPortfolios={this.refreshPortfolios}
                            />
                        ) : (
                            <></>
                        )}
                        <div
                            ref={this.ref}
                            style={{
                                padding: 10,
                                paddingTop:
                                    compare || this.state.printing ? 0 : 120,
                            }}
                        >
                            {this.state.portfolio != null ? (
                                <Row>
                                    <Col xs>
                                        <Row
                                            style={{
                                                paddingTop: 50,
                                            }}
                                        >
                                            <Col
                                                xs={12}
                                                style={{
                                                    paddingTop: this.state
                                                        .printing
                                                        ? 15
                                                        : 0,
                                                    paddingLeft: this.state
                                                        .printing
                                                        ? 15
                                                        : 20,
                                                    display: 'block',
                                                }}
                                            >
                                                <ImpactScore
                                                    footer
                                                    withAnimation
                                                    bgColor={
                                                        'rgb(251, 239, 229)'
                                                    }
                                                    score={
                                                        this.state.portfolio
                                                            .impactScore
                                                    }
                                                    type="large"
                                                />
                                                <h1
                                                    style={{
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {this.state.portfolio.name}
                                                </h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ padding: '0 20px' }}>
                                                {' '}
                                                <span
                                                    style={{
                                                        fontWeight: 300,
                                                        fontSize: 13,
                                                    }}
                                                >
                                                    {this.state.portfolio.type}{' '}
                                                    | $
                                                    {this.state.portfolio.monetaryValue
                                                        .toFixed(0)
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ','
                                                        )}{' '}
                                                    |{' '}
                                                    <strong>
                                                        {
                                                            this.state.portfolio
                                                                .totalHoldings
                                                        }
                                                    </strong>{' '}
                                                    {this.state.portfolio
                                                        .totalHoldings > 1
                                                        ? 'Holdings'
                                                        : 'Holding'}{' '}
                                                    | Last Modified:{' '}
                                                    <strong>
                                                        {new Date(
                                                            this.state.portfolio.lastModified
                                                        ).toLocaleDateString(
                                                            'en-US'
                                                        )}
                                                    </strong>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ) : (
                                <></>
                            )}
                            {compare || this.state.printing ? (
                                <></>
                            ) : (
                                <Row style={{ paddingTop: 20 }}>
                                    <Col
                                        style={{
                                            paddingTop: isMobile ? 20 : 0,
                                            paddingRight: 10,
                                            display: isMobile
                                                ? 'none'
                                                : 'block',
                                        }}
                                        {...(isMobile ? { xs: 12 } : {})}
                                    >
                                        <ReactToPrint
                                            content={this.fetchPrintContent}
                                            documentTitle="Impact Report"
                                            onBeforeGetContent={() => {
                                                return new Promise(
                                                    (resolve, reject) => {
                                                        this.setState(
                                                            {
                                                                printing: true,
                                                                table: renderTable(
                                                                    this.fetchTableHeader(),
                                                                    this.state
                                                                        .holdings,
                                                                    this.state
                                                                        .handleRowEdit,
                                                                    this.state
                                                                        .printing
                                                                ),
                                                            },
                                                            resolve as any
                                                        )
                                                    }
                                                )
                                            }}
                                            onBeforePrint={() => {
                                                return new Promise(
                                                    (resolve, reject) => {
                                                        const planTitle =
                                                            fetchPlanTitle()
                                                        // if (
                                                        //     !(
                                                        //         planTitle ===
                                                        //             'Pro' ||
                                                        //         planTitle ===
                                                        //             'Administrator'
                                                        //     )
                                                        // ) {
                                                        //     toast.error(
                                                        //         <span>
                                                        //             Please{' '}
                                                        //             <Link to="/checkout">
                                                        //                 upgrade
                                                        //                 to pro{' '}
                                                        //             </Link>
                                                        //             to create
                                                        //             impact
                                                        //             reports
                                                        //         </span>
                                                        //     )
                                                        //     this.setState(
                                                        //         {
                                                        //             printing:
                                                        //                 false,
                                                        //         },
                                                        //         reject as any
                                                        //     )
                                                        // } else {
                                                        this.setState({
                                                            table: renderTable(
                                                                this.fetchTableHeader(),
                                                                this.state
                                                                    .holdings,
                                                                this
                                                                    .handleRowEdit,
                                                                this.state
                                                                    .printing
                                                            ),
                                                        })
                                                        ;(resolve as any)()
                                                        // }
                                                    }
                                                )
                                            }}
                                            onAfterPrint={() =>
                                                this.setState({
                                                    printing: false,
                                                })
                                            }
                                            trigger={() => (
                                                <Button
                                                    style={{
                                                        width: isMobile
                                                            ? '100%'
                                                            : 160,
                                                    }}
                                                    type="outlined"
                                                    icon={
                                                        <PrinterOutline
                                                            width={20}
                                                        />
                                                    }
                                                >
                                                    Report
                                                </Button>
                                            )}
                                        />
                                    </Col>
                                    <Col
                                        style={{
                                            paddingTop: isMobile ? 20 : 0,
                                            paddingRight: 10,
                                        }}
                                        {...(isMobile ? { xs: 12 } : {})}
                                    >
                                        <Button
                                            style={{
                                                width: isMobile ? '100%' : 160,
                                            }}
                                            type="outlined"
                                            icon={<ArrowExportUp width={20} />}
                                            onClick={this.importPortfolio}
                                        >
                                            Import
                                        </Button>
                                    </Col>
                                    <Col
                                        style={{
                                            paddingTop: isMobile ? 20 : 0,
                                            paddingRight: 10,
                                        }}
                                        {...(isMobile ? { xs: 12 } : {})}
                                    >
                                        <Button
                                            style={{
                                                width: isMobile ? '100%' : 160,
                                            }}
                                            type="outlined"
                                            icon={<ArrowDownload width={25} />}
                                            onClick={this.exportPortfolio}
                                        >
                                            Export
                                        </Button>
                                    </Col>
                                    {!isMobile && (
                                        <Col
                                            style={{
                                                paddingTop: isMobile ? 20 : 0,
                                                paddingRight: 10,
                                            }}
                                            {...(isMobile ? { xs: 12 } : {})}
                                        >
                                            <Button
                                                style={{
                                                    width: isMobile
                                                        ? '100%'
                                                        : 180,
                                                }}
                                                type="outlined"
                                                onClick={() => {
                                                    if (checkExport()) {
                                                        this.setState({
                                                            comparePortfolios:
                                                                true,
                                                        })
                                                    }
                                                }}
                                                icon={
                                                    <CompareArrows width={20} />
                                                }
                                            >
                                                Compare
                                            </Button>
                                        </Col>
                                    )}

                                    <Col
                                        style={{
                                            paddingTop: isMobile ? 20 : 0,
                                            paddingRight: 10,
                                        }}
                                        {...(isMobile ? { xs: 12 } : {})}
                                    >
                                        <Button
                                            style={{
                                                width: isMobile ? '100%' : 190,
                                            }}
                                            type="outlined"
                                            onClick={this.editPortfolio}
                                        >
                                            <Gear width={20} /> Portfolio
                                            Settings
                                        </Button>
                                    </Col>
                                    <Col
                                        style={{
                                            paddingTop: isMobile ? 20 : 0,
                                            paddingRight: 10,
                                        }}
                                        {...(isMobile ? { xs: 12 } : {})}
                                    >
                                        <Button
                                            style={{
                                                width: isMobile ? '100%' : 160,
                                            }}
                                            type="outlined"
                                            icon={<Delete width={20} />}
                                            onClick={() =>
                                                this.setState({
                                                    deleteRequested: true,
                                                })
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                            <hr
                                style={{
                                    margin: '30px 0 10px',
                                    border: '.15px solid #e3eaee',
                                    marginTop: this.state.printing ? 20 : 40,
                                }}
                            />
                            {compare || this.state.printing ? (
                                <></>
                            ) : (
                                <Row
                                    style={{
                                        marginBottom: 20,
                                        padding: '0 8px',
                                        justifyContent:
                                            isMobile && !isTablet
                                                ? 'center'
                                                : 'initial',
                                    }}
                                >
                                    <Col>
                                        <p
                                            style={{
                                                fontWeight: 300,
                                                fontSize: 18,
                                                lineHeight: '40px',
                                                display: 'inline',
                                            }}
                                        >
                                            Screening Profile:{' '}
                                            <strong>
                                                {
                                                    store.getState()
                                                        .activeProfile.name
                                                }
                                            </strong>
                                        </p>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="outlined"
                                            style={{
                                                width: 'auto',
                                                padding: '8px 12px',
                                                marginLeft: 15,
                                                display: 'inline-block',
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    profileSwitch: true,
                                                })
                                            }
                                        >
                                            <strong>Change</strong>
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                            <div style={{ pageBreakAfter: 'always' }}>
                                <ESGCriterion
                                    instrument={this.state.portfolio}
                                    portfolio
                                    printing={this.state.printing}
                                    compare={compare}
                                />
                            </div>
                            <Row>
                                {this.state.holdings.length && (
                                    <div
                                        style={{
                                            padding: this.state.printing
                                                ? 25
                                                : 0,
                                            width: '100%',
                                            pageBreakBefore: 'always',
                                            pageBreakInside: 'avoid',
                                        }}
                                    >
                                        <Table
                                            // {...style}
                                            value={
                                                !this.state.printing
                                                    ? 'Portfolio Holdings'
                                                    : 'Top 15 Holdings'
                                            }
                                            deletable
                                            onRowEdit={this.handleRowEdit}
                                            key={Math.random() * 1000}
                                            printing={this.state.printing}
                                            isPortfolio
                                            style={{
                                                marginBottom:
                                                    globals.sizes.component
                                                        .verticalSpace,
                                                marginTop: 30,
                                                cursor: 'pointer',
                                            }}
                                            headers={
                                                this.state.printing ||
                                                isMobile ||
                                                compare
                                                    ? HoldingsColumnsPrint
                                                    : HoldingsColumns
                                            }
                                            dataSource={this.state.holdings}
                                        />
                                    </div>
                                )}
                            </Row>
                            {compare ? (
                                <></>
                            ) : (
                                <div
                                    style={{
                                        pageBreakBefore: 'always',
                                        pageBreakInside: 'avoid',
                                        pageBreakAfter: 'avoid',
                                        paddingTop: 20,
                                        paddingRight: 20,
                                    }}
                                >
                                    <ImpactCalculator
                                        printing={this.state.printing}
                                        defaultPortfolioValue={this.state.portfolio.monetaryValue.toFixed(
                                            0
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                        {!compare}
                    </div>
                ) : (
                    <>
                        <LoadingScreen defaultMessage="Loading Portfolios" />
                    </>
                )}
            </>
        )
    }
}
export default withRouter(PortfoliosDetails)
