import React from 'react'
import InputField from '../../insightui/InputFieldCustom'
import { Row, Col } from 'react-flexbox-grid'
import Modal from '../Modal'
import Button from '../../insightui/Button'
import axios from 'axios'
import { config } from '../../models/config'
import { store } from '../../store'
import { toast } from 'react-toastify'

interface UpdatePaymentMethodProps {
    isActive: boolean
    onClose: () => void
}

export default class UpdatePaymentMethod extends React.Component<UpdatePaymentMethodProps> {
    state = {
        cardNumber: '',
        expMonth: '',
        expYear: '',
        cvc: '',
        zipcode: '',
    }

    updatePaymentMethod = () => {
        axios
            .put(
                `${config.api}/api/payment/payment-method`,
                {
                    cardNumber: this.state.cardNumber,
                    expMonth: parseInt(this.state.expMonth),
                    expYear: parseInt(this.state.expYear),
                    cvc: this.state.cvc,
                    zipCode: this.state.zipcode,
                },
                {
                    headers: {
                        Authorization: `Bearer ${store.getState().token}`,
                    },
                }
            )
            .then((response: any) => {
                toast.success('Payment Method updated!')
                this.props.onClose()
            })
            .catch((error) =>
                toast.error(
                    'Error. Your payment method could not be updated. Please contact support'
                )
            )
    }

    render() {
        const { isActive, onClose } = this.props
        return (
            <Modal isActive={isActive} onClose={onClose} width={500}>
                <h2>Update Payment Method</h2>
                <Row>
                    <Col lg={12}>
                        <InputField
                            id={'0'}
                            title="Card Number"
                            value={this.state.cardNumber}
                            onChange={(e) =>
                                this.setState({ cardNumber: e.target.value })
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12}>
                        <InputField
                            id={'1'}
                            title="CVC"
                            value={this.state.cvc}
                            onChange={(e) =>
                                this.setState({ cvc: e.target.value })
                            }
                        />
                    </Col>
                    <Col lg={6} sm={12}>
                        <InputField
                            id={'2'}
                            title="zipcode"
                            value={this.state.zipcode}
                            onChange={(e) =>
                                this.setState({ zipcode: e.target.value })
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12}>
                        <InputField
                            id={'3'}
                            title="exp year"
                            value={this.state.expYear}
                            placeholder="YYYY"
                            onChange={(e) =>
                                this.setState({ expYear: e.target.value })
                            }
                        />
                    </Col>
                    <Col lg={6} sm={12}>
                        <InputField
                            id={'4'}
                            title="exp month"
                            value={this.state.expMonth}
                            placeholder="MM"
                            onChange={(e) =>
                                this.setState({ expMonth: e.target.value })
                            }
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Col lg={6}>
                        <Button
                            type="outlined"
                            onClick={this.props.onClose}
                            style={{ width: '100%', marginBottom: '10px' }}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col lg={6}>
                        <Button
                            type="primary"
                            onClick={this.updatePaymentMethod}
                            style={{ width: '100%' }}
                        >
                            Update
                        </Button>
                    </Col>
                </Row>
            </Modal>
        )
    }
}
